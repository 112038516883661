var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"playlist-panel"},[_c('div',{staticClass:"section-title title"},[_c('i',{staticClass:"material-icons-outlined align-middle button",on:{"click":_setup.goBackToPlaylist}},[_vm._v(" arrow_back ")]),_c('span',{staticClass:"title"},[_vm._v(" Back ")])]),_c('div',{staticClass:"section-title playlist-title"},[_c('div',{staticClass:"playlist-name"},[_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v("playlist_play")]),_c('span',{staticClass:"title"},[_vm._v(" Area for "),_c('b',[_vm._v(_vm._s(_setup.playlist?.object.name))]),_vm._v(" layer ")])])]),_c('div',{staticClass:"panel-container"},[_c(_setup.PlaylistPreview,{attrs:{"playlist":_setup.playlist}}),_c('div',{staticClass:"form-group name layout-input mb-0"},[_c('label',{attrs:{"for":"playlist-name"}},[_vm._v("Area Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.playlist.object.name),expression:"playlist.object.name"}],staticClass:"form-control",attrs:{"type":"text","id":"playlist-name","placeholder":"Name"},domProps:{"value":(_setup.playlist.object.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_setup.playlist.object, "name", $event.target.value)},_setup.handleChange]}})]),_c('BaseSelect',{attrs:{"label":"Transition Animation","id":"transition-animation","type":"text","options":[
        { label: 'No transition', value: 'none' },
        { label: 'Fade in', value: 'fade' },
        { label: 'Slide in', value: 'slide' },
      ]},on:{"input":_setup.handleChange},model:{value:(_setup.playlist.object.transition_mode),callback:function ($$v) {_vm.$set(_setup.playlist.object, "transition_mode", $$v)},expression:"playlist.object.transition_mode"}}),_c(_setup.LayoutPlaylistItems,{ref:"PlaylistItems",attrs:{"playlist":{
        ..._setup.playlist.object,
        playlistId: _setup.playlist.object.wid,
        assocId: _setup.playlist.assoc_id,
        position: _setup.playlist.position,
      },"hideReturn":"","designerMode":""}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }