/* eslint-disable no-console */
import '@babel/polyfill';

import './sass/style.scss';
import 'bootstrap/dist/js/bootstrap';
import Toasted from 'vue-toasted';
import Vue from 'vue';
import VueKonva from 'vue-konva';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import NavBar from './components/NavBar.vue';

// Base Components
import BaseButton from '@/components/common/Button.vue';
import BaseInput from '@/components/common/Input.vue';
import BaseSelect from '@/components/common/Select.vue';
import BaseModal from '@/components/common/BaseModal/BaseModal.vue';

import EmptyLayout from './layouts/Empty.vue';
import DashboardLayout from './layouts/Dashboard.vue';
import FullLayout from '@/layouts/FullLayout.vue';

import router from './router';
import store from './store';
import { globalToasts, globalFilters } from './helpers';
import config from './config';

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;

Vue.use(Toasted, { duration: 2000, iconPack: 'material', position: 'top-center' });
Vue.use(VueKonva);

// Base components
Vue.component('BaseButton', BaseButton);
Vue.component('BaseSelect', BaseSelect);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseModal', BaseModal);

// Global toasts
const defaultToastParams = {
  closeOnSwipe: true,
  theme: 'bubble',
  duration: 13000,
  type: 'info',
  icon: 'info',
};

globalToasts.forEach((c) => {
  const params = {
    ...defaultToastParams,
    ...c.params,
  };
  Vue.toasted.register(c.name, c.toast, params);
});

// Global filters
globalFilters.forEach((f) => {
  Vue.filter(f.name, f.filter);
});

// Register global components
Vue.component('nav-bar', NavBar);
Vue.component('dashboard-layout', DashboardLayout);
Vue.component('full-layout', FullLayout);
Vue.component('empty-layout', EmptyLayout);

Sentry.init({
  Vue,
  dsn: 'https://6ba163e09f7a48bd4b4bfd1d70cd582c@o4506021336776704.ingest.sentry.io/4506031999549440',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', config.apiUrl],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const vueApp = new Vue({
  el: '#app',
  template: '<App/>',
  render: (h) => h(App),
  components: { App },
  router,
  store,
});

vueApp.$mount('#app');

if (process.env.NODE_ENV === 'development') {
  // Fixing Vue Devtool error
  if (typeof window !== 'undefined') {
    if (window.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
      window.dispatchEvent(new Event('hook-ready'));
    }
  }

  //in your backend file
  let hookReady = false;

  window.addEventListener('hook-ready', () => {
    hookReady = true;
  });

  function checkHookAndExecute() {
    if (hookReady) {
      if (global_hook_1.hook.Vue) {
        connect();
        (0, app_1._legacy_getAndRegisterApps)(ctx, true);
        shared_utils_1.SharedData.legacyApps = true;
      }

      global_hook_1.hook.on(shared_utils_1.HookEvents.INIT, () => {
        (0, app_1._legacy_getAndRegisterApps)(ctx, true);
        shared_utils_1.SharedData.legacyApps = true;
      });
    } else {
      setTimeout(() => checkHookAndExecute(), 50);
    }
  }

  checkHookAndExecute();
}
