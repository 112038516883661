<template>
  <Modal
    @closeModal="closeModal"
    :isSaving="isLoading"
    :title="templates ? 'Move Templates' : 'Move Template'"
    :okFunction="updateDirectory"
    :modalStyles="{ width: '500px' }"
  >
    <template v-slot:body>
      <div>
        <label for="mediaFolders" style="font-size: 16px">
          {{ `In what folder do you want to save the ${template ? 'template' : 'templates'}?` }}
        </label>
        <select id="mediaFolders" class="form-control" v-model="folderId">
          <option value="">Select folder</option>
          <option v-for="(folder, index) in folders" :key="index" :value="folder.id">
            {{ folder.name }}
          </option>
        </select>
      </div>
    </template>
  </Modal>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Modal from '@/components/common/Modal.vue';
  import { apiMoveTemplate, apiMoveMultiTemplates } from '@/api/templates';

  export default {
    name: 'ChangeTemplateFolderModal',
    components: { Modal },
    data() {
      return {
        isLoading: false,
        folderId: null,
      };
    },
    props: {
      template: {
        type: Object,
        default: null,
      },
      templates: {
        type: Array,
        default: null,
      },
    },
    computed: {
      ...mapGetters({ folders: 'getAllTemplateFolders' }),
    },
    mounted() {
      this.setSelectedFolder();
    },
    methods: {
      async updateDirectory() {
        if (!this.folderId) {
          this.$toasted.global.general_error({ message: 'Select a folder first' });
          return;
        }
        if (this.isLoading) {
          this.$toasted.global.general_error({ message: 'Folder update in progress' });
          return;
        }

        this.isLoading = true;

        try {
          if (this.template) {
            await apiMoveTemplate(this.template.template_id, this.folderId);
          } else if (this.templates) {
            await apiMoveMultiTemplates(
              this.templates.map((item) => item.template_id),
              this.folderId,
            );
          }
          this.$emit('onFolderUpdate');
          this.$toasted.global.general_success({ message: 'Template successfully moved' });
          this.$emit('closeModal');
        } catch {
          this.$toasted.global.general_error({ message: 'Failed to move the template' });
        }

        this.isLoading = false;
      },

      setSelectedFolder() {
        if (this.template) {
          this.folderId = this.template.item_folder;
        } else if (this.templates && this.templates.length) {
          this.folderId = this.templates[0].item_folder;
        }
      },

      closeModal() {
        this.$emit('closeModal');
      },
    },
  };
</script>
