<script lang="ts">
  export default {
    name: 'PulseMonitoring',
  };
</script>

<script setup lang="ts">
  import { MonitoringFilter, Options } from './types';
  import SearchButton from '@/components/common/SearchButton.vue';
  import LabelledInput from '@/components/common/LabelledInput.vue';
  import LabelledSelect from '@/components/common/LabelledSelect.vue';
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import { apiGetScreenGroups } from '@/api/screens';
  import { apiGetScreenPublishStatus } from '@/api/pulse';
  import { useToasted } from '@/helpers';
  import { PublishStatus } from '@/types/api/pulse';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import ProgressBar from '@/components/ProgressBar.vue';
  import { Colors } from '@/constant/theme';
  import ScreenRotationIcons from '@/components/common/screens/ScreenRotationIcons.vue';

  const toasted = useToasted();
  const intervalId = ref<number | null>(null);
  const filter = ref<MonitoringFilter>({
    dateFrom: null,
    dateTo: null,
    group: null,
    status: null,
    search: '',
  });
  const isLoading = ref(false);
  const groupOptions = ref<Array<Options>>([
    {
      value: null,
      label: 'All Groups',
    },
  ]);
  const statusOptions = ref<Array<Options>>([
    {
      value: null,
      label: 'All Statuses',
    },
    {
      value: 'Pending',
      label: 'Pending',
    },
    {
      value: 'Updated',
      label: 'Updated',
    },
    {
      value: 'Failed',
      label: 'Failed',
    },
  ]);
  const publishInfo = ref<Array<PublishStatus>>([]);

  const filteredPublishInfo = computed(() => {
    return publishInfo.value.filter((publish: PublishStatus) => {
      let returnVal = true;

      if (filter.value.search) {
        returnVal =
          returnVal &&
          publish.screenName.toLowerCase().indexOf(filter.value.search.toLowerCase()) !== -1;
      }

      if (filter.value.group) {
        returnVal = returnVal && publish.screenGroup === filter.value.group;
      }

      if (filter.value.status) {
        returnVal = returnVal && publish.publishStatus === filter.value.status;
      }

      if (filter.value.dateFrom) {
        returnVal = returnVal && publish.publishDate > filter.value.dateFrom;
      }

      if (filter.value.dateTo) {
        returnVal = returnVal && publish.publishDate <= `${filter.value.dateTo}T23:59:59`;
      }

      return returnVal;
    });
  });

  const progressBarColor = computed(() => {
    if (!pendingPublishScreens.value.length) {
      return Colors.GREEN_LIGHT;
    }

    return Colors.PRIMARY_RED;
  });

  const pendingPublishScreens = computed(() => {
    return filteredPublishInfo.value.filter(
      (pub: PublishStatus) => pub.publishStatus === 'Pending' || !pub.publishStatus,
    );
  });
  const failedPublishScreens = computed(() => {
    return filteredPublishInfo.value.filter((pub: PublishStatus) => pub.publishStatus === 'Failed');
  });

  const uploadPercent = computed(() => {
    if (filteredPublishInfo.value.length === 0) {
      return 0;
    }

    return (
      ((filteredPublishInfo.value.length - pendingPublishScreens.value.length) * 100) /
      filteredPublishInfo.value.length
    );
  });

  onMounted(async () => {
    try {
      isLoading.value = true;
      const groups = await apiGetScreenGroups();
      groupOptions.value = [
        ...groupOptions.value,
        ...groups.map((group: any) => {
          return {
            label: group.name,
            value: group.name,
          };
        }),
      ];
    } catch (err) {
      console.log(err);
    }
    fetchScreenPublishStatus(true);
    intervalId.value = setInterval(() => {
      fetchScreenPublishStatus(false);
    }, 10000);
  });

  onUnmounted(() => {
    destroyTimer();
  });

  async function fetchScreenPublishStatus(showLoader: boolean = false) {
    isLoading.value = showLoader && true;
    try {
      const data = await apiGetScreenPublishStatus(undefined);
      publishInfo.value = data;
    } catch {
      toasted.global.general_error({
        message: 'Unable to load publish information',
      });
    }
    isLoading.value = false;
  }

  function isResultFiltered() {
    return Object.values(filter.value).some((x) => !!x);
  }

  function handleStatusFilterChange(value: string | number | null) {
    filter.value.status = value as string;
  }

  function handleGroupFilterChange(value: string | number | null) {
    filter.value.group = value as string;
  }

  function formatDate(date: string | null) {
    if (!date) return '';
    const dt = new Date(date);
    return dt
      .toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .toUpperCase();
  }

  function destroyTimer() {
    if (!intervalId.value) return;
    clearInterval(intervalId.value);
  }

  function isHorizontalScreen(screen: PublishStatus) {
    return !!screen?.orientation && ['180d', 'landscape'].includes(screen?.orientation);
  }
</script>

<template>
  <div class="monitoring-details">
    <div class="search-filters">
      <div class="search">
        <i
          class="filter-icon material-icons-outlined button"
          :class="{ active: isResultFiltered() }"
        >
          filter_alt
        </i>
        <SearchButton v-model="filter.search" />
      </div>
      <div class="data-filters">
        <LabelledInput
          v-model="filter.dateFrom"
          type="date"
          label="Date From"
          labelColor="#333332"
          backgroundColor="#f8f8f8"
          labelOpacity=".5"
        />
        <LabelledInput
          v-model="filter.dateTo"
          type="date"
          label="Date To"
          labelColor="#333332"
          backgroundColor="#f8f8f8"
          labelOpacity=".5"
        />
        <LabelledSelect
          v-model="filter.group"
          label="Group"
          labelColor="#333332"
          backgroundColor="#f8f8f8"
          :options="groupOptions"
          @change="handleGroupFilterChange"
          name="grpup-filter"
          labelOpacity=".5"
        />
        <LabelledSelect
          v-model="filter.status"
          label="Status"
          labelColor="#333332"
          backgroundColor="#f8f8f8"
          :options="statusOptions"
          @change="handleStatusFilterChange"
          name="status-filter"
          labelOpacity=".5"
        />
      </div>
    </div>
    <div class="pulse-content" v-if="!isLoading">
      <div class="status-data">
        <div v-if="!filteredPublishInfo.length" class="no-screen">
          <BaseText>No screen found</BaseText>
        </div>
        <div v-else class="status-table">
          <div class="table-header">
            <div class="publish-date">
              <BaseText variant="subtitle2">Publish Date</BaseText>
            </div>
            <div class="publish-by">
              <BaseText variant="subtitle2">Publish By</BaseText>
            </div>
            <div class="name">
              <BaseText variant="subtitle2">Name</BaseText>
            </div>
            <div class="group">
              <BaseText variant="subtitle2">Group</BaseText>
            </div>
            <div class="orientation">&nbsp;</div>
            <div class="last-online">
              <BaseText variant="subtitle2">Last online</BaseText>
            </div>
            <div class="status">
              <BaseText variant="subtitle2">Status</BaseText>
            </div>
          </div>
          <div class="table-rows scrollbar">
            <div class="table-row" v-for="screen in filteredPublishInfo" :key="screen.screenId">
              <div class="publish-date">
                <BaseText variant="body1">{{ formatDate(screen.publishDate) }}</BaseText>
              </div>
              <div class="publish-by">
                <BaseText variant="body1">{{ screen.publishedBy }}</BaseText>
              </div>
              <div class="name">
                <BaseText variant="subHeading"
                  ><span
                    class="screen-status"
                    :class="`color-${screen.connectionStatus ?? 1}`"
                  ></span
                  >{{ screen.screenName }}</BaseText
                >
              </div>
              <div class="group group-data">
                <BaseText variant="subtitle2"
                  ><img src="@/assets/icon/icon-group.svg" />{{ screen.screenGroup }}</BaseText
                >
              </div>
              <div class="orientation">
                <ScreenRotationIcons
                  :horizontalScreens="isHorizontalScreen(screen) ? 1 : 0"
                  :verticalScreens="isHorizontalScreen(screen) ? 0 : 1"
                  hideNumbers
                />
              </div>

              <div class="last-online">
                <BaseText variant="body1">{{ formatDate(screen.lastOnline) }}</BaseText>
              </div>
              <div
                class="status status-table-data"
                :title="
                  screen.publishStatus === 'Failed'
                    ? 'Failed because the screen has been offline for more than an hour after publish was sent. The screen will be \'Updated\' once it is online.'
                    : ''
                "
              >
                <span :class="screen.publishStatus"></span>
                <BaseText variant="subtitle2">{{ screen.publishStatus }}</BaseText>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pulse-progress" v-if="filteredPublishInfo.length && pendingPublishScreens.length">
        <img
          class="progress-icon spin-animation"
          v-if="pendingPublishScreens.length"
          src="@/assets/icon/progress-3.png"
          alt="Progress Icon"
        />
        <img
          class="progress-icon"
          v-else
          src="@/assets/icon/check_circle_outline.svg"
          alt="Progress Icon"
        />
        <div class="info">
          <ProgressBar :percent="uploadPercent" :color="progressBarColor" />
        </div>
        <BaseText variant="title1" color="#000000"
          >{{ filteredPublishInfo.length - pendingPublishScreens.length }} /
          {{ filteredPublishInfo.length }}</BaseText
        >
      </div>
      <div
        class="complete-summary"
        v-else-if="filteredPublishInfo.length && pendingPublishScreens.length === 0"
      >
        <div class="icon" :class="failedPublishScreens.length ? 'failed' : 'success'"></div>
        <div class="summary">
          <BaseText class="symmary-stat" variant="title1">
            {{
              failedPublishScreens.length ? failedPublishScreens.length : filteredPublishInfo.length
            }}
            out of {{ filteredPublishInfo.length }}
          </BaseText>
          <BaseText variant="body2" class="summary-text">
            {{ failedPublishScreens.length ? 'screens failed to update' : 'screens are updated' }}
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .monitoring-details {
    font-family: poppins;
    min-width: 1400px;
    display: flex;
    flex-direction: column;
    .search-filters {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px 8px;
      .search {
        display: flex;
        gap: 8px;
        .active {
          color: $primaryRed;
        }
      }
    }
    .data-filters {
      display: flex;
      gap: 16px;
      > * {
        flex: 1 1 0;
      }
    }
    .form-group {
      width: 100%;
      margin-bottom: 0;
      input {
        width: 100%;
        padding: 8px 16px;
        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
    .status-data {
      padding: 0 8px;
      .no-screen {
        text-align: center;
      }
      .status-table {
        .table-header,
        .table-rows {
          .publish-date,
          .last-online {
            width: 14%;
          }
          .name,
          .group {
            width: 22%;
          }
          .orientation {
            width: 5%;
          }
          .publish-by {
            width: 16%;
            word-wrap: break-word;
          }
          .status {
            display: flex;
            flex-direction: row;
            gap: 8px;
            width: 7%;
            align-items: center;
            &:has(.Failed) {
              cursor: pointer;
            }
          }
        }
        .table-header,
        .table-row {
          padding: 12px 16px;
          display: flex;
          flex-direction: row;
          gap: 8px;
        }
        .table-rows {
          display: flex;
          flex-direction: column;
          gap: 8px;
          max-height: 62vh;
          overflow-y: auto;
          .table-row {
            background-color: #fff;
            .name {
              .screen-status {
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 3px solid;
                margin-right: 8px;
                &.color-2 {
                  background-color: $greenLight;
                  border-color: $greenLightFaded;
                }
                &.color-0 {
                  background-color: $redLight;
                  border-color: $redLightFaded;
                }
                &.color-1 {
                  background-color: $greyLight;
                  border-color: $greyLightFaded;
                }
              }
            }
            .group {
              > div {
                display: flex;
                flex-direction: row;
                gap: 8px;
              }
              .material-icons {
                margin-right: 8px;
              }
            }
            .status {
              span {
                display: inline-block;
                height: 16px;
                width: 16px;
                vertical-align: middle;
              }
              .Pending {
                background-image: url('@/assets/icon/pending.svg');
              }
              .Updated {
                background-image: url('@/assets/icon/check.svg');
              }
              .Failed {
                background-image: url('@/assets/icon/error.svg');
                + div {
                  color: $primaryRed;
                }
              }
            }
          }
        }
      }
    }
    .pulse-progress,
    .complete-summary {
      height: 75px;
      background-color: #ffffff;
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      padding: 16px;
      .info {
        flex: 1;
      }
    }
    .group-data,
    .status-table-data {
      color: $secondaryText;
    }

    .complete-summary {
      display: flex;
      flex-direction: row;
      gap: 16px;
      .summary {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      .icon {
        width: 36px;
        height: 36px;
        &.failed {
          background-image: url('@/assets/icon/error_outline.svg');
        }
        &.success {
          background-image: url('@/assets/icon/check_circle_outline.svg');
        }
      }
    }
  }
  ::v-deep {
    .screens-rotation {
      justify-content: center;
    }
  }
</style>
