<template>
  <div class="preview-container">
    <div class="preview" :class="{ previewBackground: !isHorizontalLayout }">
      <div
        class="preview-content"
        @click="handlePlaylistPlayer"
        :style="{ height: `${playlistSize.height}px`, width: `${playlistSize.width}px` }"
      >
        <PlaylistPlayer
          :style="{ zoom: playlistZoom }"
          ref="previewPlayer"
          :playlist="playlist.object"
          :playSlider="playSlider"
          isPlaylistPreview
          :transitionMode="playlist.transitionMode"
        />

        <div class="button-layer">
          <div class="button-container">
            <i class="material-icons align-middle">
              {{ playSlider ? 'pause' : 'play_arrow' }}
            </i>
          </div>
        </div>
      </div>
    </div>

    <div class="preview-footer">
      <div class="footer-item time" title="Total Time">
        <i class="material-icons-outlined align-middle">timer</i>
        {{ playlistTotalTime }}
      </div>
      <div class="footer-item" title="Images">
        <i class="material-icons-outlined align-middle">image</i>
        {{ getTypeAmount(WIDGET_TYPES.IMAGE) }}
      </div>
      <div class="footer-item" title="Videos">
        <i class="material-icons-outlined align-middle">play_circle_outline</i>
        {{ getTypeAmount(WIDGET_TYPES.VIDEO) }}
      </div>
      <div class="footer-item" title="PDFs">
        <i class="material-icons-outlined align-middle">text_snippet</i>
        {{ getTypeAmount(WIDGET_TYPES.PDF) }}
      </div>
    </div>
  </div>
</template>

<script>
  import PlaylistPlayer from '@/components/player/PlaylistPlayer.vue';

  import { WIDGET_TYPES } from '@/models/layoutDesigner';
  import { transformSecondsToTimer } from '@/helpers/utils';
  import { restrictToBounds } from '@/helpers/draggable';

  export default {
    name: 'PlaylistPreview',

    components: {
      PlaylistPlayer,
    },

    props: {
      playlist: { type: Object, required: true, default: {} },
    },

    data() {
      return {
        previewPlayer: null,
        playSlider: false,
        WIDGET_TYPES,
        width: 326,
      };
    },

    mounted() {
      this.previewPlayer = this.$refs.previewPlayer;
    },

    watch: {
      playlist() {
        this.$nextTick();
      },
    },

    computed: {
      playlistSize() {
        const widgetSize = {
          height: this.isHorizontalLayout
            ? this.playlist.position.horizontal.heightPixels
            : this.playlist.position.vertical.heightPixels,
          width: this.isHorizontalLayout
            ? this.playlist.position.horizontal.widthPixels
            : this.playlist.position.vertical.widthPixels,
        };

        const widgetAspectRatio = widgetSize.height / widgetSize.width;
        const isPlaylistWider = widgetAspectRatio < 1;
        let playlistWidth = restrictToBounds(widgetSize.width, 1, this.width);
        let playlistHeight = restrictToBounds(widgetSize.height, 1, 325);

        if (isPlaylistWider) {
          playlistHeight = playlistWidth * widgetAspectRatio;
        } else {
          playlistWidth = playlistHeight / widgetAspectRatio;
        }

        return {
          height: playlistHeight,
          width: playlistWidth,
        };
      },

      isHorizontalLayout() {
        return this.$store.state.layoutDesigner.layout.settings.isHorizontal;
      },

      playlistZoom() {
        const widgetSize = {
          height: this.isHorizontalLayout
            ? this.playlist.position.horizontal.heightPixels
            : this.playlist.position.vertical.heightPixels,
          width: this.isHorizontalLayout
            ? this.playlist.position.horizontal.widthPixels
            : this.playlist.position.vertical.widthPixels,
        };

        const widgetAspectRatio = widgetSize.height / widgetSize.width;
        const isPlaylistWider = widgetAspectRatio < 1;

        return isPlaylistWider ? this.width / widgetSize.width : 325 / widgetSize.height;
      },

      playlistItems() {
        return this.$store.getters.getPlaylistItems(this.playlist.object?.wid) || [];
      },

      playlistTotalTime() {
        const totalTime = this.playlistItems.reduce((timeSum, item) => {
          return timeSum + item.display_timer;
        }, 0);

        return transformSecondsToTimer(totalTime || 0);
      },
    },

    methods: {
      getTypeAmount(type) {
        return this.playlistItems.filter((item) => item.item_type.includes(type)).length;
      },

      handlePlaylistPlayer() {
        this.playSlider = !this.playSlider;

        this.playSlider ? this.previewPlayer?.initPlaylist() : this.previewPlayer?.pausePlayer();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .preview-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .preview {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
    }

    .previewBackground {
      background-color: #d9d9d9;
    }

    .preview-content {
      position: relative;
      cursor: pointer;

      .button-layer {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2000;
        width: 100%;
        height: 100%;
        transition:
          visibility 0s,
          opacity 0.3s linear;
        visibility: hidden;
        opacity: 0;

        .button-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 64px;
          height: 64px;

          border-radius: 100%;
          background: rgba(255, 255, 255, 0.45);
          border: 2px solid rgba(255, 255, 255, 0.15);

          i {
            font-size: 32px;
            color: $primaryRed;
          }
        }
      }

      &:hover {
        .button-layer {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .preview-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      i {
        color: $secondaryText;
      }

      .footer-item:hover {
        cursor: pointer;

        &.expand {
          color: $primaryRed;
        }
      }
    }
  }
</style>
