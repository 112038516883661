<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    title="Delete Role"
    okButtonLabel="Delete"
    :okFunction="deleteRole"
    :isLoading="isLoading"
  >
    <template v-slot:body>
      <div class="delete-description">Are you sure you want to delete it?</div>
    </template>
  </Modal>
</template>

<script>
  import { DELETE_ROLE, GET_ORGANISATION_ROLES } from '@/store/actions/roles';
  import Modal from '../../common/Modal.vue';

  export default {
    name: 'RemoveRoleModal',

    components: { Modal },

    props: {
      role: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        name: '',
        selectedLayer: '',
        selectedLayers: [],
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async deleteRole() {
        this.isLoading = true;

        try {
          const { successful, message } = await this.$store.dispatch(DELETE_ROLE, this.role.id);

          if (successful) {
            this.$toasted.global.general_success({
              message: 'Role deleted successfully',
            });

            await this.$store.dispatch(GET_ORGANISATION_ROLES);

            this.closeModal();
          } else {
            this.$toasted.global.general_error({
              message: message || 'Cannot delete the role',
            });
          }
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error) || 'Cannot delete the role',
          });
        }

        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped></style>
