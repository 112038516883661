<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    title="New Template"
    :modalStyles="{ width: '400px' }"
    :okFunction="createTemplate"
    :isSaving="isSaving"
    :disabled="templateName.length < 3 || templateName.length > 40 || isSaving"
    okButtonLabel="Create"
  >
    <template v-slot:body>
      <div class="content-wrapper">
        <BaseInput
          v-model="templateName"
          label="Name"
          placeholder="Template's name"
          minlength="3"
          maxlength="40"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  import { TEMPLATE_DEFAULT_DATA } from '@/models/templates';

  import { apiCreateTemplate, apiUpdateTemplate } from '@/api/templates';

  export default {
    name: 'CreateTemplateModal',

    components: { Modal },

    emits: ['template-created'],

    data() {
      return {
        showModal: false,
        isSaving: false,
        templateName: '',
      };
    },

    mounted() {
      this.showModal = true;
    },

    computed: {
      selectedFolder() {
        return this.$store.state.templatesBrowser.selectedFolder;
      },
    },

    methods: {
      async createTemplate() {
        this.isSaving = true;

        try {
          const templateData = { ...TEMPLATE_DEFAULT_DATA, name: this.templateName };

          const response = await apiCreateTemplate(templateData, this.selectedFolder?.id);

          if (response) {
            await this.updateTemplate(response.data);

            this.$router.push({
              name: 'Template',
              params: { templateId: response.data.template_id },
            });
          }
        } catch {
          this.$toasted.global.general_error({ message: 'Error creating template' });
        } finally {
          this.isSaving = false;
          this.closeModal();
        }
      },

      async updateTemplate(templateData) {
        try {
          const templateSettings = {
            settings: {
              ...templateData.settings,
              appId: templateData.app_id,
            },
            thumbnailImage: templateData.thumbnailImage,
          };

          await apiUpdateTemplate(templateData.template_id, templateSettings);
        } catch (error) {
          console.log('TemplatesFolderView.vue - updateTemplate error: ', error);
        }
      },

      closeModal() {
        this.showModal = false;
        this.templateName = '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
