<template>
  <div class="main-container" id="LayoutsView">
    <div class="content">
      <div class="content-body">
        <div class="Layouts">
          <div class="header">
            <div class="header-top">
              <div v-if="isAdmin" class="tabs">
                <div
                  :class="['tab', { active: !showLayoutTemplates }]"
                  @click="setShowLayoutTemplates(false)"
                >
                  <span class="material-icons-outlined align-middle">dashboard</span>
                  <span class="text">Layouts</span>
                </div>
                <div
                  :class="['tab', { active: showLayoutTemplates }]"
                  @click="setShowLayoutTemplates(true)"
                >
                  <span class="material-icons-outlined align-middle">dashboard_customize</span>
                  <span class="text">Layout Templates</span>
                </div>
              </div>
              <div v-else class="title">Layouts</div>
              <div class="header-buttons">
                <div class="header-button" title="New Layout">
                  <i class="material-icons-outlined align-middle button" @click="onNewLayout">
                    add
                  </i>
                </div>
              </div>
            </div>

            <div class="header-bottom">
              <SearchButton v-model="searchTerm" />
            </div>
          </div>

          <div ref="scrollBody" class="body scrollbar">
            <div class="cards-container" v-if="isLoading">
              <div v-for="i in 5" :key="`${i}-skeleton`" class="skeleton-card"></div>
            </div>

            <div v-else-if="layoutItems.length > 0" class="cards-container">
              <LayoutCard
                v-for="(layout, index) in layoutItems"
                :key="index"
                :layout="layout"
                :selected="selectedLayout?.layout_id === layout.layout_id"
                @layoutSelected="selectedLayout = layout"
              />
              <template v-if="isLoadingPage">
                <div v-for="i in 5" :key="`${i}-skeleton`" class="skeleton-card"></div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LayoutsSidebar
      v-if="selectedLayout"
      :layout="selectedLayout"
      :layoutTemplatesMode="showLayoutTemplates"
      @layoutUpdated="getLayouts"
      @layoutDeleted="layoutDeleted"
      @updateLayouts="getLayouts"
    />

    <LayoutSidebarPlaceholder
      v-else
      :templatesButton="!showLayoutTemplates && isAdmin"
      @onCreate="onNewLayout"
      @manageTemplates="setShowLayoutTemplates(true)"
    />

    <AddLayoutModal v-if="showNewLayoutModal" @closeModal="showNewLayoutModal = false" />
  </div>
</template>

<script>
  import _ from 'lodash';

  import { apiReadLayouts } from '@/api/layouts';

  import LayoutCard from '@/components/layouts/LayoutCard.vue';
  import AddLayoutModal from '@/components/layouts/AddLayoutModal.vue';
  import LayoutsSidebar from '@/components/layouts/LayoutsSidebar.vue';
  import LayoutSidebarPlaceholder from '@/components/layouts/LayoutSidebarPlaceholder.vue';
  import SearchButton from '@/components/common/SearchButton.vue';

  import { emptyLayoutImage } from '@/models/layoutDesigner/templates';

  import { RESET_LAYOUT } from '@/store/actions/layoutDesigner';
  import { RESET_PLAYER, SET_PLAYER_DEMO } from '@/store/actions/player';
  import { sortByDate } from '@/helpers/utils';

  export default {
    name: 'Layouts',

    components: {
      LayoutSidebarPlaceholder,
      LayoutCard,
      AddLayoutModal,
      LayoutsSidebar,
      SearchButton,
    },

    data() {
      return {
        searchTerm: '',
        showNewLayoutModal: false,
        showLayoutTemplates: false,
        showLayoutsSearchBox: false,
        selectedLayout: null,
        isLoading: false,
        isLoadingPage: false,
        isLastPage: false,
        query: {
          page: 1,
          name: '',
          items_per_page: 20,
        },
        layoutItems: [],
      };
    },

    mounted() {
      this.getLayouts();
      this.addScrollListener();

      this.$store.commit(SET_PLAYER_DEMO, true);
      this.$store.commit(RESET_PLAYER, {});
      this.$store.commit(RESET_LAYOUT);
    },

    beforeDestroy() {
      this.removeScrollListener();
    },

    watch: {
      searchTerm: _.debounce(function (val) {
        this.isLastPage = false;
        this.query.name = val;
        this.query.page = 1;
        this.getLayouts();
      }, 500),
    },

    computed: {
      isAdmin() {
        return this.$store.getters.isAdministrator;
      },
    },

    methods: {
      onSearch(term) {
        this.layoutSearchText = term;
      },

      onNewLayout() {
        this.showNewLayoutModal = true;
      },

      async getLayouts(loader = true, pageLoader = false) {
        this.isLoading = loader;
        this.isLoadingPage = pageLoader;
        try {
          const response = await apiReadLayouts(this.query, this.showLayoutTemplates);
          let layouts = sortByDate(response?.data?.items, 'created');

          if (!layouts) {
            this.$toasted.global.general_error({ message: 'Error Loading Layouts' });
          }

          layouts = layouts.map((layout) => ({
            layout_id: layout.layout_id,
            name: layout.settings.name,
            image: layout.settings.image || emptyLayoutImage,
            settings: layout.settings,
          }));

          if (this.query.page === 1) {
            this.layoutItems = layouts;
          } else {
            this.layoutItems = [...this.layoutItems, ...layouts];
          }

          if (layouts.length < this.query.items_per_page) {
            this.isLastPage = true;
          }
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: error.response.data.message });
        } finally {
          this.isLoading = false;
          this.isLoadingPage = false;
        }
      },

      addScrollListener() {
        this.$refs.scrollBody.addEventListener('wheel', this.handleScrollBodyScrolling);
      },

      removeScrollListener() {
        this.$refs.scrollBody.removeEventListener('wheel', this.handleScrollBodyScrolling);
      },

      handleScrollBodyScrolling(e) {
        const element = this.$refs.scrollBody;
        const hasTouchedEnd =
          element.scrollTop * 1.1 >= element.scrollHeight - element.offsetHeight;

        if (hasTouchedEnd) {
          this.handleScrollEnd();
        }
      },

      handleScrollEnd() {
        if (this.isLoadingPage || this.isLastPage) return;
        this.query.page = this.query.page + 1;
        this.getLayouts(false, true);
      },

      layoutSearchIconClick() {
        this.showLayoutsSearchBox = !this.showLayoutsSearchBox;
      },

      layoutDeleted() {
        this.getLayouts();
        if (this.selectedLayout?.layout_id) {
          this.layoutItems = this.layoutItems.filter(
            (layout) => layout.layout_id != this.selectedLayout.layout_id,
          );
        }
        this.selectedLayout = null;
      },

      setShowLayoutTemplates(state = true) {
        this.showLayoutTemplates = state;
        this.selectedLayout = null;
        this.query = {
          page: 1,
          name: '',
          items_per_page: 20,
        };

        this.getLayouts();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .Layouts {
    width: 100%;
    padding: 0 16px 40px;
    display: flex;
    flex-direction: column;

    .header-text-button {
      line-height: 24px;
      height: 30px;
    }

    .header-text-button,
    .header-button {
      &:hover i {
        color: $primaryRed;
      }
    }

    .header-bottom {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: space-between;
    }

    .body {
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding-right: 2rem;
      margin-right: -2rem;
    }

    .cards-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 2px;
    }

    .skeleton-card {
      width: 172px;
      height: 230px;
      background-color: white;
      animation: skeleton-pulse 4s infinite;
    }
  }

  .tabs {
    display: flex;
    width: 420px;

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      flex: 1;
      border-bottom: 2px solid #f0f0f0;
      padding: 8px 16px;
      width: 120px;

      transition: border-color 200ms ease-in-out;
      color: #6a6b6a;
      cursor: pointer;

      &:hover {
        border-color: #d6d6d6;
        color: #555555;
      }
      &.active {
        border-color: #e3003a;
        color: #151515;
      }

      .text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: inherit;
      }
    }
  }
</style>
