<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isSaving="isSaving"
    :disabled="!selectedTemplate"
    :okFunction="createTile"
    okButtonLabel="Attach"
    :modalStyles="{ width: '860px', height: '800px' }"
  >
    <template v-slot:header-title>
      <div>
        Add a template to <b v-if="playlist.name">{{ playlist.name }}</b> <span v-else>Area</span>
      </div>
    </template>

    <template v-slot:body>
      <TemplatesBrowser showBrowserMode :playlist="playlist" />
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import TemplatesBrowser from '@/components/templates/TemplatesBrowser.vue';

  export default {
    name: 'TemplateFolderBrowserModal',

    components: { Modal, TemplatesBrowser },

    props: {
      isTemplateForTiles: {
        type: Boolean,
        default: false,
      },
      playlist: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        showModal: false,
        isSaving: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    computed: {
      selectedTemplate() {
        return this.$store.state.templatesBrowser.selectedTemplate;
      },
    },

    methods: {
      async createTile() {
        this.$emit('createTile', this.selectedTemplate);
        this.closeModal();
      },

      async getTemplates() {
        await this.$store.dispatch(GET_TEMPLATES_FOLDER, {});
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped></style>
