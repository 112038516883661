<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :title="editMode ? 'Edit User' : 'Add User'"
    :modalStyles="{ width: '400px' }"
    avoidClickOutside
    :ok-function="editMode ? editUser : createUser"
    :ok-button-label="editMode ? 'Save' : 'Add User'"
    :is-loading="isLoading"
    class="align-items-start"
  >
    <template #body>
      <form @submit.prevent="editMode ? editUser() : createUser()" autocomplete="false">
        <div class="modal-body">
          <alert
            v-if="updateStatus == 'error'"
            styleclass="alert-danger"
            icon="error"
            :message="`Misslyckades att uppdatera: ${updateError}`"
          ></alert>

          <alert
            v-if="createStatus == 'error'"
            styleclass="alert-danger"
            icon="error"
            :message="`Misslyckades att skapa: ${createError}`"
          ></alert>

          <div class="group-body">
            <div class="form-group">
              <label for="email">
                <span class="label">Email</span>
                <input
                  type="text"
                  name="email"
                  id="email"
                  required
                  v-model="userData.email"
                  placeholder="Set email"
                  class="form-control"
                />
              </label>
            </div>

            <div class="form-group">
              <label for="fName">
                <span class="label">Full Name</span>
                <input
                  type="text"
                  name="name"
                  id="fName"
                  required
                  v-model="userData.name"
                  placeholder="Set full name"
                  class="form-control"
                />
              </label>
            </div>

            <div class="form-group">
              <label for="role">
                <span class="label">User Role</span>
                <select
                  class="custom-select"
                  name="role"
                  id="role"
                  v-model="userData.role"
                  required
                >
                  <option :value="null" selected>
                    {{ isLoadingRoles ? 'Loading...' : 'Choose here' }}
                  </option>
                  <option v-for="role in roles" :key="role.id" :value="role.id">
                    {{ role.name }}
                  </option>
                </select>
              </label>
            </div>

            <div class="form-check form-group">
              <label class="form-check-label flex-row" for="user-local-administrator">
                <input
                  name="user-local-administrator"
                  v-model="userData.is_local_administrator"
                  class="form-check-input"
                  type="checkbox"
                  value="false"
                  :disabled="!isAdmin"
                />
                Local administrator
                <i class="material-icons align-middle">supervisor_account</i>
              </label>
            </div>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
  import * as USER from '@/store/actions/user';

  import Modal from '../../common/Modal.vue';
  import SendButton from '../../common/SendButton.vue';
  import Alert from '../../Alert.vue';
  import { ADMIN_ORGANISATIONS_GET } from '@/store/actions/organisation';

  export default {
    name: 'UserModal',

    components: { Alert, Modal, SendButton },

    props: {
      user: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        userData: {
          user_id: undefined,
          created: undefined,
          username: '',
          email: '',
          is_local_administrator: false,
          is_administrator: false,
          role: null,
          name: '',
        },
        showModal: false,
        isLoading: false,
        isLoadingRoles: false,
      };
    },

    mounted() {
      this.showModal = true;

      if (this.user) {
        this.userData = {
          ...this.user,
          email: this.user.email || this.user.username,
        };
      }
    },

    computed: {
      roles() {
        return this.$store.state.roles.roles;
      },
      editMode() {
        return !!this.user;
      },
      updateStatus() {
        return this.$store.state.user.updateStatus;
      },
      updateError() {
        return this.$store.state.user.updateError;
      },
      createStatus() {
        return this.$store.state.user.createStatus;
      },
      createError() {
        return this.$store.state.user.createError;
      },
      isAdmin() {
        return this.$store.getters.isAdministrator;
      },
      organisationId() {
        return this.$store.state.organisation.organisation.organisation_id;
      },
    },

    methods: {
      async createUser() {
        if (!this.userData.name) {
          this.$toasted.global.general_error({ message: 'User name is required' });
          return;
        }
        if (!this.userData.email) {
          this.$toasted.global.general_error({ message: 'User email is required' });
          return;
        }
        if (!this.userData.role) {
          this.$toasted.global.general_error({ message: 'User role is required' });
          return;
        }
        this.isLoading = true;

        try {
          const userMail = this.userData.email.trim();
          const userData = {
            ...this.userData,
            username: userMail,
            url: window.location.origin,
          };
          userData.email = userMail;

          if (this.isAdmin) {
            await this.$store.dispatch(USER.ADMIN_USER_ADD, {
              organisation: this.organisationId,
              ...userData,
            });
          } else {
            await this.$store.dispatch(USER.USER_ADD, userData);
          }
          this.$emit('getUsers');
          this.$toasted.global.general_success({
            message:
              'User created. An email has been sent to the user with the link to set the password.',
          });
          await this.$store.dispatch(ADMIN_ORGANISATIONS_GET);
          this.closeModal();
        } catch (error) {
          this.$toasted.global.general_error({
            message: error?.response?.data?.message || 'Failed to create the user',
          });
        }

        this.isLoading = false;
      },

      async editUser() {
        const updateArgs = {
          userId: this.userData.user_id,
          userData: { ...this.userData, username: this.userData.email },
        };

        this.isLoading = true;

        try {
          if (this.isAdmin) {
            await this.$store.dispatch(USER.ADMIN_USER_UPDATE, updateArgs);
          } else {
            await this.$store.dispatch(USER.USER_UPDATE, updateArgs);
          }

          this.$toasted.global.general_success({
            message: 'Saved',
          });

          this.$emit('getUsers');
          this.closeModal();
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },

      async getOrganisationRoles() {
        this.isLoadingRoles = true;

        const fetchedSuccesfully = await this.$store.dispatch(
          GET_ORGANISATION_ROLES,
          this.userSearch,
        );

        if (!fetchedSuccesfully) {
          this.$toasted.global.general_error({
            message: "Ups, we weren't able to get data. Please refresh the page.",
          });
        }

        this.isLoadingRoles = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .group-body {
    display: grid;
    row-gap: 24px;
    column-gap: 16px;

    .form-group {
      width: 100%;
      margin-bottom: 0;

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 8px;

        font-weight: 500;
        line-height: 24px;

        .label {
          display: flex;
          gap: 8px;
          align-items: center;

          font-size: 14px;
        }
      }
    }

    .description {
      grid-column: 1/3;
    }
  }
</style>
