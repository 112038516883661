<script lang="ts">
  export default {
    name: 'FileDropZone',
  };
</script>

<script setup lang="ts">
  import { ref, defineEmits } from 'vue';
  import { ALLOWED_MAX_FILE_SIZE_IN_MB, ALLOWED_PLAYLIST_FILE_EXTENSIONS } from '@/constant/const';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';

  const props = withDefaults(
    defineProps<{
      allowMultiple: boolean;
    }>(),
    {
      allowMultiple: true,
    },
  );

  const emit = defineEmits<{
    (event: 'filesSelected', data: { selectedFiles: File[] }): void;
  }>();

  const inputRef = ref<HTMLInputElement | null>(null);

  function handleClick() {
    if (inputRef.value === null) return;
    inputRef.value.click();
  }

  function handleDrop(event: DragEvent) {
    const files = event.dataTransfer?.files;

    if (files && files.length > 0) {
      emit('filesSelected', { selectedFiles: Array.from(files) });
    }
  }

  function handleInputChange(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length > 0) {
      emit('filesSelected', { selectedFiles: Array.from(target.files) });
    }
  }
</script>

<template>
  <div
    class="drop-area"
    @click="handleClick"
    @drop.prevent="handleDrop"
    @dragenter.prevent
    @dragover.prevent
    @dragend.prevent
  >
    <div class="icon">
      <i class="material-icons-outlined">upload_file</i>
      <BaseText variant="subtitle2" class="title-text">
        Click to upload or drag and drop  {{props.allowMultiple? 'files' : 'file'}} here
      </BaseText>
      <BaseText variant="subtitle1" class="subtitle-text">
        Maximum file size *{{ ALLOWED_MAX_FILE_SIZE_IN_MB }} MB.
      </BaseText>
      <input
        v-show="false"
        ref="inputRef"
        type="file"
        :accept="ALLOWED_PLAYLIST_FILE_EXTENSIONS.toString()"
        @change.prevent="handleInputChange"
        :multiple="allowMultiple"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .drop-area {
    width: 100%;
    min-height: 194px;
    background-color: #f3f3f3;
    border: 2px dashed #e3003a;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ccc;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #e1e1e1;
    }

    #file-input {
      display: none;
    }

    &.filesSelected {
      margin-bottom: 24px;
    }
  }

  .icon {
    text-align: center;
    margin-bottom: 16px;
    i {
      font-size: 58px;
      color: #e3003a;
    }
  }

  .title-text {
    font-size: 16px;
    color: #151515;
    margin-bottom: 8px;

    .underline {
      text-decoration: underline;
    }
  }

  .subtitle-text {
    font-size: 14px;
    color: #6a6b6a;
  }
</style>
