<script lang="ts">
  export default {
    name: 'FileUploadRow',
  };
</script>

<script setup lang="ts">
  import { computed, toRefs, withDefaults, defineProps } from 'vue';
  import { getSimpleType } from '@/helpers';
  import ProgressBar from '@/components/ProgressBar.vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import { FileUploadStatus } from '@/components/common/fileUploadModal/types';
  import Loader from '@/components/common/Loader.vue';
  import { humanFileSize } from '@/helpers/filters';
  import { Colors } from '@/constant/theme';

  const props = withDefaults(
    defineProps<{
      fileType: string;
      fileName: string;
      fileSize: number;
      status?: FileUploadStatus | null;
      uploadPercent: number;
    }>(),
    {
      status: null,
    },
  );
  const { fileType, fileName, fileSize, status, uploadPercent } = toRefs(props);

  const emit = defineEmits<{ (event: 'remove', fileName: string): void }>();

  const progressBarColor = computed(() => {
    if (status.value === FileUploadStatus.Completed) {
      return Colors.GREEN_LIGHT;
    }

    return Colors.PRIMARY_RED;
  });

  const statusText = computed(() => {
    if (status.value === FileUploadStatus.Uploading) {
      return 'Uploading...';
    } else if (status.value === FileUploadStatus.PendingProcess) {
      return 'Waiting for the file to be processed...';
    } else if (status.value === FileUploadStatus.Processing) {
      return 'Processing file...';
    } else if (status.value === FileUploadStatus.Completed) {
      return 'Upload successful';
    } else if (status.value === FileUploadStatus.Failed) {
      return 'Upload failed';
    }

    return null;
  });

  const statusTextStyle = computed(() => {
    if (status.value === FileUploadStatus.Completed) {
      return {
        color: Colors.GREEN_LIGHT,
      };
    } else if (status.value === FileUploadStatus.Failed) {
      return {
        color: Colors.PRIMARY_RED,
      };
    }

    return null;
  });

  function handleRemove() {
    emit('remove', fileName.value);
  }
</script>

<template>
  <div class="file-upload-row-container">
    <div class="content-section">
      <div class="file-type-icon">
        <Loader
          v-if="
            status === FileUploadStatus.Uploading ||
            status === FileUploadStatus.PendingProcess ||
            status === FileUploadStatus.Processing
          "
        />
        <i class="material-icons-outlined" v-else-if="getSimpleType(fileType) === 'image'">
          crop_original
        </i>
        <i class="material-icons-outlined" v-else-if="getSimpleType(fileType) === 'video'">
          play_circle_outline
        </i>
        <i class="material-icons-outlined" v-else-if="getSimpleType(fileType) === 'audio'">
          audio_file
        </i>
        <i class="material-icons-outlined" v-else-if="getSimpleType(fileType) === 'pdf'"
          >picture_as_pdf</i
        >
      </div>
      <div class="file-info">
        <BaseText class="styled-text">{{ fileName }}</BaseText>
        <BaseText v-if="status === null" class="upload-status-text">
          {{ humanFileSize(fileSize) }}
        </BaseText>
        <BaseText v-else class="upload-status-text" :style="statusTextStyle">
          {{ statusText }}
        </BaseText>
      </div>
      <i class="close-button material-icons-outlined" @click="handleRemove">close</i>
    </div>
    <ProgressBar :percent="uploadPercent" :color="progressBarColor" />
  </div>
</template>

<style scoped lang="scss">
  .file-upload-row-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    background-color: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
  }

  .content-section {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    height: 32px;
    max-height: 32px;
  }

  .file-type-icon {
    position: relative;
    min-width: 32px;
    height: 32px;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      color: $secondaryText;
    }
  }

  .styled-text {
    color: #151515;
    font-weight: bold;
    font-size: 12px;
    line-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .upload-status-text {
    color: $secondaryText;
    font-weight: 400;
    font-size: 12px;
    line-height: 1rem;
  }

  .file-info {
    width: 100%;
    max-width: 364px;
    height: 100%;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    color: #323232;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $primaryRed;
    }
  }
</style>
