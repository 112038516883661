<template>
  <div class="template-view-container">
    <TemplatesBrowser />

    <TemplatesSidebar />
  </div>
</template>

<script>
  import TemplatesSidebar from '@/components/templates/TemplatesSidebar.vue';
  import TemplatesBrowser from '@/components/templates/TemplatesBrowser.vue';

  export default {
    name: 'TemplatesFolderView',

    components: {
      TemplatesBrowser,
      TemplatesSidebar,
    },
  };
</script>

<style lang="scss" scoped>
  .template-view-container {
    font-family: 'Poppins';
    display: grid;
    grid-template-columns: auto 376px;
    width: 100%;
    height: 100vh;
    color: #151515;
    background-color: #f7f8f7;
  }
</style>
