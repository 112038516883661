<template>
  <div class="main-container" id="layout-designer-screen">
    <div class="content">
      <div class="content-body">
        <div class="Layouts">
          <LayoutDesignerHeader @openDeleteModal="showDeleteLayoutModal = true" />

          <div class="body scrollbar" @click="deselectWidget">
            <Layout v-show="layout" :layout="layout" />
          </div>
        </div>
      </div>
    </div>
    <Editor />

    <DeleteLayoutModal
      v-if="showDeleteLayoutModal"
      @closeModal="showDeleteLayoutModal = false"
      :layoutId="layout ? layout.layout_id : null"
      @deleted="handleDeleteLayout"
    />
  </div>
</template>

<script>
  import Layout from '@/components/layoutDesigner/designer/Layout.vue';
  import Editor from '@/components/layoutDesigner/editor/Index.vue';
  import DeleteLayoutModal from '@/components/layouts/DeleteLayoutModal.vue';
  import LayoutDesignerHeader from '@/components/layoutDesigner/designer/LayoutDesignerHeader';

  import { SET_IS_PLAYLIST_PREVIEW_ON } from '@/store/actions/playlist';
  import {
    LAYOUT_DESIGNER_DESELECT_WIDGET,
    LOAD_LAYOUT,
    RESET_LAYOUT,
    SET_SAVE_LAYOUT,
  } from '@/store/actions/layoutDesigner';
  import { GET_TEMPLATES } from '@/store/actions/templatesBrowser';

  export default {
    name: 'LayoutDesigner',

    components: {
      Editor,
      Layout,
      DeleteLayoutModal,
      LayoutDesignerHeader,
    },

    data() {
      return {
        showDeleteLayoutModal: false,
      };
    },

    async mounted() {
      this.$store.commit(RESET_LAYOUT);
      this.$store.commit(SET_IS_PLAYLIST_PREVIEW_ON, false);
      this.getLayoutData();
      this.getTemplates();
    },

    beforeRouteLeave(to, from, next) {
      if (this.allowLeave) {
        next();
        return;
      }

      next(window.confirm('Are you sure to leave without saving your changes?'));
    },

    beforeDestroy() {
      this.removeListener();
    },

    watch: {
      allowLeave(allow) {
        if (allow) {
          this.removeListener();
          return;
        }

        window.addEventListener('beforeunload', this.leaving);
      },
    },

    computed: {
      layout() {
        return this.$store.state.layoutDesigner.layout;
      },

      allowLeave() {
        return !this.$store.state.layoutDesigner.saveLayout;
      },
    },

    methods: {
      removeListener() {
        window.removeEventListener('beforeunload', this.leaving);
      },

      leaving(event) {
        event.preventDefault();

        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;
      },

      deselectWidget() {
        this.$store.commit(LAYOUT_DESIGNER_DESELECT_WIDGET);
      },

      async getLayoutData() {
        const layoutId = this.$route.params.layout_id;

        const result = await this.$store.dispatch(LOAD_LAYOUT, {
          layoutId,
          loadChildren: true,
        });

        if (!result) {
          this.$toasted.global.general_error({
            message: 'Layout not found',
          });

          this.$router.push({ name: 'Layouts' });
        }
      },

      async getTemplates() {
        await this.$store.dispatch(GET_TEMPLATES, {});
      },

      async handleDeleteLayout() {
        this.$store.commit(SET_SAVE_LAYOUT, false);
        this.$router.push({ name: 'Layouts' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .Layouts {
    width: 100%;
    padding: 0 16px 40px;
    display: flex;
    flex-direction: column;

    .body {
      display: flex;
      flex-direction: column;
      margin-right: -40px;
      margin-top: -16px;

      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
</style>
