<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    title="Create New Template"
    :okFunction="createTemplate"
  >
    <template v-slot:body>
      <div class="modal-description">
        To create a new template you'll need to save your changes. Do you want to save your changes?
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  import { SET_SAVE_LAYOUT_FOR_TEMPLATE } from '@/store/actions/layoutDesigner';

  export default {
    name: 'AddNewTemplateModal',

    components: { Modal },

    props: {
      playlist: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        showModal: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async createTemplate() {
        this.$store.commit(SET_SAVE_LAYOUT_FOR_TEMPLATE, { playlist: this.playlist });
        this.closeModal();
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>
