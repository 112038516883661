<template>
  <div class="layer-widget-panel scrollbar">
    <div v-if="!isWeatherApp || isOldWeatherApp" class="form-group template-input">
      <label for="template-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="template-name"
        placeholder="Name"
        v-model="value.object.name"
        disabled
      />
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon v-show="isWidgetLocked" :locked="isWidgetLocked" />
        <VisibilityIcon :isVisible="isWidgetVisible" @clicked="updateVisibility" />
        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <button
      v-if="!isWeatherApp"
      type="button"
      class="button-2-secondary"
      :class="{ disabled: isWidgetLockedByOverride }"
      :disabled="isWidgetLockedByOverride"
      @click="openContentBrowser"
    >
      <i class="material-icons-outlined align-middle">apps</i>
      Replace App
    </button>

    <template v-if="value.object.item_type === 'app/trafiklab'">
      <TrafiklabPanelSetting
        :viewer-styles="value.object.viewerStyles"
        :disabled="isWidgetLockedByOverride"
        @update:viewerStyles="
          (newViewerStyles) => {
            updateViewerStyles(newViewerStyles);
          }
        "
      />
    </template>

    <!-------- WEATHER APP - START ---------->
    <template v-if="isWeatherApp && !isOldWeatherApp">
      <div v-if="showLocationPicker" class="location-container">
        <LocationPicker @updateLocation="updateLocation" />

        <button
          v-show="showLocationPicker"
          type="button"
          class="button-2-secondary"
          @click="showLocationPicker = false"
        >
          Cancel
        </button>
      </div>

      <template v-if="!showLocationPicker && value.object.weather.location">
        <div class="form-group settings-input mt-3 mb-0" style="border-bottom: 0">
          <label for="template-name">Location</label>
          <input
            class="form-control"
            type="text"
            id="template-name"
            placeholder="Name"
            maxlength="30"
            v-model="value.object.weather.location.label"
          />
          <div
            v-show="value.object.weather.location"
            class="remove-button"
            @click="showLocationPicker = true"
          >
            Change Location
          </div>
        </div>

        <div class="form-group template-input">
          <label for="template-name">Widget Name</label>
          <input
            class="form-control"
            type="text"
            id="template-name"
            placeholder="Name"
            v-model="value.object.weather.name"
          />
        </div>

        <div class="form-group layout-input content-type-input">
          <label>Widget Type</label>
          <select
            class="form-control"
            id="content-mode"
            v-model="weatherType"
            :disabled="isWidgetLockedByOverride"
          >
            <option :value="WEATHER_DISPLAY_TYPE?.NOW.value">
              {{ WEATHER_DISPLAY_TYPE?.NOW.label }}
            </option>
            <option :value="WEATHER_DISPLAY_TYPE?.HOURS.value">
              {{ WEATHER_DISPLAY_TYPE?.HOURS.label }}
            </option>
            <option :value="WEATHER_DISPLAY_TYPE?.DAYS.value">
              {{ WEATHER_DISPLAY_TYPE?.DAYS.label }}
            </option>
          </select>
        </div>

        <div class="form-group template-input">
          <label class="font-poppins t-sm" for="language">Language</label>
          <select
            class="form-control input-underline"
            id="language"
            v-model="value.object.weather.language"
          >
            <option :value="WEATHER_LANGUAGES.ENGLISH.value">
              {{ WEATHER_LANGUAGES.ENGLISH.label }}
            </option>
            <option :value="WEATHER_LANGUAGES.SWEDISH.value">
              {{ WEATHER_LANGUAGES.SWEDISH.label }}
            </option>
          </select>
        </div>

        <div class="font-color-container">
          <div class="font-container form-group layout-input">
            <label for="template-name">Font</label>
            <FontSelector
              :disabled="isWidgetLockedByOverride"
              :value="value.object.weather.textFont"
              :fonts="userFonts"
              @update:value="updateWeatherFont"
            ></FontSelector>
          </div>

          <div class="color-section" v-click-outside="closeColorPicker">
            <div class="color-button" @click="toggleColorPicker('fontColor')" title="Font Color">
              <i class="material-icons-outlined align-middle">text_fields</i>
              <div
                class="color-bar"
                :style="{ backgroundColor: value.object.weather.fontColor }"
              ></div>
            </div>
            <div
              class="color-button"
              @click="toggleColorPicker('backgroundColor')"
              title="Background Color"
            >
              <img class="logo w-165" src="@/assets/icon/Colors.svg" alt="Background Color" />
              <div
                class="color-bar"
                :style="{ backgroundColor: value.object.weather.backgroundColor }"
              ></div>
            </div>
            <div class="color-button" @click="toggleColorPicker('iconColor')" title="Icon Color">
              <i class="material-icons-outlined align-middle">light_mode</i>
              <div
                class="color-bar"
                :style="{ backgroundColor: value.object.weather.iconColor }"
              ></div>
            </div>

            <Sketch
              v-if="showColorPicker"
              class="picker"
              :value="value.object.weather[pickerColorKey]"
              @input="updateColor"
            />
          </div>
        </div>

        <div class="form-group template-input">
          <label class="font-poppins t-sm" for="temperature">Temperature</label>
          <select
            class="form-control input-underline"
            id="temperature"
            v-model="value.object.weather.temperatureUnit"
          >
            <option :value="WEATHER_TEMPERATURE.CELSIUS.value">
              {{ WEATHER_TEMPERATURE.CELSIUS.label }}
            </option>
            <option :value="WEATHER_TEMPERATURE.FAHRENHEIT.value">
              {{ WEATHER_TEMPERATURE.FAHRENHEIT.label }}
            </option>
          </select>
        </div>

        <div
          v-if="value.object.weather.type === WEATHER_DISPLAY_TYPE.DAYS.value"
          class="form-group template-input"
        >
          <label class="font-poppins t-sm" for="time-format">Weekday Format</label>
          <select
            class="form-control input-underline"
            id="time-format"
            v-model="value.object.weather.dayFormat"
          >
            <option :value="WEATHER_DAY_FORMAT.SHORT.value">
              {{ WEATHER_DAY_FORMAT.SHORT.label }}
            </option>
            <option :value="WEATHER_DAY_FORMAT.WORD.value">
              {{ WEATHER_DAY_FORMAT.WORD.label }}
            </option>
            <option :value="WEATHER_DAY_FORMAT.DAY_OF_MONTH.value">
              {{ WEATHER_DAY_FORMAT.DAY_OF_MONTH.label }}
            </option>
          </select>
        </div>

        <div
          v-if="value.object.weather.type === WEATHER_DISPLAY_TYPE.HOURS.value"
          class="form-group template-input"
        >
          <label class="font-poppins t-sm" for="time-format">Time Format</label>
          <select
            class="form-control input-underline"
            id="time-format"
            v-model="value.object.weather.timeFormat"
          >
            <option :value="WEATHER_HOURS_FORMAT.TWELVE.value">
              {{ WEATHER_HOURS_FORMAT.TWELVE.label }}
            </option>
            <option :value="WEATHER_HOURS_FORMAT.TWENTY_FOUR.value">
              {{ WEATHER_HOURS_FORMAT.TWENTY_FOUR.label }}
            </option>
          </select>
        </div>

        <div class="location-text">
          <div class="floor-switch">
            <div class="custom-control custom-switch check">
              <input
                type="checkbox"
                class="custom-control-input"
                id="location-text"
                v-model="value.object.weather.showLocationText"
              />
              <label class="custom-control-label" for="location-text"></label>
            </div>
          </div>

          <BaseText variant="inputLabel">Show Location</BaseText>
        </div>
      </template>
    </template>
    <!-------- WEATHER APP - END---------->

    <!-------- OLD WEATHER APP - START ---------->
    <template v-if="isOldWeatherApp">
      <template v-if="value.object.visibility">
        <div class="form-group ticker">
          <div class="custom-control custom-switch templates">
            <input
              type="checkbox"
              class="custom-control-input"
              id="weather-time"
              :disabled="disableTimeSwitch"
              v-model="value.object.visibility.time"
            />
            <label class="custom-control-label" for="weather-time">Show Time</label>
          </div>
        </div>

        <div class="form-group ticker">
          <div class="custom-control custom-switch templates">
            <input
              type="checkbox"
              class="custom-control-input"
              id="weather-date"
              :disabled="disableDateSwitch"
              v-model="value.object.visibility.date"
            />
            <label class="custom-control-label" for="weather-date">Show Date</label>
          </div>
        </div>

        <div class="form-group ticker">
          <div class="custom-control custom-switch templates">
            <input
              type="checkbox"
              class="custom-control-input"
              id="weather-weather"
              :disabled="disableWeatherSwitch"
              v-model="value.object.visibility.weather"
            />
            <label class="custom-control-label" for="weather-weather">Show Weather</label>
          </div>
        </div>
      </template>

      <div class="sub-section-title">Alignment</div>

      <div class="form-group alignment d-flex">
        <div class="alignments form-inline">
          <div class="alignment-mode align-content-center align-middle">
            <input
              type="radio"
              id="text-left-align"
              class="custom-control-input"
              hidden
              :value="ALIGN_MODES.LEFT_ALIGN"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.background.alignment"
            />
            <label for="text-left-align" class="alignment-label">
              <i class="material-icons-outlined align-middle">align_horizontal_left</i>
            </label>
          </div>

          <div class="alignment-mode">
            <input
              type="radio"
              id="text-center-align"
              class="custom-control-input"
              hidden
              :value="ALIGN_MODES.CENTER_ALIGN"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.background.alignment"
            />
            <label for="text-center-align" class="alignment-label">
              <i class="material-icons-outlined align-middle">align_horizontal_center</i>
            </label>
          </div>

          <div class="alignment-mode">
            <input
              type="radio"
              id="text-right-align"
              class="custom-control-input"
              hidden
              :value="ALIGN_MODES.RIGHT_ALIGN"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.background.alignment"
            />
            <label for="text-right-align" class="alignment-label">
              <i class="material-icons-outlined align-middle">align_horizontal_right</i>
            </label>
          </div>
        </div>
      </div>

      <div class="sub-section-title">Background Color</div>

      <div class="form-group hidden size">
        <div class="form-row">
          <color-picker
            input-el-id="text-color"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.background.color"
          />

          <div class="col input-group" title="Transparency">
            <div class="input-label">
              <span class="input-group-text">
                <i class="material-icons-outlined align-middle">opacity</i>
              </span>
            </div>
            <input
              type="number"
              class="form-control"
              id="header-text-opacity"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.background.transparency"
              @change="(event) => limitOpacity(event.target.value, 'background.transparency', 100)"
            />
          </div>
        </div>
      </div>

      <div class="sub-section-title">Text</div>

      <div class="form-group hidden size" title="Date Text Size">
        <div class="form-row">
          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">T1</span>
            </div>

            <input
              type="number"
              min="0"
              max="120"
              class="form-control"
              :disabled="isWidgetLockedByOverride"
              v-model="textSizeLeft1"
            />
          </div>

          <div class="input-group col" title="Clock Text Size">
            <div class="input-label">
              <span class="input-group-text">T2</span>
            </div>
            <input
              type="number"
              min="0"
              max="120"
              class="form-control"
              :disabled="isWidgetLockedByOverride"
              v-model="textSizeLeft2"
            />
          </div>
        </div>
      </div>

      <div class="form-group hidden size">
        <div class="form-row">
          <div class="input-group col" title="Degrees Text Size">
            <div class="input-label">
              <span class="input-group-text">T3</span>
            </div>

            <input
              type="number"
              min="0"
              max="120"
              class="form-control"
              :disabled="isWidgetLockedByOverride"
              v-model="textSizeRight"
            />
          </div>

          <div class="input-group col"></div>
        </div>
      </div>

      <div class="form-group template-input logo-size input-la">
        <label for="logo-size">Image size (px)</label>

        <div class="input-group">
          <input
            type="number"
            min="0"
            max="500"
            class="form-control"
            id="logo-size"
            :disabled="isWidgetLockedByOverride"
            v-model="logoSize"
          />
        </div>

        <div class="sub-section-title">Division line</div>

        <div class="form-group hidden size">
          <div class="form-row" title="Line Color">
            <color-picker
              input-el-id="line-color"
              :disabled="isWidgetLockedByOverride"
              v-model="lineColor"
            />

            <div class="input-group col" title="Line Width">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>

              <input
                type="number"
                min="0"
                max="20"
                class="form-control"
                :disabled="isWidgetLockedByOverride"
                v-model="lineWidth"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-------- OLD WEATHER APP - END---------->

    <!-------- TABLE APP - START ---------->
    <template v-if="value.object.item_type === 'app/table'">
      <div class="form-group hidden size">
        <div class="form-row">
          <div class="input-group col" title="Time Interval (seconds)">
            <div class="input-label">
              <span class="input-group-text">Time</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="layer-width"
              :disabled="isWidgetLockedByOverride"
              v-model="tableInterval"
            />
          </div>

          <div class="input-group col" title="Rows per page">
            <div class="input-label">
              <span class="input-group-text">Rows</span>
            </div>
            <input
              type="number"
              class="form-control"
              :disabled="isWidgetLockedByOverride"
              v-model="tableRows"
            />
          </div>
        </div>
      </div>

      <div class="sub-section-title">Background</div>

      <div class="form-group hidden">
        <div class="form-row">
          <color-picker
            input-el-id="heading-row-color"
            title="Background Color"
            :disabled="isWidgetLockedByOverride"
            v-model="tableBackgroundColor"
          />
        </div>
      </div>

      <div class="sub-section-title">Headings</div>

      <div class="form-group hidden">
        <div class="form-row">
          <color-picker
            input-el-id="heading-row-color"
            title="Background Color"
            :disabled="isWidgetLockedByOverride"
            v-model="headingBackgroundColor"
          />

          <div class="input-group col" title="Font Size">
            <div class="input-label">
              <span class="input-group-text">Size</span>
            </div>

            <input
              type="number"
              min="0"
              max="20"
              class="form-control"
              :disabled="isWidgetLockedByOverride"
              v-model="headingFontSize"
            />
          </div>
        </div>
      </div>

      <div class="form-group alignment d-flex">
        <div class="alignments form-inline">
          <div class="alignment-mode align-content-center align-middle">
            <input
              type="radio"
              id="heading-left-align"
              class="custom-control-input"
              hidden
              :value="ALIGN_MODES.LEFT_ALIGN"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.styles.heading.alignment"
            />
            <label for="heading-left-align" class="alignment-label">
              <i class="material-icons align-middle">format_align_left</i>
            </label>
          </div>

          <div class="alignment-mode">
            <input
              type="radio"
              id="heading-center-align"
              class="custom-control-input"
              hidden
              :value="ALIGN_MODES.CENTER_ALIGN"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.styles.heading.alignment"
            />
            <label for="heading-center-align" class="alignment-label">
              <i class="material-icons align-middle">format_align_center</i>
            </label>
          </div>

          <div class="alignment-mode">
            <input
              type="radio"
              id="heading-right-align"
              class="custom-control-input"
              hidden
              :value="ALIGN_MODES.RIGHT_ALIGN"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.styles.heading.alignment"
            />
            <label for="heading-right-align" class="alignment-label">
              <i class="material-icons align-middle">format_align_right</i>
            </label>
          </div>
        </div>
      </div>

      <div class="sub-section-title">Rows</div>

      <div class="form-group hidden">
        <div class="form-row">
          <color-picker
            input-el-id="body-row-color"
            title="Background Color"
            :disabled="isWidgetLockedByOverride"
            v-model="bodyBackgroundColor"
          />

          <div class="input-group col" title="Font Size">
            <div class="input-label">
              <span class="input-group-text">Size</span>
            </div>

            <input
              type="number"
              min="0"
              max="20"
              class="form-control"
              :disabled="isWidgetLockedByOverride"
              v-model="bodyFontSize"
            />
          </div>
        </div>
      </div>

      <div class="form-group alignment d-flex">
        <div class="alignments form-inline">
          <div class="alignment-mode align-content-center align-middle">
            <input
              type="radio"
              id="body-left-align"
              class="custom-control-input"
              hidden
              :value="ALIGN_MODES.LEFT_ALIGN"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.styles.body.alignment"
            />
            <label for="body-left-align" class="alignment-label">
              <i class="material-icons align-middle">format_align_left</i>
            </label>
          </div>

          <div class="alignment-mode">
            <input
              type="radio"
              id="body-center-align"
              class="custom-control-input"
              hidden
              :value="ALIGN_MODES.CENTER_ALIGN"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.styles.body.alignment"
            />
            <label for="body-center-align" class="alignment-label">
              <i class="material-icons align-middle">format_align_center</i>
            </label>
          </div>

          <div class="alignment-mode">
            <input
              type="radio"
              id="body-right-align"
              class="custom-control-input"
              hidden
              :value="ALIGN_MODES.RIGHT_ALIGN"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.styles.body.alignment"
            />
            <label for="body-right-align" class="alignment-label">
              <i class="material-icons align-middle">format_align_right</i>
            </label>
          </div>
        </div>
      </div>

      <div class="sub-section-title">Lines</div>

      <div class="form-group hidden">
        <div class="form-row">
          <color-picker
            input-el-id="heading-row-color"
            title="Lines Color"
            :disabled="isWidgetLockedByOverride"
            v-model="tableLinesColor"
          />

          <div class="input-group col" title="Lines Thickness">
            <div class="input-label">
              <span class="input-group-text">T</span>
            </div>

            <input
              type="number"
              min="0"
              max="20"
              class="form-control"
              :disabled="isWidgetLockedByOverride"
              v-model="tableLines"
            />
          </div>
        </div>
      </div>
    </template>
    <!-------- TABLE APP - END ---------->

    <!-------- RSS APP - START ---------->
    <template v-if="value.object.item_type === 'app/rss'">
      <div class="content-section">
        <div class="form-row text-css">
          <div class="col form-group layout-input">
            <label for="rss-duration">
              <i class="material-icons-outlined align-middle">timer</i>
              Duration
            </label>

            <input
              type="number"
              class="form-control"
              id="rss-duration"
              :disabled="isWidgetLockedByOverride"
              v-model="rssSlideDuration"
            />
          </div>

          <div class="col form-group layout-input">
            <label for="content-size">
              <i class="material-icons align-middle">text_fields</i>
              Title
            </label>

            <input
              type="number"
              class="form-control"
              id="title-size"
              :disabled="isWidgetLockedByOverride"
              v-model="titleFontSize"
            />
          </div>
        </div>

        <div class="form-row text-css">
          <div class="col form-group layout-input">
            <label for="content-size">
              <img src="@/assets/icon/padding-icon.svg" alt="" />
              Spacing
            </label>
            <input
              type="number"
              class="form-control"
              id="rss-spacing"
              :disabled="isWidgetLockedByOverride"
              v-model="rssSpacing"
            />
          </div>

          <div class="col form-group layout-input">
            <label for="content-size">
              <i class="material-icons-outlined align-middle">pause_presentation</i>
              Padding
            </label>

            <input
              type="number"
              class="form-control"
              id="padding"
              :disabled="isWidgetLockedByOverride"
              v-model="rssPadding"
            />
          </div>
        </div>
      </div>

      <div class="content-section">
        <div class="test">
          <div class="section-title">
            <div class="form-group">
              <div class="custom-control custom-switch layouts">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="rss-name"
                  :disabled="isWidgetLockedByOverride"
                  v-model="value.object.display.rssName"
                />
                <label class="custom-control-label" for="rss-name">Feed name</label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="value.object.display.rssName" class="form-row text-css">
          <div class="col form-group layout-input">
            <label for="feed-name-size">
              <i class="material-icons align-middle">text_fields</i>
              Font size
            </label>

            <input
              type="number"
              class="form-control"
              id="feed-name-size"
              :disabled="isWidgetLockedByOverride"
              v-model="feedNameSize"
            />
          </div>
        </div>

        <div class="content-section">
          <div class="section-title">
            <div class="custom-control custom-switch layouts">
              <input
                type="checkbox"
                class="custom-control-input"
                id="rss-content"
                :disabled="isWidgetLockedByOverride"
                v-model="value.object.display.content"
              />
              <label class="custom-control-label" for="rss-content">Content</label>
            </div>
          </div>

          <div v-if="value.object.display.content" class="form-row text-css">
            <div class="col form-group layout-input">
              <label for="content-size">
                <i class="material-icons align-middle">text_fields</i>
                Font size
              </label>

              <input
                type="number"
                class="form-control"
                id="content-size"
                :disabled="isWidgetLockedByOverride"
                v-model="contentSize"
              />
            </div>

            <div class="col form-group layout-input">
              <label for="content-size">
                <i class="material-icons-outlined align-middle">content_cut</i>
                Characters limit
              </label>

              <input
                type="number"
                class="form-control"
                id="content-size"
                :disabled="isWidgetLockedByOverride"
                v-model="contentLength"
              />
            </div>
          </div>
        </div>

        <div class="content-section">
          <div class="section-title">
            <div class="custom-control custom-switch layouts">
              <input
                type="checkbox"
                class="custom-control-input"
                id="rss-image"
                :disabled="isWidgetLockedByOverride"
                v-model="value.object.display.image"
              />
              <label class="custom-control-label" for="rss-image">Feed Image</label>
            </div>
          </div>

          <image-mode
            v-if="value.object.display.image"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.display.imageMode"
          />

          <div
            v-if="value.object.display.image"
            class="form-group layout-input text-size slider-container"
          >
            <label for="image-width" class="slider"> Image Width </label>
            <input
              type="range"
              class="custom-range"
              min="20"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.display.imageWidth"
              max="80"
              step="5"
              id="image-width"
            />
          </div>
        </div>
      </div>
    </template>
    <!-------- RSS APP - END ---------->

    <ContentBrowserModal
      ref="content_browser_modal"
      @submitted="[replaceApp($event), closeModal()]"
      :allow-multiple-item-select="false"
      :filterItemTypes="appFilter"
    />
  </div>
</template>

<script>
  import { Sketch } from 'vue-color';
  import ClickOutside from 'vue-click-outside';

  import { restrictToBounds } from '@/helpers/draggable';

  import ColorPicker from '@/components/ColorPicker.vue';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import VisibilityIcon from '@/components/layoutDesigner/editor/settingPanel/VisibilityIcon.vue';
  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import ImageMode from '@/components/templates/editor/settingPanel/formControls/ImageMode.vue';
  import TrafiklabPanelSetting from '@/components/common/appWidgetPanel/trafiklabSettingPanel/TrafiklabSettingPanel.vue';
  import FontSelector from '@/components/common/fontSelector/FontSelector.vue';
  import LocationPicker from '@/components/apps/weather/LocationPicker.vue';
  import BaseText from '@/components/common/ui/atoms/baseText/BaseText.vue';

  import { WIDGET_CONTENT_TYPE, WIDGET_TYPES, ALIGN_MODES } from '@/models/layoutDesigner';
  import { CONTENT_TYPE_WEATHER_APP } from '@/constant/const';
  import {
    weatherDefaultOptions,
    WEATHER_DISPLAY_TYPE,
    WEATHER_TEMPERATURE,
    WEATHER_HOURS_FORMAT,
    WEATHER_DAY_FORMAT,
    WEATHER_LANGUAGES,
  } from '@/constant/weatherApp';

  import { TEMPLATE_REPLACE_CHILD_ITEM } from '@/store/actions/templateDesigner';

  import widgetMixin from '@/components/templates/editor/settingPanel/widgetMixin';

  export default {
    name: 'AppWidgetPanel',

    mixins: [widgetMixin],

    directives: {
      ClickOutside,
    },

    components: {
      TrafiklabPanelSetting,
      ColorPicker,
      ImageMode,
      ContentBrowserModal,
      PixelIcon,
      LockIcon,
      VisibilityIcon,
      FontSelector,
      LocationPicker,
      Sketch,
      BaseText,
    },

    data() {
      return {
        appFilter: [WIDGET_CONTENT_TYPE.APP],
        showColorPicker: false,
        pickerColorKey: null,
        ALIGN_MODES,
        WEATHER_DISPLAY_TYPE,
        WEATHER_TEMPERATURE,
        WEATHER_HOURS_FORMAT,
        WEATHER_DAY_FORMAT,
        WEATHER_LANGUAGES,
        folderName: '',
        showLocation: false,
        showLocationPicker: false,
      };
    },

    created() {
      if (
        this.value.object.item_type === 'app/weather' &&
        this.value.object.weather &&
        ![true, false].includes(this.value.object.weather.showLocationText)
      ) {
        this.$set(this.value.object.weather, 'showLocationText', true);
      }

      if (this.value.object.item_type === 'app/rss' && !this.value.object.display) {
        const displayDefaultOptions = {
          rssName: true,
          content: true,
          image: true,
          imageWidth: 25,
          imageMode: 'cover',
          feedNameSize: 16,
          titleFontSize: 30,
          contentSize: 20,
          contentLength: 200,
          spacing: 16,
          padding: 16,
          duration: 10,
        };

        this.$set(this.value.object, 'display', displayDefaultOptions);
      }
    },

    computed: {
      userFonts() {
        return this.$store.getters.userFonts;
      },

      // ------ TABLE APP -------- //
      tableInterval: {
        get() {
          return this.value.object.styles.pagination.interval;
        },
        set(value) {
          this.$set(
            this.value.object.styles.pagination,
            'interval',
            restrictToBounds(Number(value), 0, 3600),
          );
        },
      },

      tableRows: {
        get() {
          return this.value.object.styles.pagination.per_page;
        },
        set(value) {
          this.$set(
            this.value.object.styles.pagination,
            'per_page',
            restrictToBounds(Number(value), 1, 60),
          );
        },
      },

      tableBackgroundColor: {
        get() {
          const style = this.value.object.styles;
          const defaultColor = style.table.theme === 'white-grey' ? '#2D2D2D' : '#FFFFFF';

          return style.table.backgroundColor || defaultColor;
        },
        set(value) {
          this.$set(this.value.object.styles.table, 'backgroundColor', value);
        },
      },

      tableLinesColor: {
        get() {
          return this.value.object.styles.table.linesColor || '#2D2D2D';
        },
        set(value) {
          this.$set(this.value.object.styles.table, 'linesColor', value);
        },
      },

      tableLines: {
        get() {
          return this.value.object.styles.table.lineWidth || 1;
        },
        set(value) {
          this.$set(
            this.value.object.styles.table,
            'lineWidth',
            restrictToBounds(Number(value), 0, 20),
          );
        },
      },

      headingFontSize: {
        get() {
          return this.value.object.styles.heading.fontSize || 20;
        },
        set(value) {
          this.$set(
            this.value.object.styles.heading,
            'fontSize',
            restrictToBounds(Number(value), 0, 120),
          );
        },
      },

      headingBackgroundColor: {
        get() {
          const style = this.value.object.styles;
          const defaultColor = style.table.theme === 'white-grey' ? '#5A5A5B' : '#FFFFFF';

          return style.heading.backgroundColor || defaultColor;
        },
        set(value) {
          this.$set(this.value.object.styles.heading, 'backgroundColor', value);
        },
      },

      bodyFontSize: {
        get() {
          return this.value.object.styles.body.fontSize || 16;
        },
        set(value) {
          this.$set(
            this.value.object.styles.body,
            'fontSize',
            restrictToBounds(Number(value), 0, 120),
          );
        },
      },

      bodyBackgroundColor: {
        get() {
          const style = this.value.object.styles;
          const defaultColor = style.table.theme === 'white-grey' ? '#5A5A5B' : '#FFFFFF';

          return style.body.backgroundColor || defaultColor;
        },
        set(value) {
          this.$set(this.value.object.styles.body, 'backgroundColor', value);
        },
      },

      // ------ WEATHER APP -------- //

      isWeatherTypeNow() {
        return this.value.object.weather?.type === WEATHER_DISPLAY_TYPE.NOW.value;
      },

      isWeatherApp() {
        return this.value.object.item_type === CONTENT_TYPE_WEATHER_APP;
      },

      isOldWeatherApp() {
        return (
          this.isWeatherApp &&
          !this.value.object.weather &&
          (this.value.object.visibility || this.value.object.background)
        );
      },

      weatherType: {
        get() {
          return this.value.object.weather?.type;
        },
        set(value) {
          this.$set(this.value.object.weather, 'type', value);
        },
      },

      weatherTextSize: {
        get() {
          return this.isWeatherTypeNow
            ? this.value.object.weather.simpleTextSizeSimple
            : this.value.object.weather.multipleTextSize;
        },
        set(value) {
          this.$set(
            this.value.object.weather,
            this.isWeatherTypeNow ? 'simpleTextSizeSimple' : 'multipleTextSize',
            restrictToBounds(Number(value), 8, 120),
          );
        },
      },

      weatherNumberSize: {
        get() {
          return this.isWeatherTypeNow
            ? this.value.object.weather.simpleNumberSize
            : this.value.object.weather.multipleNumberSize;
        },
        set(value) {
          this.$set(
            this.value.object.weather,
            this.isWeatherTypeNow ? 'simpleNumberSize' : 'multipleNumberSize',
            restrictToBounds(Number(value), 6, 120),
          );
        },
      },

      // ------ START - OLD WEATHER APP -------- //
      textSizeLeft1: {
        get() {
          return this.value.object.background.textSizeLeft1 || 14;
        },
        set(value) {
          this.$set(
            this.value.object.background,
            'textSizeLeft1',
            restrictToBounds(Number(value), 0, 120),
          );
        },
      },

      textSizeLeft2: {
        get() {
          return this.value.object.background.textSizeLeft2 || 30;
        },
        set(value) {
          this.$set(
            this.value.object.background,
            'textSizeLeft2',
            restrictToBounds(Number(value), 0, 120),
          );
        },
      },

      textSizeRight: {
        get() {
          return this.value.object.background.textSizeRight || 32;
        },
        set(value) {
          this.$set(
            this.value.object.background,
            'textSizeRight',
            restrictToBounds(Number(value), 0, 120),
          );
        },
      },

      lineWidth: {
        get() {
          return this.value.object.background.lineWidth || 1;
        },
        set(value) {
          this.$set(
            this.value.object.background,
            'lineWidth',
            restrictToBounds(Number(value), 0, 20),
          );
        },
      },

      lineColor: {
        get() {
          return this.value.object.background.lineColor || '#F0F0F0';
        },
        set(value) {
          this.$set(this.value.object.background, 'lineColor', value);
        },
      },

      logoSize: {
        get() {
          return this.value.object.background.logoSize || 60;
        },
        set(value) {
          this.$set(
            this.value.object.background,
            'logoSize',
            restrictToBounds(Number(value), 0, 500),
          );
        },
      },

      disableTimeSwitch() {
        return (
          this.isWidgetLockedByOverride ||
          (this.value.object.visibility.time &&
            !this.value.object.visibility.date &&
            !this.value.object.visibility.weather)
        );
      },

      disableDateSwitch() {
        return (
          this.isWidgetLockedByOverride ||
          (this.value.object.visibility.date &&
            !this.value.object.visibility.time &&
            !this.value.object.visibility.weather)
        );
      },

      disableWeatherSwitch() {
        return (
          this.isWidgetLockedByOverride ||
          (this.value.object.visibility.weather &&
            !this.value.object.visibility.date &&
            !this.value.object.visibility.time)
        );
      },
      // ------ END - OLD WEATHER APP -------- //

      // ------ RSS APP -------- //
      feedNameSize: {
        get() {
          return this.value.object.display?.feedNameSize || 20;
        },
        set(value) {
          this.$set(
            this.value.object.display,
            'feedNameSize',
            restrictToBounds(Number(value), 0, 200),
          );
        },
      },

      titleFontSize: {
        get() {
          return this.value.object.display?.titleFontSize || 30;
        },
        set(value) {
          this.$set(
            this.value.object.display,
            'titleFontSize',
            restrictToBounds(Number(value), 0, 200),
          );
        },
      },

      contentSize: {
        get() {
          return this.value.object.display?.contentSize || 20;
        },
        set(value) {
          this.$set(
            this.value.object.display,
            'contentSize',
            restrictToBounds(Number(value), 0, 200),
          );
        },
      },

      contentLength: {
        get() {
          return this.value.object.display?.contentLength || 200;
        },
        set(value) {
          this.$set(
            this.value.object.display,
            'contentLength',
            restrictToBounds(Number(value), 1, 100000),
          );
        },
      },

      rssSpacing: {
        get() {
          return this.value.object.display?.spacing || 16;
        },
        set(value) {
          this.$set(this.value.object.display, 'spacing', restrictToBounds(Number(value), 0, 200));
        },
      },

      rssPadding: {
        get() {
          return this.value.object.display?.padding || 16;
        },
        set(value) {
          this.$set(this.value.object.display, 'padding', restrictToBounds(Number(value), 0, 200));
        },
      },

      rssSlideDuration: {
        get() {
          return this.value.object.display?.duration || 16;
        },
        set(value) {
          this.$set(
            this.value.object.display,
            'duration',
            restrictToBounds(Number(value), 1, 3600),
          );
        },
      },
    },

    methods: {
      updateLocation(data) {
        this.$set(this.value.object.weather, 'location', data);
        this.showLocationPicker = false;

        if (!this.value.object.weather.name) {
          this.$set(this.value.object.weather, 'name', data.name);
        }
      },

      updateWeatherFont(newFont) {
        this.$set(this.value.object.weather, 'textFont', newFont);
      },

      updateViewerStyles(viewerStyles) {
        this.$set(this.value.object, 'viewerStyles', viewerStyles);
      },

      openContentBrowser() {
        this.$refs.content_browser_modal.open();
      },

      limitOpacity(value, key, defaultValue) {
        if (!value & (value !== 0)) {
          this.$set(this.value.object, key, defaultValue);
        }

        if (value > 100) {
          this.$set(this.value.object, key, 100);
        }

        if (value < 0) {
          this.$set(this.value.object, key, 0);
        }
      },

      async replaceApp(selectedItems) {
        if (selectedItems.length === 0) return;

        const item = selectedItems[0];
        const formatWeatherApp = item.item_type === CONTENT_TYPE_WEATHER_APP && !item.weather;
        let weatherData = {};

        if (item.item_type === CONTENT_TYPE_WEATHER_APP) {
          weatherData = {
            ...weatherDefaultOptions,
            name: item.name,
            backgroundColor: item.background.color,
            language: item.lang,
            temperatureUnit: item.temp_unit,
            timeFormat: item.time_format,
            location: {
              name: item.name,
              coordinates: item.location,
            },
          };
        }

        const newWidget = {
          ...this.value,
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position: this.value.position,
          object: {
            ...item,
            config: undefined,
            ...item.config,
            type: WIDGET_TYPES.APP,
            itemType: WIDGET_TYPES.APP,
            item_type: item.item_type,
            item_id: item.item_id,
            weather: formatWeatherApp ? weatherData : undefined,
            wid: this.value.object.wid,
          },
          itemType: WIDGET_TYPES.APP,
        };

        await this.$store.dispatch(TEMPLATE_REPLACE_CHILD_ITEM, {
          widget: newWidget,
          newItemId: this.inOverrideMode ? this.value.object.item_id : item.item_id,
        });
      },

      closeModal() {
        this.$refs.content_browser_modal.close();
      },

      toggleColorPicker(colorKey) {
        if (this.isWidgetLockedByOverride) return;

        this.showColorPicker = !this.showColorPicker;
        this.pickerColorKey = colorKey;
      },

      updateColor(color) {
        if (!this.pickerColorKey) return;

        const useHex8 = this.pickerColorKey === 'backgroundColor';

        this.$set(this.value.object.weather, this.pickerColorKey, useHex8 ? color.hex8 : color.hex);
      },

      closeColorPicker() {
        if (!this.pickerColorKey || !this.showColorPicker) return;

        this.showColorPicker = false;
        this.pickerColorKey = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .picker {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 20;
  }

  .layer-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;

    .button-2-secondary {
      margin: 16px 0;
      width: 100%;

      &.disabled {
        background-color: $backgroundGrey2;
      }
    }
  }

  .measurements-container {
    padding-top: 6px;
  }

  .sub-section-title {
    margin-top: 8px;
  }

  .alignment {
    margin-top: 6px;
  }

  .alignments {
    display: flex;
    gap: 8px;

    .alignment-mode {
      width: 26px;
      margin: 0 2px;

      input:checked + label {
        color: $primaryText;
        background: #f5f5f5;
        padding: 16px;
      }

      input + label {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        color: $secondaryText;
      }

      .alignment-label {
        cursor: pointer;
      }
    }
  }

  .form-group {
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0;
    }
  }

  .content-section {
    padding-top: 6px;

    .section-title {
      font-weight: bold;
      padding-top: 16px;
      font-size: 16px;
      border-top: 1px solid $lineGrey;
      margin-bottom: 10px;
    }
  }

  .font-color-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 12px;
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0;
      width: 100%;
    }

    .color-section {
      display: flex;
      position: relative;
      align-items: flex-end;
      gap: 10px;
      min-width: 140px;
    }
  }

  .color-button {
    background-color: $lineGrey;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 40px;
    padding: 8px 6px 6px 6px;
    width: 40px;

    i {
      color: $tableText;
      font-size: 18px;
      height: 18px;
      width: 18px;
    }

    img {
      margin-top: -1px;
      height: 20px;
      width: 20px;
    }

    .color-bar {
      border-radius: 5px;
      height: 4px;
      width: 100%;
      background-color: black;
    }
  }

  label {
    margin-bottom: 4px;
  }

  .location-container {
    margin-top: 16px;
    width: 100%;
  }

  .remove-button {
    width: 100%;
    text-align: right;

    &:hover {
      cursor: pointer;
      color: $primaryRed;
    }
  }

  .location-text {
    display: flex;
    margin-bottom: 8px;
  }
</style>
