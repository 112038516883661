<template>
  <div class="additional-info-container">
    <div
      v-if="playlistItem && playlistItem.display_priority === DISPLAY_PRIORITY.PRIORITY"
      class="priority-indicator"
    >
      PRIO
    </div>
    <div
      class="schedule-indicator"
      v-if="
        playlistItem &&
        playlistItem.scheduler &&
        playlistItem.scheduler.available !== SCHEDULE_TYPES.ALWAYS
      "
      title="Scheduled"
    ></div>
  </div>
</template>

<script>
  import { SCHEDULE_TYPES, DISPLAY_PRIORITY } from '@/constant/const';
  export default {
    props: { playlistItem: { type: Object, default: () => null } },
    data() {
      return { SCHEDULE_TYPES, DISPLAY_PRIORITY };
    },
  };
</script>

<style scoped lang="scss">
  .additional-info-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 2px;
    .priority-indicator {
      padding-top: 3px;
      width: 40px;
      height: 21px;
      background-color: $primaryRed;
      color: #ffffff;
      font-weight: 700;
      font-size: 10px;
      border-radius: 10.5px;
      text-align: center;
    }
    .schedule-indicator {
      background-image: url('@/assets/img/pending.svg');
      width: 20px;
      height: 20px;
    }
  }
</style>
