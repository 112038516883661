<template>
  <div class="toggle-switch" @click="toggleAndEmit">
    <span v-if="value" class="switchButton material-symbols-outlined on"> toggle_on </span>
    <span v-else class="switchButton material-icons-outlined"> toggle_off </span>
    <span class="toggle-text">{{ text }}</span>
  </div>
</template>

<script>
  export default {
    name: 'ToggleSwitch',

    emits: ['input'],

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: '',
      },
    },

    methods: {
      toggleAndEmit() {
        this.$emit('input', !this.value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .toggle-switch {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    .toggle-text {
      font-size: 14px;
      line-height: 20px;
      color: $primaryText;
    }
  }

  .switchButton {
    user-select: none;
    &.material-icons-outlined {
      font-size: 24px;
      color: #6a6b6a;
    }

    &.material-symbols-outlined {
      font-size: 24px;
    }

    &.on {
      color: #e3003a;
    }
  }
</style>
