<template>
  <div class="row mx-0 w-100">
    <div class="col-8 px-0">
      <div class="carousel carousel-fade slide" data-ride="carousel" id="login-carousel">
        <div class="carousel-inner">
          <div class="carousel-item active" ref="carousel-1">
            <div class="py-4 bg-yellow-grad vh-100">
              <div class="px-5">
                <img :src="darkLogo" class="w-165" alt="Picflow logo" />
              </div>
              <div class="pb-5 d-flex align-items-center" style="padding-top: 5rem">
                <div>
                  <img :src="crbg1" alt="Carousel image" />
                </div>
                <div style="margin-left: -3.5rem">
                  <h3 class="cr-title mb-4">Digitala infodisplayer till fastigheter</h3>
                  <p class="font-whyte-ink f-xl">&rarr; En komplett kommunikationslösning</p>
                  <p class="font-whyte-ink f-xl">&rarr; Snabb och enkel informationsspidning</p>
                  <p class="font-whyte-ink f-xl">&rarr; Styr all kommunikation i ett system</p>
                  <p class="font-whyte-ink f-xl">&rarr; Integrera med ert fastighetssytem</p>
                  <p class="font-whyte-ink f-xl">&rarr; Närmare relation med hyresgästerna</p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" ref="carousel-2">
            <div class="py-4 px-5 bg-cr-2 vh-100">
              <img class="mb-4 w-165" :src="lightLogo" alt="Picflow logo" />
              <div class="py-5">
                <h3 class="cr-title mb-5 t-yellow">Låt fastigheten leva.</h3>
                <p class="font-whyte-ink f-2xl t-yellow">
                  Allt vi gör, gör vi för att göra det enkelt för dig att skapa en närmare relation
                  med dina hyresgäster. Med Picler kan du ge alla hyresgäster den information de
                  behöver, när de behöver den. Digitalt och direkt till fastighetens digitala
                  infodisplay.
                </p>
              </div>
              <div style="margin-top: 8rem">
                <p class="t-yellow f-xl">Magiskt smart plattform.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-controls">
          <a
            class="mr-3 text-decoration-none"
            href="#login-carousel"
            role="button"
            data-slide="prev"
          >
            <span class="f-2xl font-whyte-ink" aria-hidden="true">&larr;</span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="text-decoration-none" href="#login-carousel" role="button" data-slide="next">
            <span class="f-2xl font-whyte-ink" aria-hidden="true">&rarr;</span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
    <div class="col-4 px-0 align-items-center login-forms font-poppins" id="login">
      <div v-if="!playerSite" class="forms-wrapper">
        <div class="mb-4">
          <h1 class="mb-2 f-xl font-whyte-ink">
            <div
              v-if="formType === LOGIN_FORMS.FORGOT_PASSWORD"
              class="back-button"
              @click="formType = LOGIN_FORMS.LOGIN"
            >
              <i class="material-icons-outlined align-middle"> arrow_back </i>
            </div>
            Welcome to PicFlow, <br />
            {{ formType === LOGIN_FORMS.LOGIN ? 'Sign In to Continue' : '' }}
          </h1>
          <p class="mb-0 font-gray size-1">Please enter your details.</p>
        </div>

        <template v-if="formType === LOGIN_FORMS.LOGIN">
          <form id="login-form" @submit.prevent="login">
            <div class="form-label-group">
              <label for="inputEmail">Email</label>
              <input
                v-model="username"
                type="email"
                id="inputEmail"
                class="form-control"
                placeholder="email@domain.com"
                required
                autofocus
              />
            </div>

            <div class="form-label-group">
              <label for="inputPassword">Password</label>
              <div class="input-group" ref="pass">
                <input
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  id="inputPassword"
                  class="form-control border-right-0"
                  placeholder="Enter your password"
                  required
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text bg-white cursor-pointer py-1"
                    @click="showPassword = !showPassword"
                  >
                    <span class="icon material-icons-outlined align-middle">{{
                      showPassword ? 'visibility_off' : 'visibility'
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
            <p>
              <a
                class="mb-3 link font-gray size-14p"
                @click="formType = LOGIN_FORMS.FORGOT_PASSWORD"
                >Forgot Password?</a
              >
            </p>
            <button
              class="btn btn-lg btn-danger btn-block bg-primary-red rounded-xl login-button"
              type="submit"
              :disabled="isLoginInProgress"
            >
              <div class="spin-animation" v-if="isLoginInProgress">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#e8eaed"
                >
                  <path
                    d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"
                  />
                </svg>
              </div>

              {{ LOGIN_FORM_BUTTON_LABELS.LOGIN }}
            </button>
          </form>
        </template>

        <template v-else-if="formType === LOGIN_FORMS.FORGOT_PASSWORD">
          <form id="forgot-password-form" @submit.prevent="forgotPassword">
            <div class="form-label-group">
              <label for="inputEmail">Email</label>
              <input
                v-model="username"
                type="email"
                id="inputEmail"
                class="form-control"
                placeholder="email@domain.com"
                required
                autofocus
              />
            </div>
            <button
              class="btn btn-lg btn-danger btn-block bg-primary-red rounded-xl"
              type="submit"
              :disabled="isLoading"
            >
              <i class="material-icons align-middle spin-animation" v-if="isLoading">refresh</i>
              {{ LOGIN_FORM_BUTTON_LABELS.SEND_EMAIL }}
            </button>
          </form>
        </template>

        <template v-else>
          <form id="reset-password-form" @submit.prevent="resetPassword">
            <div class="form-label-group">
              <label for="inputPassword">{{ isNewPassword ? 'Create' : 'New' }} Password</label>
              <div class="input-group" ref="pass">
                <input
                  v-model="newPassword"
                  :type="showPassword ? 'text' : 'password'"
                  id="inputPassword"
                  class="form-control border-right-0"
                  :placeholder="`${isNewPassword ? 'Create New' : 'Enter New'} Password`"
                  required
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text bg-white cursor-pointer py-1"
                    @click="showPassword = !showPassword"
                  >
                    <span class="icon material-icons-outlined align-middle">{{
                      showPassword ? 'visibility_off' : 'visibility'
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
            <button
              class="btn btn-lg btn-danger btn-block bg-primary-red rounded-xl"
              type="submit"
              :disabled="isLoading"
            >
              <i class="material-icons align-middle spin-animation" v-if="isLoading">refresh</i>
              {{ isNewPassword ? 'Create' : 'Reset' }} Password
            </button>
          </form>
        </template>

        <p class="text-muted">
          <small>
            Request account on
            <a href="http://picler.se" title="Picler.se" target="_blank">picler.se</a>.
          </small>
        </p>
      </div>

      <alert
        v-else
        class="alert-info"
        message="This is not the site you're looking for, please go to https://app.cleversign.se"
      ></alert>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { AUTH_REQUEST } from '../store/actions/auth';
  import config from '../config';
  import Alert from './Alert.vue';
  import darkLogo from '@/assets/img/login/picflow-logo-login-carousel-dark.svg';
  import lightLogo from '@/assets/img/login/picflow-logo-login-carousel-light.svg';
  import crbg1 from '@/assets/img/login/login-carousel-bg.png';
  import { LOGIN_FORMS, LOGIN_FORM_BUTTON_LABELS } from '@/constant/const';
  import { apiForgotPassword, apiResetPassword } from '@/api/auth';

  export default {
    name: 'Login',

    components: {
      Alert,
    },

    data() {
      return {
        username: '',
        password: '',
        errorMessage: '',
        darkLogo,
        lightLogo,
        crbg1,
        showPassword: false,
        formType: LOGIN_FORMS.LOGIN,
        LOGIN_FORMS,
        newPassword: '',
        token: '',
        isLoading: false,
        LOGIN_FORM_BUTTON_LABELS,
      };
    },

    mounted() {
      const setControlColor = () => {
        if (this.$refs['carousel-1']?.classList?.contains('active')) {
          $('.carousel-controls a').css({ color: '#333332' });
        } else {
          $('.carousel-controls a').css({ color: '#fff7bc' });
        }
      };
      setControlColor();
      $('#login-carousel').on('slid.bs.carousel', setControlColor);
      this.token = this.$route.query.token;
      if (this.token) {
        this.formType = LOGIN_FORMS.RESET_PASSWORD;
      }
    },

    methods: {
      login() {
        this.errorMessage = '';
        const { username, password } = this;
        this.$store
          .dispatch(AUTH_REQUEST, { username, password })
          .then((resp) => {
            this.$toasted.global.general_success({
              message: 'Login in...',
            });
            window.location.href = '/';
          })
          .catch((error) => {
            if (error.response) {
              if (error.response?.status === 404) {
                this.$toasted.global.general_error({
                  message: 'Invalid credentials',
                });
                return;
              }
              this.$toasted.global.general_error({
                message: error.response.data.message,
              });
            } else {
              this.$toasted.global.general_error({
                message: String(error),
              });
              //this.errorMessage = String(error)
            }
          });
      },

      async forgotPassword() {
        try {
          this.isLoading = true;
          await apiForgotPassword(this.username, window.location.href);
          this.$toasted.global.general_success({
            message: 'Please check your email for the link to reset password.',
          });
        } catch (err) {
          this.$toasted.global.general_error({
            message: err?.response?.data?.message ?? String(err),
          });
        } finally {
          this.isLoading = false;
        }
      },

      async resetPassword() {
        try {
          this.isLoading = true;
          await apiResetPassword(this.token, this.newPassword);
          this.$toasted.global.general_success({
            message: 'Password reset successful. Please use the newly created password to login',
          });
          this.formType = LOGIN_FORMS.LOGIN;
          this.$router.replace({ query: null });
        } catch (err) {
          this.$toasted.global.general_error({
            message: err?.response?.data?.message ?? String(err),
          });
        } finally {
          this.isLoading = false;
        }
      },
    },

    computed: {
      ...mapState({
        loginStatus: (state) => state.auth.status,
        loginError: (state) => state.auth.error,
      }),

      isLoginInProgress() {
        return this.loginStatus === 'loading';
      },

      playerSite() {
        return config.playerSite;
      },
      isNewPassword() {
        return this.$route?.query?.type === 'new';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bg-yellow-grad {
    background-color: #feef93;
    background-image: linear-gradient(to right, #fff7bc, #feef93);
  }
  .cr-title {
    font-size: 40px;
    font-family: 'Whyte Inktrap';
  }
  .f-xl {
    font-size: 1.5rem;
  }
  .f-2xl {
    font-size: 2rem;
  }
  .t-yellow {
    color: #fef3ab;
  }
  .bg-cr-2 {
    background: url('@/assets/img/login/login-carousel-bg2.jpg') left top;
    background-size: cover;
  }
  .carousel-controls {
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    z-index: 1;
  }
  .login-forms {
    display: flex;
    width: 100%;
    min-height: 100vh;
    //padding-top: 100px;
    background-color: #fff;
    .font-whyte-ink {
      color: #151515;
    }
    .font-gray {
      color: #6a6b6a;
    }
    .size-1 {
      font-size: 1rem;
    }
    .size-14p {
      font-size: 14px;
    }

    .logo {
      width: 250px;
      margin-bottom: 12px;
    }
  }

  .forms-wrapper {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: 0 auto;

    .btn {
      margin-top: 40px;
    }
  }

  .form-label-group {
    position: relative;
    margin-bottom: 0.75rem;
  }

  .form-label-group > label {
    display: block;
    width: 100%;
    margin-bottom: 0.25rem; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #333332;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .form-label-group ~ label {
    font-size: 14px;
    color: #333332;
  }

  .link {
    cursor: pointer;
  }

  img.cleversign-logo {
    width: 220px;
  }

  .form-control[type='email'],
  .form-control[type='text'],
  .form-control[type='password'] {
    font-size: 16px;
    color: #151515;
    font-family: Poppins;
  }

  @media (min-width: 576px) {
    img.cleversign-logo {
      width: 250px;
    }
  }

  @media (min-width: 768px) {
    img.cleversign-logo {
      width: 350px;
    }
  }
  .back-button {
    display: inline-block;
    &:hover {
      cursor: pointer;

      i {
        color: $primaryRed;
      }
    }
  }
  .login-button {
    display: flex;
    gap: 8px;
    justify-content: center;
  }
</style>
