<template>
  <SimpleModal
    title="Add Screen"
    ref="createScreenModal"
    :show-close-icon="creationStep !== 'layout'"
    :is-loading="loading"
    :clickOutsideClose="creationStep !== 'layout'"
    @close="$emit('close')"
    :model-content-style="{
      width: '862px',
    }"
    class="add-screen-modal"
  >
    <!-- Layout step -->
    <template v-if="creationStep === 'layout'">
      <div class="screen-code-generated">
        <BaseSelect id="screenLayout" label="Select the screen's Layout" v-model="screenLayout">
          <option value="null">None</option>
          <option
            v-for="layout in layouts"
            :key="layout.layout_id"
            :value="layout.layout_id"
            :selected="layout.layout_id === screenLayout"
          >
            {{ layout.settings.name }}
          </option>
        </BaseSelect>
      </div>
    </template>

    <!-- Screen code step -->
    <template v-else-if="creationStep === 'code'">
      <div class="screen-code-generated">
        <BaseText class="screen-code-content-text">
          <img class="icon" src="@/assets/icon/warning.svg" alt="Warning" />
          {{ SCREEN_CODE_USE_MESSAGE }}
        </BaseText>
        <ScreenCodeTabs
          @tab:tabChange="handleTabChange"
          ref="screenCodeTabs"
          :screenId="screen.id"
        />
      </div>
    </template>

    <!-- First step  -->
    <template v-else>
      <div class="screen-add-content">
        <div class="row mx-n2">
          <div class="col px-2">
            <BaseInput
              label="Name"
              type="text"
              v-model="screenName.value"
              placeholder="New Screen"
            />
          </div>
        </div>

        <BaseInput label="Group">
          <multiselect
            :options="groupOptions"
            track-by="name"
            label="name"
            v-model="screenGroup"
            placeholder="Select a group"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="option" slot-scope="{ option }">
              <div>{{ option.name }}</div>
            </template>
            <template slot="noResult">
              <span>No groups found</span>
            </template>
            <template slot="noOptions">
              <span>No groups found</span>
            </template>
          </multiselect>
        </BaseInput>

        <BaseInput label="License">
          <multiselect
            :options="licensesOptions"
            track-by="name"
            label="name"
            v-model="screenLicense.value"
            placeholder="Select a License"
            :searchable="true"
            :allow-empty="false"
            open-direction="bottom"
            :class="{ 'is-invalid': !!screenLicense.error }"
          >
            <template slot="option" slot-scope="{ option }">
              <div>{{ option.name }}</div>
            </template>
            <template slot="noResult">
              <span>No licenses found</span>
            </template>
            <template slot="noOptions">
              <span>No licenses found</span>
            </template>
          </multiselect>
        </BaseInput>
      </div>
    </template>

    <template v-if="!loading" #footer>
      <div class="footer">
        <div class="screen-step">
          <div class="complete"></div>
          <div :class="{ complete: creationStep === 'layout' || creationStep === 'code' }"></div>
          <div :class="{ complete: creationStep === 'code' }"></div>
        </div>
        <!-- Layout step -->
        <template v-if="creationStep === 'layout'">
          <BaseButton size="sm" theme="secondary" class="mr-3" @click="skipLayout()">
            Skip
          </BaseButton>
          <BaseButton size="sm" @click="saveLayoutOnScreen()" :loading="saving">Next</BaseButton>
        </template>

        <!-- Screen code step -->
        <template v-else-if="creationStep === 'code'">
          <BaseButton size="sm" theme="secondary" class="mr-3" @click="$emit('close')">
            Close
          </BaseButton>
          <BaseButton
            size="sm"
            @click="handleCodeAction()"
            :loading="saving"
            :disabled="!isActionButtonActive"
            >{{ codeActionButtonLabel }}</BaseButton
          >
        </template>

        <!-- First step  -->
        <template v-else>
          <BaseButton size="sm" theme="secondary" class="mr-3" @click="$emit('close')">
            Cancel
          </BaseButton>
          <BaseButton size="sm" @click="handleAdd()" :loading="saving">Next</BaseButton>
        </template>
      </div>
    </template>
  </SimpleModal>
</template>

<script>
  import { Multiselect } from 'vue-multiselect';
  import { mapState } from 'vuex';
  import { OnClickOutside } from '@vueuse/components';

  import SimpleModal from '@/components/common/SimpleModal.vue';
  import BaseText from '@/components/common/ui/atoms/baseText/BaseText.vue';

  import { SCREENS_ADD, SCREEN_ADD_LAYOUT, SCREEN_GET_ALL_LICENSES } from '@/store/actions/screens';
  import { apiReadLayouts } from '@/api/layouts';
  import {
    SCREEN_CODE_USE_MESSAGE,
    GENERATE_CODE_LABEL,
    CONNECT_SCREEN_LABEL,
  } from '@/constant/screens';
  import ScreenCodeTabs from '@/components/screens/ScreenCodeTabs';

  const $ = window.jQuery;

  export default {
    inject: ['getGroups'],

    emits: ['screenAdded', 'close'],

    components: {
      Multiselect,
      SimpleModal,
      BaseText,
      OnClickOutside,
      ScreenCodeTabs,
    },

    props: {
      screenGroupName: {
        type: String,
        default: null,
      },
      screenGroupId: {
        type: String | Number,
        default: null,
      },
      isOpen: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        loading: false,
        licenses: [],

        creationStep: 'first',
        screenName: { value: '', error: null },
        screenGroup:
          this.screenGroupId && this.screenGroupName
            ? { name: this.screenGroupName, value: this.screenGroupId }
            : '',
        screenLicense: { value: '', error: null },
        saving: false,

        screenCodeGenerated: null,
        screenLayout: null,
        screen: null,
        layouts: [],
        SCREEN_CODE_USE_MESSAGE,
        codeActionButtonLabel: GENERATE_CODE_LABEL,
        isGenerateTab: true,
        isActionButtonActive: true,
      };
    },

    mounted() {
      this.fetchData();

      if (this.isOpen) {
        this.open();
      }
    },

    computed: {
      ...mapState({
        screenGroups: (state) => state.screens.screenGroups,
        screenGroupsStatus: (state) => state.screens.status,
        organisationId: (state) => state.auth.organisation,
      }),

      groups() {
        return this.getGroups();
      },

      groupOptions() {
        return this.groups
          .filter((group) => !group.locked)
          .map((group) => {
            return {
              name: group.name,
              value: group.id,
            };
          });
      },

      licensesOptions() {
        return this.licenses
          .filter((license) => license.screen === null)
          .map((license) => ({
            name: license.name,
            value: license.id,
          }));
      },
    },

    methods: {
      async fetchData() {
        this.loading = true;

        try {
          await Promise.all([this.fetchLicenses(), this.fetchLayouts()]);
        } catch (error) {
          this.$toasted.global.general_error({
            message: `Failed to get data for Add Screen modal`,
          });
          console.error('Failed to get data for Add Screen modal:', error);
        } finally {
          this.loading = false;
        }
      },

      async fetchLicenses() {
        let res = await this.$store.dispatch(SCREEN_GET_ALL_LICENSES, this.organisationId);
        this.licenses = res;
      },

      async fetchLayouts() {
        const response = await apiReadLayouts();
        this.layouts = response.data.items;
      },

      async handleAdd() {
        this.saving = true;

        try {
          const screen = await this.$store.dispatch(SCREENS_ADD, {
            screen_name: this.screenName.value,
            screen_group_id: this.screenGroup.value,
            license_id: this.screenLicense.value.value,
          });

          this.screen = screen;

          this.$toasted.global.general_success({
            message: 'Screen added successfully',
          });

          this.setCreationStep('layout');
        } catch (e) {
          this.$toasted.global.general_error({
            message: `Error adding screen ${e?.message !== null ? `(${e.message})` : ''}`,
          });
          console.error('Error adding screen:', e);
        } finally {
          this.saving = false;
        }
      },

      async saveLayoutOnScreen() {
        if (this.screenLayout === null) {
          this.setCreationStep('code');
          return;
        }

        this.isSaving = true;

        try {
          await this.$store.dispatch(SCREEN_ADD_LAYOUT, {
            screenId: this.screen.id,
            layoutId: this.screenLayout,
          });

          this.setCreationStep('code');

          this.$toasted.global.general_success({
            message: `Screen layout updated`,
          });
        } catch (error) {
          this.$toasted.global.general_error({ message: 'Unable to save layout' });
          console.error('Error saving layout to screen:', error);
        } finally {
          this.isSaving = false;
        }
      },

      open() {
        this.$refs.createScreenModal.open();
      },

      close() {
        this.$emit('close');
        this.$refs.createScreenModal.close();
      },

      setCreationStep(value) {
        if ((value === 'first') | (value === 'layout') | (value === 'code')) {
          this.creationStep = value;
        }

        if (value === 'code') this.$emit('screenAdded');
      },

      skipLayout() {
        this.screenLayout = null;
        this.setCreationStep('code');
      },

      handleTabChange(isGenerateTab) {
        this.isGenerateTab = isGenerateTab;
        isGenerateTab
          ? (this.codeActionButtonLabel = GENERATE_CODE_LABEL)
          : (this.codeActionButtonLabel = CONNECT_SCREEN_LABEL);
      },

      async handleCodeAction() {
        const isSuccessful = await this.$refs.screenCodeTabs.actionClicked();
        if (isSuccessful) {
          this.isActionButtonActive = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .add-screen-modal {
    ::v-deep .ui-modal-footer {
      margin-top: auto !important;
      display: block !important;
    }

    ::v-deep .ui-button {
      padding: 10px 12px !important;
    }

    .footer {
      display: flex;
      flex-direction: row;
      .screen-step {
        margin: auto auto auto 15rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        div {
          height: 0.25rem;
          border-radius: 2rem;
          width: 100px;
          background-color: #f0f0f0;
        }
        .complete {
          background-color: $primaryRed;
        }
      }
    }
  }
  ::v-deep {
    .multiselect {
      &__select {
        padding: 0;
        z-index: 501;
      }
      &__tags {
        border: 2px solid #e8e8e8;
        border-radius: 8px;
        font-size: 16px;
        padding: 6px 16px;
        .multiselect__input {
          padding: 0;
          margin: 0;
          font-size: 16px;
          padding-right: 26px;
          box-sizing: border-box;
          z-index: 500;
          border-radius: 0;
          height: 24px;
          &::placeholder {
            color: #95a6a6;
          }
        }
        .multiselect__placeholder {
          margin: 0;
          padding: 0;
          color: #95a6a6;
        }
      }

      &__content-wrapper {
        border-width: 2px;

        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &--above {
        .multiselect__content-wrapper {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &__single {
        margin: 0;
        padding: 0;
        line-height: 24px;
      }
    }
  }
  .switch-lg {
    font-size: 30px !important;
  }

  .label {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .screen-add-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
</style>
