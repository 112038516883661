<template>
  <div
    class="template-card"
    :class="{ ...(template.class ? template.class : {}) }"
    @click="$emit('templateSelected')"
  >
    <div class="header" :class="{ selected }">
      <img
        :src="
          template.thumbnail_image_url ? get_full_url(template.thumbnail_image_url) : template.image
        "
        class="template-img"
        :alt="template.name"
        :style="{
          width: '100%',
        }"
        @error="handleThumbanilImageError"
      />
    </div>

    <slot v-if="showFloatingMessage" name="message" />

    <div class="body">
      <div class="name text-truncate">{{ template.name }}</div>
      <div v-if="showDetails" class="size">
        {{ `${template.settings.width} x ${template.settings.height}` }}
      </div>
    </div>
  </div>
</template>

<script>
  import { getCloudfrontUrl } from '@/helpers/utils';
  export default {
    name: 'TemplateCard',

    props: {
      template: {
        type: Object,
        default: () => null,
      },
      selected: {
        type: Boolean,
        default: false,
      },
      showDetails: {
        type: Boolean,
        default: false,
      },
      showFloatingMessage: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        imageErrorHandled: false,
      };
    },
    methods: {
      get_full_url(url) {
        return getCloudfrontUrl(url);
      },
      handleThumbanilImageError(event) {
        if (!this.imageErrorHandled) {
          event.target.src = this.template.settings.image;
        }
        this.imageErrorHandled = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .template-card {
    display: flex;
    flex-direction: column;
    position: relative;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    width: 172px;
    border: 2px solid transparent;
    height: 290px;

    word-wrap: break-word;

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 232px;
      margin-bottom: 0;
      background-color: $backgroundGrey4;
      padding: 8px;

      img {
        object-fit: contain;
      }
      &:hover,
      &.selected {
        cursor: pointer;
        border: 2px solid $primaryRed !important;
      }
    }

    .template-img {
      height: 100%;
    }

    .body {
      padding-top: 8px;
      min-height: 54px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .name {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
      }
      .size {
        font-size: 14px;
        line-height: 21px;
        font-weight: 700;
      }
    }
  }
</style>
