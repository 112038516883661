<template>
  <div
    class="layout-card"
    :class="{ selected, ...(layout.class ? layout.class : {}) }"
    @click="$emit('layoutSelected')"
  >
    <div class="header">
      <img
        :src="layout.image"
        class="layout-img"
        :alt="layout.name"
        :style="{
          aspectRatio: '16/9',
          width: '100%',
        }"
      />
    </div>

    <slot v-if="showFloatingMessage" name="message" />

    <div class="body">
      <div class="card-title text-truncate">{{ layout.name }}</div>
      <div v-if="showDetails" class="card-details">
        {{ `${layout.settings.width} x ${layout.settings.height}` }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LayoutCard',

    props: {
      layout: {
        type: Object,
        default: () => null,
      },
      selected: {
        type: Boolean,
        default: false,
      },
      showDetails: {
        type: Boolean,
        default: false,
      },
      showFloatingMessage: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-card {
    display: flex;
    flex-direction: column;
    position: relative;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    background-color: #fff;
    padding: 4px;
    width: 172px;
    border: 2px solid transparent;

    word-wrap: break-word;

    &:hover,
    &.selected {
      cursor: pointer;
      border: 2px solid $primaryRed !important;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 164px;
      margin-bottom: 0;

      img {
        object-fit: contain;
      }
    }

    .layout-img {
      height: 100%;
    }

    .body {
      padding-top: 4px;
      border-top: 1px solid $lineGrey;
      min-height: 54px;
      margin: 0 4px;
    }

    &.new-card img {
      width: 60px;
      height: 60px;
      filter: invert(0.9);
    }

    .card-details {
      color: $secondaryText;
      margin-top: -12px;
      font-weight: normal;
      font-size: 13px;
    }
  }
</style>
