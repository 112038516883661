import { render, staticRenderFns } from "./ScreenCard.vue?vue&type=template&id=63eed0aa&scoped=true"
import script from "./ScreenCard.vue?vue&type=script&lang=js"
export * from "./ScreenCard.vue?vue&type=script&lang=js"
import style0 from "./ScreenCard.vue?vue&type=style&index=0&id=63eed0aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63eed0aa",
  null
  
)

export default component.exports