<script lang="ts">
  export default { name: 'FileUploadModal' };
</script>

<script setup lang="ts">
  import { defineEmits, defineProps, onMounted, onUnmounted, ref, toRefs, withDefaults } from 'vue';
  import Modal from '@/components/content/Modal.vue';
  import FileDropZone from '@/components/common/fileUploadModal/FileDropZone.vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import EmptyState from '@ui/emptyState/EmptyState.vue';
  import {
    ALLOWED_MAX_FILE_NAME_LENGTH,
    ALLOWED_MAX_FILE_SIZE_IN_MB,
    ALLOWED_MAX_IMAGE_SIZE_IN_MB,
  } from '@/constant/const';
  import {
    FileUploadOption,
    FileUploadStatus,
    Folder,
    TemporaryPDFPlaylistItem,
    TemporaryPlaylistItem,
  } from '@/components/common/fileUploadModal/types';
  import FileUploadRow from '@/components/common/fileUploadModal/FileUploadRow.vue';
  import { apiGetFileUploadStatus, apiUploadFile, apiUploadPDF } from '@/api/content';
  import { pdfToFiles } from '@/helpers/pdf';
  import { useToasted } from '@/helpers';
  import { sleep } from '@/helpers/async';

  const props = withDefaults(
    defineProps<{
      folders: Folder[];
      selectedFolder: Folder | null;
      allowMultiple?: boolean;
      awaitProcessing?: boolean;
    }>(),
    { allowMultiple: true, awaitProcessing: true },
  );
  const { folders, selectedFolder } = toRefs(props);

  const emit = defineEmits<{
    (
      event: 'close',
      data: {
        shouldRehydrate: boolean;
        playlistItems: Array<TemporaryPlaylistItem | TemporaryPDFPlaylistItem>;
      },
    ): void;
    (
      event: 'success',
      data: {
        shouldRehydrate: boolean;
        playlistItems: Array<TemporaryPlaylistItem | TemporaryPDFPlaylistItem>;
      },
    ): void;
  }>();

  const toasted = useToasted();

  const modalRef = ref(null);

  const selectedFolderId = ref<string>(
    selectedFolder.value === null ? 'null' : selectedFolder.value.id.toString(),
  );

  const isMounted = ref(true);

  const fileToUploadOptions = ref(new Array<FileUploadOption>());
  const temporaryPlaylistItems = ref(new Array<TemporaryPlaylistItem | TemporaryPDFPlaylistItem>());
  const isUploading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const shouldRehydrate = ref(false);

  onMounted(() => {
    if (modalRef.value) {
      modalRef.value.open();
      isMounted.value = true;
    }
  });

  onUnmounted(() => {
    isMounted.value = false;
  });

  function handleFilesSelect(data: { selectedFiles: File[] }) {
    for (const file of data.selectedFiles) {
      const exists = fileToUploadOptions.value.some(
        (fileUploadOption) => fileUploadOption.file.name === file.name,
      );

      if (file.name.length > ALLOWED_MAX_FILE_NAME_LENGTH) {
        toasted.global.general_error({
          message: `File name cannot be longer than ${ALLOWED_MAX_FILE_NAME_LENGTH} characters`,
        });

        continue;
      }

      const fileSizeInMB = file.size / 1024 ** 2;
      if (
        (file.type === 'image/png' || file.type === 'image/jpeg') &&
        fileSizeInMB > ALLOWED_MAX_IMAGE_SIZE_IN_MB
      ) {
        toasted.global.general_error({
          message: `Images cannot be larger than ${ALLOWED_MAX_IMAGE_SIZE_IN_MB} MB`,
        });

        continue;
      } else if (file.type === 'video/mp4' && fileSizeInMB > ALLOWED_MAX_FILE_SIZE_IN_MB) {
        toasted.global.general_error({
          message: `Videos cannot be larger than ${ALLOWED_MAX_FILE_SIZE_IN_MB} MB`,
        });

        continue;
      } else if (file.type === 'audio/mpeg' && fileSizeInMB > ALLOWED_MAX_FILE_SIZE_IN_MB) {
        toasted.global.general_error({
          message: `Audio files cannot be larger than ${ALLOWED_MAX_FILE_SIZE_IN_MB} MB`,
        });

        continue;
      }

      if (!exists) {
        if (props.allowMultiple) {
          fileToUploadOptions.value.push({
            file: file,
            status: null,
            percent: 0,
          } as FileUploadOption);
        } else {
          fileToUploadOptions.value = [
            { file: file, status: null, percent: 0 } as FileUploadOption,
          ];
        }
      }
    }
  }

  function handleFileRemove(name: string) {
    const deleteIndex = fileToUploadOptions.value.findIndex(
      (fileUploadOption) => fileUploadOption.file.name === name,
    );

    if (deleteIndex > -1) {
      // the backend don't support aborting a file upload once the process has started
      const fileUploadOption = fileToUploadOptions.value[deleteIndex];
      if (
        fileUploadOption.status === FileUploadStatus.Uploading ||
        fileUploadOption.status === FileUploadStatus.PendingProcess ||
        fileUploadOption.status === FileUploadStatus.Processing
      ) {
        toasted.global.general_warning({
          message:
            'This file is currently being uploaded or processed. Please wait for the upload to finish before removing it.',
        });
        return;
      }

      fileToUploadOptions.value.splice(deleteIndex, 1);
    }
  }

  async function handleMediaFileUpload() {
    isUploading.value = true;
    isSuccess.value = false;
    isError.value = false;
    shouldRehydrate.value = false;

    for (const fileUploadOption of fileToUploadOptions.value) {
      if (fileUploadOption.status == null || fileUploadOption.status === FileUploadStatus.Failed) {
        var updatedFileUploadOption: FileUploadOption = {
          ...fileUploadOption,
          status: FileUploadStatus.Uploading,
        };
        setFileToUploadOption(updatedFileUploadOption);

        try {
          const folderId =
            selectedFolderId.value === 'null' ? null : parseInt(selectedFolderId.value);

          if (updatedFileUploadOption.file.type === 'application/pdf') {
            updatedFileUploadOption = await processAndUploadPdf(updatedFileUploadOption, folderId);
          } else {
            updatedFileUploadOption = await uploadFile(updatedFileUploadOption, folderId);
          }

          if (updatedFileUploadOption.status === FileUploadStatus.Completed) {
            shouldRehydrate.value = true;
            setFileToUploadOption({ ...updatedFileUploadOption });
          } else if (updatedFileUploadOption.status === FileUploadStatus.Failed) {
            setFileToUploadOption({ ...updatedFileUploadOption });

            isUploading.value = false;
            isError.value = true;

            break;
          }
        } catch (error) {
          console.log(error);
          updatedFileUploadOption = { ...updatedFileUploadOption, status: FileUploadStatus.Failed };

          setFileToUploadOption(updatedFileUploadOption);

          isUploading.value = false;
          isError.value = true;

          break;
        }
      }
    }

    isUploading.value = false;

    if (isError.value === false) {
      toasted.global.general_success({ message: 'File upload(s) successful.' });

      isSuccess.value = true;
      emit('success', {
        shouldRehydrate: shouldRehydrate.value,
        playlistItems: temporaryPlaylistItems.value,
      });
    } else {
      toasted.global.general_error({ message: 'Failed to upload file.' });
    }
  }

  async function processAndUploadPdf(fileUploadOption: FileUploadOption, folderId: number | null) {
    var updatedFileUploadOption = fileUploadOption;

    updatedFileUploadOption = { ...updatedFileUploadOption, status: FileUploadStatus.Processing };
    setFileToUploadOption(updatedFileUploadOption);

    const thumbs = await pdfToFiles(fileUploadOption.file);
    const response = await apiUploadPDF(
      fileUploadOption.file,
      thumbs,
      folderId,
      (progressEvent) => {
        updatedFileUploadOption = handleFileUploadProgress(progressEvent, fileUploadOption);
      },
    );
    temporaryPlaylistItems.value.push({ ...response.pdf_content, item_type: 'app/pdf' });

    updatedFileUploadOption = { ...updatedFileUploadOption, status: FileUploadStatus.Completed };
    setFileToUploadOption(updatedFileUploadOption);

    return updatedFileUploadOption;
  }

  async function uploadFile(fileUploadOption: FileUploadOption, folderId: number | null) {
    var updatedFileUploadOption = fileUploadOption;

    const fileUpload = await apiUploadFile(
      fileUploadOption.file.name,
      fileUploadOption.file,
      folderId,
      (progressEvent) => {
        updatedFileUploadOption = handleFileUploadProgress(progressEvent, fileUploadOption);
      },
    );
    temporaryPlaylistItems.value.push(fileUpload.temporaryPlaylistItem);
    if (props.awaitProcessing) {
      return await pollFileUploadStatus(updatedFileUploadOption, fileUpload.fileUploadId);
    }
    return updatedFileUploadOption;
  }

  async function pollFileUploadStatus(
    fileUploadOption: FileUploadOption,
    fileUploadId: string,
    pollInterval: number = 2000,
  ) {
    var updatedFileUploadOption = fileUploadOption;

    do {
      // kill the ongoing task if the component has unmounted to prevent it from continuing to run in the background
      if (isMounted.value === false) {
        if (
          updatedFileUploadOption.status != null &&
          updatedFileUploadOption.status != FileUploadStatus.Completed
        ) {
          updatedFileUploadOption = { ...updatedFileUploadOption, status: FileUploadStatus.Failed };
          setFileToUploadOption(updatedFileUploadOption);
        }
        break;
      }

      const { status } = await apiGetFileUploadStatus(fileUploadId);

      if (status === 'Pending') {
        updatedFileUploadOption = {
          ...updatedFileUploadOption,
          status: FileUploadStatus.PendingProcess,
        };
        setFileToUploadOption(updatedFileUploadOption);
      } else if (status === 'Processing') {
        updatedFileUploadOption = {
          ...updatedFileUploadOption,
          status: FileUploadStatus.Processing,
        };
        setFileToUploadOption(updatedFileUploadOption);
      } else if (status === 'Completed') {
        updatedFileUploadOption = {
          ...updatedFileUploadOption,
          status: FileUploadStatus.Completed,
        };
        setFileToUploadOption(updatedFileUploadOption);
        break;
      } else if (status === 'Failed') {
        updatedFileUploadOption = { ...updatedFileUploadOption, status: FileUploadStatus.Failed };
        setFileToUploadOption(updatedFileUploadOption);
        break;
      } else {
        updatedFileUploadOption = { ...updatedFileUploadOption, status: FileUploadStatus.Failed };
        setFileToUploadOption(updatedFileUploadOption);

        console.error('Unsupported file upload status');
        toasted.global.general_error({ message: 'An error occurred during file upload.' });
        break;
      }

      await sleep(pollInterval);
    } while (true);

    return updatedFileUploadOption;
  }

  function handleFileUploadProgress(progressEvent: any, fileUploadOption: FileUploadOption) {
    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);

    if (
      (fileUploadOption.percent < 100 && percent === 100) ||
      (fileUploadOption.percent < 100 && percent < 100)
    ) {
      const newFileUploadOption = { ...fileUploadOption, percent: percent };

      setFileToUploadOption(newFileUploadOption);
      return newFileUploadOption;
    }

    return fileUploadOption;
  }

  function handleClose() {
    emit('close', {
      shouldRehydrate: shouldRehydrate.value,
      playlistItems: temporaryPlaylistItems.value,
    });
  }

  function setFileToUploadOption(fileUploadOption: FileUploadOption) {
    const insertIndex = fileToUploadOptions.value.findIndex(
      (option) => option.file.name === fileUploadOption.file.name,
    );

    if (insertIndex > -1) {
      fileToUploadOptions.value.splice(insertIndex, 1, fileUploadOption);
    }
  }
</script>

<template>
  <Modal
    ref="modalRef"
    class="file-upload-modal-container"
    title="Upload Media"
    icon="upload"
    @close="handleClose"
  >
    <div class="modal-content">
      <div class="left-panel">
        <FileDropZone @filesSelected="handleFilesSelect" :allowMultiple="allowMultiple" />
        <div v-if="fileToUploadOptions.length > 0" class="file-item-list scrollbar">
          <FileUploadRow
            v-for="option in fileToUploadOptions"
            :key="option.file.name"
            :fileType="option.file.type"
            :fileName="option.file.name"
            :fileSize="option.file.size"
            :status="option.status"
            :uploadPercent="option.percent"
            @remove="(fileName) => handleFileRemove(fileName)"
          >
          </FileUploadRow>
        </div>
        <EmptyState v-else class="styled-empty-state">
          <template #title>
            <BaseText variant="subtitle2" class="empty-state-title-text">
              No {{ props.allowMultiple ? 'files' : 'file' }} to upload
            </BaseText>
          </template>
        </EmptyState>
      </div>
      <div class="right-panel">
        <BaseText variant="subtitle2" class="right-panel-title-text">Supported formats</BaseText>
        <div class="right-panel-content-section">
          <BaseText variant="subtitle1" class="right-panel-content-text">
            <strong>Formats: </strong>JPEG, PNG, PDF, MP3, MP4
          </BaseText>
          <BaseText variant="subtitle2" class="right-panel-content-text">
            Images can be up to {{ ALLOWED_MAX_IMAGE_SIZE_IN_MB }} MB, and MP3 and MP4 files up to
            {{ ALLOWED_MAX_FILE_SIZE_IN_MB }} MB. The maximum supported resolution is 1920x1080 (or
            1080x1920 for vertical resolution). Images and videos with higher resolutions will be
            automatically scaled down to maintain the aspect ratio.
          </BaseText>
        </div>
        <div>
          <BaseText variant="subtitle1" class="right-panel-content-text">
            <strong>Folder</strong>
          </BaseText>
          <BaseSelect v-model="selectedFolderId">
            <option value="null">None</option>
            <option
              v-for="folder in folders"
              :key="folder.id"
              :value="folder.id"
              :selected="folder.id === selectedFolderId"
            >
              {{ folder.name }}
            </option>
          </BaseSelect>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <div class="footer-container">
        <BaseButton theme="secondary" size="sm" @click="handleClose">Cancel</BaseButton>
        <BaseButton
          size="sm"
          :disabled="fileToUploadOptions.length === 0"
          :loading="isUploading"
          @click="handleMediaFileUpload"
        >
          Upload
        </BaseButton>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
  .modal-content {
    display: grid;
    grid-template-columns: minmax(0, 2fr) 1fr;
    column-gap: 16px;
    row-gap: 24px;
    border: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .left-panel {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .file-item-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 200px;
    max-height: 200px;
    overflow-y: auto;
  }

  .file-item-row {
    position: relative;
    display: flex;
    gap: 8px;
    width: 100%;
    min-height: 72px;
    padding: 8px;
    background-color: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
    align-items: center;
  }

  .styled-empty-state {
    width: 100%;
    height: 200px;
    max-height: 200px;
  }

  .empty-state-title-text {
    font-size: 16px;
    color: #151515;
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    gap: 8px;
    background-color: #f3f3f3;
    border-radius: 8px;
  }

  .right-panel-title-text {
    font-size: 16px;
    color: #151515;
  }

  .right-panel-content-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .right-panel-content-text {
    font-size: 12px;
    color: #6a6b6a;
    line-height: 1rem;
  }

  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    height: 100%;
  }
</style>
