<template>
  <div class="content-view-container">
    <FileUploadModal
      v-if="!multipleFolder && isFileUploadModalVisible"
      :folders="allFolders"
      :selectedFolder="selectedFolder"
      @close="(data) => handleCloseFileUploadModal(data)"
      @success="(data) => handleCloseFileUploadModal(data)"
    />
    <CreateContentFolderModal
      v-if="isCreateContentFolderModalVisible"
      :parentFolder="selectedFolder?.id"
      @close="(data) => handleCloseCreateContentFolderModal(data)"
      @success="(data) => handleCloseCreateContentFolderModal(data)"
    />

    <div class="loader" v-if="requestStatus === 'loading'">
      <div>Loading....</div>
    </div>

    <div class="content" v-else>
      <div class="content-body">
        <div class="folders">
          <div class="folders-header section-header">
            <div class="header-top">
              <div class="title">Folders</div>
              <i
                v-show="!multipleFolder && !multipleContent"
                class="material-icons-outlined align-middle button"
                @click="showCreateContentFolderModal(true)"
              >
                add
              </i>
            </div>
            <div class="header-bottom">
              <i
                class="filter-icon material-icons-outlined button"
                :class="{ active: showFolderFilters }"
                @click="toggleFolderFilter"
              >
                filter_alt
              </i>
              <SearchButton v-model="folderSearchTerm" />
              <span
                v-show="!multipleContent"
                class="material-icons-outlined button inline-block cursor-pointer ml-auto"
                title="Multiple selection"
                :class="{ 'text-primary-danger': multipleFolder }"
                @click="handleMultipleFolderSelection"
              >
                select_all
              </span>
            </div>
            <div v-if="showFolderFilters" class="filters">
              <SelectFilter
                label="File Type"
                :options="[
                  { label: 'All', value: '' },
                  { label: 'Apps', value: 'apps' },
                  { label: 'Videos', value: 'video' },
                  { label: 'Images', value: 'image' },
                  { label: 'PDFs', value: 'pdf_file' },
                ]"
                v-model="folderFilterTerm"
              />
            </div>
          </div>
          <div class="folders-body scrollbar">
            <FolderItem
              :totals="withoutFolderTotals"
              @select-folder="selectPath"
              :active="!selectedRoot"
              :focused="!selectedRoot && !selectedItem"
              v-if="!multipleFolder"
            />
            <FolderItem
              v-for="folder in folders"
              :key="folder.full_path"
              :folder="folder"
              isFolder
              :active="isFolderActive(folder)"
              :focused="isFolderFocused(folder)"
              :selectedFolder="selectedFolder"
              :selectedRoot="selectedRoot"
              @select-folder="selectPath"
              @onDelete="handleDeleteUpdateFolder"
              @onEdit="handleDeleteUpdateFolder"
            />
          </div>
        </div>
        <div class="items" v-if="!multipleFolder">
          <div class="items-header section-header">
            <div class="header-top">
              <div class="title">Items</div>
              <i
                class="material-icons-outlined align-middle button"
                @click="showFileUploadModal(true)"
              >
                add
              </i>
            </div>
            <div class="header-bottom">
              <i
                class="filter-icon material-icons-outlined button"
                :class="{ active: showContentFilters }"
                @click="toggleContentFilter"
              >
                filter_alt
              </i>
              <SearchButton v-model="contentSearchTerm" />
              <span
                v-show="!multipleFolder"
                class="material-icons-outlined button inline-block cursor-pointer ml-auto"
                title="Multiple selection"
                :class="{ 'text-primary-danger': multipleContent }"
                @click="handleMultipleItemSelection"
              >
                select_all
              </span>
            </div>
            <div v-if="showContentFilters" class="filters">
              <SelectFilter
                label="File Type"
                :options="[
                  { label: 'All', value: '' },
                  { label: 'Apps', value: 'apps' },
                  { label: 'Videos', value: 'video' },
                  { label: 'Images', value: 'image' },
                  { label: 'PDFs', value: 'pdf_file' },
                ]"
                v-model="contentFilterTerm"
              />
            </div>
          </div>
          <div class="items-body scrollbar" v-if="filteredContent.length">
            <ContentItem
              v-for="item in filteredContent"
              class="item"
              :key="item.app_id || item.uuid"
              :item="item"
              :class="itemSelectedClass(item)"
              @select-item="handleSelectItem"
            />
          </div>
          <div class="items-body" v-else>
            <div class="empty">No items found</div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="multipleFolder || multipleContent">
      <MultiSelectSettings
        :items="multipleItems"
        :isFolder="multipleFolder"
        @onClose="cancelMultiSelection"
        @onDelete="cancelMultiSelection(true)"
        @onUpdate="onItemUpdated"
      />
    </template>
    <template v-else>
      <ContentItemSetting
        v-if="selectedItem && !multipleContent"
        :item="selectedItem"
        @onUpdate="onItemUpdated"
        @onDelete="handleDeletedItem"
      />
      <ContentFolderSetting
        v-else-if="selectedRoot"
        :folder="selectedFolder"
        @onDelete="handleDeleteUpdateFolder"
      />
      <ContentItemSettingPlaceholder v-else @onUpload="showFileUploadModal(true)" />
    </template>
  </div>
</template>

<script>
  import {
    CONTENT_REQUEST,
    FOLDER_FILTER_REQUEST,
    FOLDER_SEARCH_REQUEST,
  } from '@/store/actions/content';
  import { mapGetters, mapState } from 'vuex';
  import { simpleTypeMixin } from '@/helpers/mixins';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import ContentFolderSetting from '@/components/content/ContentFolderSetting.vue';
  import ContentItemSetting from '@/components/content/ContentItemSetting.vue';
  import ContentItem from '@/components/content/ContentView/ContentItem.vue';
  import FolderItem from '@/components/content/ContentView/FolderItem.vue';
  import MultiSelectSettings from '@/components/content/MultiSelectSettings.vue';
  import ContentItemSettingPlaceholder from '@/components/content/ContentItemSettingPlaceholder.vue';
  import SearchButton from '@/components/common/SearchButton.vue';
  import SelectFilter from '@/components/content/SelectFilter.vue';
  import FileUploadModal from '@/components/common/fileUploadModal/FileUploadModal.vue';
  import CreateContentFolderModal from '@/components/common/CreateContentFolderModal/CreateContentFolderModal.vue';

  export default {
    name: 'ContentView',
    mixins: [simpleTypeMixin],
    data() {
      return {
        selectedRoot: null,
        selectedFolder: null,
        selectedItem: false,
        multipleFolder: false,
        multipleContent: false,
        folderSearchTerm: '',
        folderFilterTerm: '',
        showFolderSearchBox: false,
        showFolderFilters: false,
        contentSearchTerm: '',
        contentFilterTerm: '',
        showContentSearchBox: false,
        showContentFilters: false,
        isFileUploadModalVisible: false,
        isCreateContentFolderModalVisible: false,
      };
    },
    components: {
      CreateContentFolderModal,
      FileUploadModal,
      MultiSelectSettings,
      FolderItem,
      ContentItem,
      SidebarContainer,
      ContentFolderSetting,
      ContentItemSetting,
      ContentItemSettingPlaceholder,
      SearchButton,
      SelectFilter,
    },

    mounted() {
      this.reloadContent();
    },

    computed: {
      ...mapState({
        requestStatus: (state) => state.content.requestStatus,
        content: (state) => state.content.content,
      }),
      ...mapGetters({ folders: 'getFoldersDetails', allFolders: 'getAllContentFolders' }),

      withoutFolderItems() {
        return this.content.filter((item) => item.content_type !== 'folder');
      },

      withoutFolderTotals() {
        return {
          images: this.withoutFolderItems.filter(
            (item) => this.getSimpleType(item.item_type) === 'image',
          ).length,
          videos: this.withoutFolderItems.filter(
            (item) => this.getSimpleType(item.item_type) === 'video',
          ).length,
          pdfs: this.withoutFolderItems.filter(
            (item) => this.getSimpleType(item.item_type) === 'pdf',
          ).length,
          apps: this.withoutFolderItems.filter(
            (item) => item.item_type?.startsWith('app/') && !item.item_type?.includes('pdf'),
          ).length,
        };
      },

      filteredContent() {
        if (this.multipleFolder) {
          return [];
        }
        let contents;
        if (this.selectedFolder) {
          contents = this.selectedFolder.contents;
        } else {
          contents = this.content;
        }
        return contents.filter((item) => {
          let itemFilter =
            item.content_type !== 'folder' &&
            !item.item_type?.includes('text') &&
            !item.item_type?.includes('clock') &&
            !item.item_type?.includes('button');

          if (itemFilter && this.contentSearchTerm) {
            itemFilter = item?.name?.toLowerCase()?.includes(this.contentSearchTerm.toLowerCase());
          }
          if (itemFilter && this.contentFilterTerm) {
            itemFilter = this.isItemMatchWithFilteredType(item);
          }
          return itemFilter;
        });
      },

      multipleItems() {
        if (this.multipleFolder && Array.isArray(this.selectedFolder)) {
          return this.selectedFolder;
        }
        if (this.multipleContent && Array.isArray(this.selectedItem)) {
          return this.selectedItem;
        }
        return [];
      },
    },

    watch: {
      content: {
        handler(newContent) {
          // Check if root folder needs to be updated
          if (this.selectedRoot) {
            const rootFolder = this.findFolderWithPath(this.selectedRoot.full_path);
            if (rootFolder) {
              this.selectedRoot = rootFolder;
            }
          }

          // Check if selected folder needs to be updated
          if (this.selectedFolder) {
            const folder = this.findFolderWithPath(this.selectedFolder.full_path);
            if (folder) {
              this.selectedFolder = folder;
            }
          }
        },
        deep: true,
      },

      folderSearchTerm(val) {
        this.$store.dispatch(FOLDER_SEARCH_REQUEST, val || '');
      },

      folderFilterTerm(val) {
        this.$store.dispatch(FOLDER_FILTER_REQUEST, val);
      },
    },

    methods: {
      async reloadContent() {
        await this.$store.dispatch(CONTENT_REQUEST);
      },

      selectPath(root, folder, prevSubFolders = []) {
        if (this.multipleContent) {
          return;
        }
        if (!root) {
          this.selectedRoot = null;
          this.selectedFolder = null;
          this.selectedItem = false;
          return;
        }

        if (this.multipleFolder) {
          let foundIndex = -1;

          if (prevSubFolders.length) {
            prevSubFolders.forEach((folder) => this._unselectIfSelected(folder));
          }
          this.selectedFolder.some((item, index) => {
            if (item.full_path === folder.full_path) {
              foundIndex = index;
              return true;
            }
          });
          if (foundIndex >= 0) {
            this.selectedFolder.splice(foundIndex, 1);
            this._unselectIfSelected(root, true);
            return;
          }
          if (root.full_path !== folder.full_path) {
            this._unselectIfSelected(root);
          }
          this.selectedFolder.push(folder);

          if (!this.selectedRoot.some((r) => r.full_path === root.full_path)) {
            this.selectedRoot.push(root);
          }
          return;
        }
        this.selectedFolder = folder;
        this.selectedRoot = root;
        this.selectedItem = false;
      },

      _unselectIfSelected(folder, isRoot = false) {
        let items;

        if (isRoot) {
          items = this.selectedRoot;
        } else {
          items = this.selectedFolder;
        }
        items.some((item, index) => {
          if (item.full_path === folder.full_path) {
            items.splice(index, 1);
            return true;
          }
        });
      },

      handleSelectItem(item) {
        if (this.multipleFolder) {
          return;
        }
        if (this.multipleContent) {
          let foundIndex = -1;

          this.selectedItem.some((content, index) => {
            if (content.item_id === item.item_id) {
              foundIndex = index;
              return true;
            }
          });

          if (foundIndex >= 0) {
            this.selectedItem.splice(foundIndex, 1);
          } else {
            this.selectedItem.push(item);
          }
          return;
        }
        this.selectedItem = item;
      },

      async handleCloseFileUploadModal(data) {
        this.showFileUploadModal(false);

        if (data.shouldRehydrate) {
          // force rerender nested folder items
          this.selectedRoot = null;
          this.selectedFolder = null;
          this.selectedItem = false;
          await this.reloadContent();
        }
      },

      async handleCloseCreateContentFolderModal(data) {
        this.showCreateContentFolderModal(false);

        if (data.shouldRehydrate) {
          // force rerender nested folder items
          this.selectedRoot = null;
          this.selectedFolder = null;
          this.selectedItem = false;
          await this.reloadContent();
        }
      },

      showFileUploadModal(show) {
        this.isFileUploadModalVisible = show;
      },

      showCreateContentFolderModal(show) {
        this.isCreateContentFolderModalVisible = show;
      },

      recursivelyFindFolder(path, foldersToSearch) {
        for (let i = 0; i < foldersToSearch.length; i++) {
          if (foldersToSearch[i].full_path === path) {
            return foldersToSearch[i];
          }
          if (foldersToSearch[i].children) {
            const folder = this.recursivelyFindFolder(path, foldersToSearch[i].children);
            if (folder) {
              return folder;
            }
          }
        }
        return false;
      },

      findFolderWithPath(path) {
        const pathArray = path?.split('/') || [];
        const depth = pathArray.length;
        let currentFolder =
          depth >= 1 ? this.folders.find((folder) => folder.name === pathArray[0]) : false;

        for (let i = 1; i < depth; i++) {
          const found = currentFolder?.contents?.find((folder) => folder.name === pathArray[i]);

          if (found) {
            currentFolder = found;
          }
        }
        return currentFolder;
      },

      async onItemUpdated() {
        this.selectedItem = false;
        this.multipleContent = false;
        await this.reloadContent();
        this.selectedFolder = this.allFolders.find((item) => item.id === this.selectedFolder?.id);
      },

      handleDeletedItem() {
        this.selectedItem = null;
        this.selectedRoot = null;
        this.selectedFolder = null;
        this.reloadContent();
      },

      handleDeleteUpdateFolder() {
        this.handleDeletedItem();
        this.selectedRoot = null;
        this.selectedFolder = null;
        this.reloadContent();
      },

      handleMultipleContentSelection() {
        if (this.multipleFolder) {
          return;
        }
        this.multipleContent = true;

        if (this.selectedItem) {
          this.selectedItem = [this.selectedItem];
        } else {
          this.selectedItem = [];
        }
      },

      handleMultipleFolderSelection() {
        if (this.multipleFolder) {
          this.cancelMultiSelection();
          return;
        }
        if (!this.selectedRoot) {
          this.selectedRoot = [];
        }
        if (!Array.isArray(this.selectedRoot)) {
          this.selectedRoot = [this.selectedRoot];
        }
        if (!this.selectedFolder) {
          this.selectedFolder = [];
        }
        if (!Array.isArray(this.selectedFolder)) {
          this.selectedFolder = [this.selectedFolder];
        }
        this.multipleFolder = true;
      },

      handleMultipleItemSelection() {
        if (this.multipleContent) {
          this.cancelMultiSelection();
          return;
        }
        if (!this.selectedItem) {
          this.selectedItem = [];
        }
        if (!Array.isArray(this.selectedItem)) {
          this.selectedItem = [this.selectedItem];
        }
        this.multipleContent = true;
      },

      isFolderActive(folder) {
        if (this.multipleFolder && Array.isArray(this.selectedRoot)) {
          return this.selectedRoot?.some((root) => root?.full_path === folder.full_path);
        }
        return folder.full_path === this.selectedRoot?.full_path;
      },

      isFolderFocused(folder) {
        if (!this.multipleFolder) {
          return folder.full_path === this.selectedRoot?.full_path && !this.selectedItem;
        }
        return this.isFolderActive(folder);
      },

      itemSelectedClass(selectedItem) {
        if (!this.selectedItem) {
          return '';
        }

        let selected = false;

        if (this.multipleContent) {
          selected = this.selectedItem.some((item) => {
            return item.item_id === selectedItem?.item_id;
          });
        } else {
          selected = this.selectedItem?.item_id === selectedItem?.item_id;
        }

        return selected ? 'active' : '';
      },

      cancelMultiSelection(isDeleted = false) {
        if (this.multipleFolder) {
          this.multipleFolder = false;
          this.selectedFolder = null;
          this.selectedRoot = null;
          this.selectedItem = false;
        }
        if (this.multipleContent) {
          if (isDeleted && this.selectedFolder) {
            this.selectedRoot = null;
            this.selectedFolder = null;
          }
          this.selectedItem = false;
          this.multipleContent = false;
        }
      },

      showFolderSearchArea() {
        this.showFolderSearchBox = true;
        this.$nextTick(() => this.$refs.folderSearchBox.focus());
      },

      toggleFolderFilter() {
        this.showFolderFilters = !this.showFolderFilters;

        if (this.folderFilterTerm) {
          this.folderFilterTerm = '';
        }
      },

      showContentSearchArea() {
        this.showContentSearchBox = true;
        this.$nextTick(() => this.$refs.contentSearchBox.focus());
      },

      toggleContentFilter() {
        this.showContentFilters = !this.showContentFilters;

        if (this.contentFilterTerm) {
          this.contentFilterTerm = '';
        }
      },

      isItemMatchWithFilteredType(item) {
        let matched = false;

        switch (this.contentFilterTerm) {
          case 'apps':
            matched = item?.item_type?.startsWith('app/') && item?.item_type !== 'app/pdf';
            break;
          case 'image':
            matched = item?.item_type?.startsWith('image');
            break;
          case 'video':
            matched = item?.item_type?.startsWith('video');
            break;
          case 'pdf_file':
            matched = item?.item_type === 'app/pdf';
            break;
          default:
            matched = item?.item_type === this.contentFilterTerm;
        }
        return matched;
      },
    },
  };
</script>

<style lang="scss" scoped>
  pre {
    color: black;
  }
  .loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
  }
  .content-view-container {
    font-family: 'Poppins';
    display: grid;
    grid-template-columns: auto 376px;
    width: 100%;
    height: 100vh;
    color: #151515;
    background-color: #f7f8f7;
    .content {
      height: 100%;
      overflow: hidden;
      .content-body {
        padding: 48px 10px 0px 24px;
        display: flex;
        height: 100%;
        overflow: hidden;
        .folders,
        .items {
          width: 50%;
        }
        .items {
          border-left: 2px solid #f0f0f0;
        }

        .folders {
          display: flex;
          flex-direction: column;
          .folders-header {
            box-sizing: border-box;
            padding: 0px 16px;
          }
          .folders-body {
            flex: 1;
            box-sizing: border-box;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 28px 16px;
          }
        }

        .items {
          display: flex;
          flex-direction: column;
          .items-header {
            padding: 0px 16px;
          }
          .items-body {
            flex: 1;
            padding: 28px 16px;
            display: grid;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            grid-template-columns: repeat(3, minmax(1px, 1fr));
            align-content: start;
            align-items: stretch;
            gap: 8px;
            .item {
              height: fit-content;
              width: 100%;
            }
          }
        }
      }
    }

    .section-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 4px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      font-family: 'Whyte Inktrap';
      .header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          color: #6a6b6a;
        }
      }

      .header-bottom {
        display: flex;
        align-items: center;
        vertical-align: middle;
        color: #6a6b6a;
        gap: 10px;

        .filter-icon {
          height: 30px;
        }
      }
    }
  }
</style>
