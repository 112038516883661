<template>
  <SidebarContainer class="editor-screen-sidebar">
    <template #default v-if="!selectedWidget || verifyLock(selectedWidget)">
      <div class="sidebar-section">
        <div class="description">
          <div class="section-title">
            <i class="material-icons-outlined align-middle">dashboard</i>
            <span> {{ isEditingScreen ? 'Screen Layout' : 'Group Layout' }} </span>
          </div>

          <div type="text" class="form-control disabled">{{ layout?.settings.name }}</div>
        </div>

        <div class="section-content">
          <div class="title">Screen Resolution:</div>
          <span>
            {{ layoutResolution }} (
            {{ layoutDimensions?.width + ' x ' + layoutDimensions?.height }} /
            {{ layoutAspectRatio }} )
          </span>
        </div>
      </div>

      <div class="sidebar-section">
        <div class="section-title">
          <i class="material-icons-outlined align-middle">layers</i>
          <span class="title">Layers</span>
        </div>
        <div class="content-section">
          <div class="layers">
            <div
              class="layer"
              v-for="(layer, index) in layoutChildren"
              :key="index"
              @click="selectWidget(layer)"
              :title="getLockTitle(layer)"
            >
              <i class="material-icons-outlined">{{ getIconType(layer) }}</i>
              <span> {{ getLayerName(layer) }}</span>
              <i v-if="verifyLock(layer)" class="material-icons-outlined align-middle right">
                lock
              </i>
              <i v-else class="material-icons-outlined align-middle right">chevron_right</i>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #default v-else-if="selectedWidget">
      <template v-if="isLoadingAccess">
        <div class="wrapper">
          <Loader />
        </div>
      </template>

      <template v-else>
        <template v-if="isLayerSwapable(selectedWidget)">
          <div class="wrapper">
            <div class="back-wrapper">
              <div class="back" @click="goBack">
                <i class="material-icons-outlined align-middle">arrow_back</i>
                <div class="text" v-if="screen">
                  Back to <b>{{ screen.name }}</b> Screen
                </div>
                <div class="text" v-else>Back</div>
              </div>

              <DeleteOverrideButton
                v-if="showOverrideResetButton"
                @deleteOverride="deleteOverride"
              />
            </div>

            <div class="info">
              <i class="material-icons-outlined">{{ getIconType(selectedWidget) }}</i>
              <span class="text-capitalize">
                {{ getTitleFromType(selectedWidget.itemType) }} Layer
              </span>
            </div>

            <div
              v-if="!['table', 'richtext', 'weather'].includes(getLayerType(selectedWidget))"
              class="widget-preview-container"
            >
              <WidgetNode
                class="widget-preview"
                :node="{
                  id: getLayerType(selectedWidget) || 'playlist',
                  data: selectedWidget,
                }"
              />
            </div>

            <TableContent
              v-if="getLayerType(selectedWidget) === 'table'"
              ref="TableContent"
              :rows="selectedWidget.object.rows"
              :columns="selectedWidget.object.columns"
              @onSave="saveTableContent"
            />

            <BaseButton
              v-if="!['table', 'richtext'].includes(getLayerType(selectedWidget))"
              variant="outline"
              icon="insert_photo"
              @click="openContentBrowser"
            >
              Replace
            </BaseButton>

            <RichTextEditor
              v-if="getLayerType(selectedWidget) === 'richtext'"
              ref="RichTextEditor"
              @save="saveLayerData"
              :widget="selectedWidget"
            />
          </div>
        </template>

        <template v-else-if="getLayerType(selectedWidget) === 'template'">
          <TemplateOverrideSettings
            :screen="screen"
            :template="selectedWidget.object"
            @goBack="goBack"
          />
        </template>

        <template
          v-else-if="getLayerType(selectedWidget) === 'text' || selectedWidget.itemType === 'layer'"
        >
          <div class="wrapper">
            <div class="back-wrapper">
              <div class="back" @click="goBack">
                <i class="material-icons-outlined align-middle">arrow_back</i>
                <div class="text" v-if="screen">
                  Back to <b>{{ screen.name }}</b> Screen
                </div>
                <div class="text" v-else>Back</div>
              </div>

              <DeleteOverrideButton
                v-if="showOverrideResetButton"
                @deleteOverride="deleteOverride"
              />
            </div>

            <div class="info">
              <i class="material-icons-outlined">{{ getIconType(selectedWidget) }}</i>
              <span class="text-capitalize">{{ getTitleFromType(selectedWidget.itemType) }}</span>
            </div>

            <template v-if="getLayerType(selectedWidget) === 'text'">
              <BaseInput label="Heading" v-model="selectedWidget.object.headerText" />

              <BaseInput label="Body">
                <textarea
                  class="form-control"
                  v-model="selectedWidget.object.userText"
                  :rows="3"
                  :placeholder="selectedWidget.object.userText"
                />
              </BaseInput>
            </template>

            <template v-if="selectedWidget.itemType === 'layer'">
              <div class="">
                <div class="custom-control custom-switch layouts">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="text-ticker"
                    v-model="selectedWidget.object.useLink"
                  />
                  <label class="custom-control-label" for="text-ticker">Use Link</label>
                </div>
              </div>

              <div v-if="selectedWidget.object.useLink" class="form-group layout-input link">
                <label for="layout-name">Link to website</label>
                <input
                  class="form-control"
                  type="text"
                  id="layout-link"
                  maxlength="260"
                  placeholder="http://..."
                  v-model="selectedWidget.object.link"
                />
              </div>
            </template>
          </div>
        </template>

        <!-- Playlist -->
        <template v-else-if="selectedWidget?.type === 'playlist'">
          <PlaylistsItems
            v-if="!currentPlaylistTemplate"
            ref="PlaylistItems"
            :playlist="selectedWidget.object"
            @goBackButton="goBack"
            @playlistItemsUpdated="handlePlaylistItemsUpdate"
            @playlistsTemplateSidebar="openPlaylistTemplateSidebar"
            @contentChange="onContentChange"
          />
          <TemplateOverrideSettings
            v-else
            :screen="screen"
            :template="currentPlaylistTemplate.template"
            :playlist="selectedWidget.object"
            @goBack="currentPlaylistTemplate = null"
          />
        </template>

        <!-- The content is not editable -->
        <template v-else>
          <div class="wrapper">
            <div class="back" @click="goBack">
              <i class="material-icons-outlined align-middle">arrow_back</i>
              <div class="text" v-if="screen">
                Back to <b>{{ screen.name }}</b> Screen
              </div>
              <div class="text" v-else>Back</div>
            </div>

            <Tip title="No edit options" class="bg-gray">The selected layer is not editable</Tip>
          </div>
        </template>
      </template>

      <!-- Swapables -->

      <ContentBrowserModal
        ref="contentBrowserModal"
        :filterItemTypes="contentBrowserFilters"
        :isSaving="replacingContent"
        @submitted="handleContentSubmit"
        class="skip-overlay"
      />
    </template>

    <template #buttons>
      <template v-if="!verifyLock(selectedWidget)">
        <BaseButton
          v-if="selectedWidget && selectedWidget.type === 'playlist' && !currentPlaylistTemplate"
          icon="add"
          variant="outline"
          @click="openPlaylistAddItem"
        >
          Add Item
        </BaseButton>

        <BaseButton
          v-else-if="['text', 'layer'].includes(getLayerType(selectedWidget))"
          icon="save"
          @click="saveLayerData"
          :loading="isSaving"
        >
          Save Changes
        </BaseButton>

        <BaseButton
          v-else-if="getLayerType(selectedWidget) === 'table'"
          icon="save"
          @click="$refs.TableContent.save()"
          :loading="isSaving"
        >
          Save Changes
        </BaseButton>
      </template>

      <Modal
        v-show="showOverrideBlockedModal"
        :modalStyles="{ width: '600px' }"
        noCancelButton
        title="Access Denied"
        :okFunction="() => (showOverrideBlockedModal = false)"
      >
        <template v-slot:body>
          <div class="modal-description">
            <template v-if="isEditingScreen">
              You can't override this screen because your role doesn't have enough access. Ask your
              admin your role's access.
            </template>
            <template v-else>
              <p>
                You are not allowed to override this group's layouts. Because you don't have access
                to all the sub-groups in it.
              </p>
              <p>
                You can still override individual screens, or sub-groups that you have access to.
              </p>
            </template>
          </div>
        </template>
      </Modal>
    </template>
  </SidebarContainer>
</template>

<script>
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import Tip from '@/components/Tip.vue';
  import PlaylistsItems from '@/components/screens/PlaylistsItems.vue';
  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import WidgetNode from '@/components/playerWidgets/WidgetNode.vue';
  import Loader from '@/components/common/Loader.vue';
  import Modal from '@/components/common/Modal.vue';
  import TableContent from '@/components/apps/table/TableContent.vue';
  import TemplateOverrideSettings from '@/components/screens/screen/TemplateOverrideSettings.vue';
  import RichTextEditor from '@/components/screens/screen/richTextEditor/RichTextEditor.vue';
  import DeleteOverrideButton from '@/components/screens/screen/DeleteOverrideButton.vue';
  import { SCREEN_ADD_LAYOUT, SCREENS_RESTART } from '@/store/actions/screens';
  import _ from 'lodash';
  import { SET_SELECTED_WIDGET } from '@/store/actions/player';
  import { apiGetLayoutWidgetRoles, apiReadLayouts } from '@/api/layouts';
  import {
    apiCreateScreenGroupItemOverride,
    apiCreateScreenItemOverride,
    apiScreenDeleteItemOverride,
    apiScreenGroupDeleteItemOverride,
    apiScreenGroupUpdateItemOverride,
    apiScreenUpdateItemOverride,
  } from '@/api/screens';
  import { getWidgetIcons } from '@/helpers/mixins';
  import { simpleTypeMixin } from '@/helpers';
  import { APP_TYPES, WIDGET_TYPES } from '@/models/layoutDesigner';
  import { PublicationType } from '@/types/api/screens';

  export default {
    inject: ['handleContentChange'],

    props: {
      screens: {
        type: Array,
        default: () => [],
      },
      screen: {
        type: Object,
        default: () => null,
      },
      isOverridable: {
        type: Boolean,
        default: true,
      },
    },

    components: {
      SidebarContainer,
      PlaylistsItems,
      ContentBrowserModal,
      WidgetNode,
      Tip,
      Loader,
      Modal,
      TableContent,
      TemplateOverrideSettings,
      RichTextEditor,
      DeleteOverrideButton,
    },

    mixins: [simpleTypeMixin],

    data() {
      return {
        isSaving: false,
        isLoadingAccess: false,
        replacingContent: false,
        screenLayout: null,
        layouts: [],
        currentPlaylistTemplate: null,
        isRefreshing: false,
        showOverrideBlockedModal: false,
      };
    },

    created() {
      if (this.isEditingScreen) {
        this.fetchLayouts();
      }
    },

    mounted() {
      this.screenLayout = this.layout?.layout_id;
    },

    updated() {
      if (this.$route.params.layerId) {
        this.fetchLayerPermissions();
      }
    },

    watch: {
      layout() {
        this.screenLayout = this.layout?.layout_id;
      },
      layoutChildren(newLayoutChildren) {
        for (const widget of Object.values(newLayoutChildren)) {
          if (
            widget.itemType === WIDGET_TYPES.PLAYLIST &&
            widget.object.playlist_id === this.$route.query.playlistId
          ) {
            this.selectWidget(widget);
            return;
          }
        }
      },
    },

    computed: {
      showOverrideResetButton() {
        if (!this.selectedWidget) return false;

        const { override } = this.selectedWidget.object;

        if (!override) return false;

        return (this.groupId && !!override.screen_group) || (!this.groupId && !!override.screen);
      },

      isEditingScreen() {
        return !!this.$route.params?.screenId;
      },

      shouldCreateGroupOverride() {
        return (
          !this.isEditingScreen &&
          this.selectedWidget?.object.override &&
          this.selectedWidget?.object.override?.screen_group === null
        );
      },

      layout() {
        return this.$store.state.player.layout;
      },

      selectedWidget() {
        return this.$store.state.player.selectedWidget;
      },

      layoutAspectRatio() {
        return this.layoutDimensions.ratio;
      },

      layoutDimensions() {
        return this.$store.getters.getPlayerResolution;
      },

      layoutResolution() {
        return this.layout?.settings.resolution;
      },

      lockLayers() {
        return this.isEditingScreen && !!this.screen?.screen_group;
      },

      groupId() {
        return this.$route.params?.groupId;
      },

      layoutChildren() {
        return this.$store.state.player.widgets;
      },

      contentBrowserFilters() {
        let item_type = this.selectedWidget?.object?.item_type;
        if (!item_type) return [];
        if (item_type.startsWith('image')) return ['image'];

        if (item_type.startsWith('video')) return ['video'];

        if (item_type.startsWith('app')) return item_type === 'app/pdf' ? ['pdf'] : ['app'];
      },
    },

    methods: {
      async onContentChange() {
        await this.handleContentChange();
      },

      getLayerName(layer) {
        return layer.object.name || this.getSimpleType(layer.object.item_type).toUpperCase();
      },

      getLayer(layerId) {
        return this.layoutChildren.find((layer) => layer.assoc_id === layerId);
      },

      async fetchLayouts() {
        try {
          const response = await apiReadLayouts();
          this.layouts = response.data.items;
        } catch (error) {
          console.log('ScreenSidebar fetchLayouts error: ', error);
        }
      },

      goBack() {
        if (!_.isEmpty(this.$route.query)) {
          this.$router.replace({ query: null });
        }
        this.selectWidget(null);
      },

      async saveScreenData() {
        if (!_.isEmpty(this.$route.query)) {
          this.$router.replace({ query: null });
        }

        this.isSaving = true;

        try {
          // Check if layout is changed
          if (this.screenLayout !== this.layout.layout_id) {
            await this.$store.dispatch(SCREEN_ADD_LAYOUT, {
              screenId: this.screen.id,
              layoutId: this.screenLayout,
            });
          }

          await this.handleContentChange();

          // Show success message
          this.$toasted.global.general_success({
            message: 'Screen saved successfully',
          });
        } catch (err) {
          this.$toasted.global.general_error({
            message: 'Error while saving screen',
          });
        }

        this.isSaving = false;
      },

      async fetchLayerPermissions() {
        if (!this.isLayerSwapable(this.selectedWidget)) return;
        this.isLoadingAccess = true;
        try {
          let res = await apiGetLayoutWidgetRoles(
            this.selectedWidget.object.item_id,
            this.selectedWidget.type,
          );
          console.log(res.data);
        } catch (e) {
          console.log(e);
          this.$toasted.global_error({
            message: 'Unable to fetch layer access',
          });
        }
        this.isLoadingAccess = false;
      },

      async handleContentSubmit(files) {
        this.replacingContent = true;
        let item = files[0];

        try {
          const isObjectAnOverride = this.selectedWidget.object.override && this.createOverride;

          isObjectAnOverride && !this.shouldCreateGroupOverride
            ? await this.updateOverride(item.item_id)
            : await this.createOverride(item.item_id);

          await this.handleContentChange();

          this.$toasted.global.general_success({
            message: 'The content was replaced successfully',
          });
        } catch (e) {
          this.$toasted.global.general_error({
            message: String(e),
          });
        }

        this.replacingContent = false;
        this.closeContentBrowser();
        this.$store.commit(SET_SELECTED_WIDGET, null);
      },

      handlePlaylistItemsUpdate() {
        this.$store.dispatch(SCREENS_RESTART, {
          publicationType: PublicationType.ScreenPublication,
          data: {
            screen_ids: this.screens.map((screen) => screen.id),
          },
        });
      },

      async saveLayerData() {
        this.isSaving = true;

        const type = this.getLayerType(this.selectedWidget);
        const config = this.selectedWidget.object;

        if (type === 'text' || type === 'layer') {
          await this.saveTextLayer(config);
        } else if (type === 'richtext') {
          const htmlText = this.$refs.RichTextEditor.getRichText();
          await this.saveTextLayer(
            // deprecate base text size by setting it to minus one
            { ...config, userText: htmlText, textSize: -1 },
            'RichText layer saved successfully',
          );
        }

        await this.handleContentChange();

        this.isSaving = false;
      },

      async saveTableContent(tableData) {
        const config = { ...this.selectedWidget.object, ...tableData };

        try {
          if (!this.selectedWidget.object.override || this.shouldCreateGroupOverride) {
            await this.createOverride(this.selectedWidget.object.item_id, config);
          } else {
            await this.updateOverride(this.selectedWidget.object.item_id, config);
          }

          await this.handleContentChange();

          this.$toasted.global.general_success({
            message: 'Table saved successfully',
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
          console.log('saveTableContent error', error);
        }
      },

      async saveTextLayer(config, message = 'Text layer saved successfully') {
        try {
          if (!this.selectedWidget.object.override || this.shouldCreateGroupOverride) {
            await this.createOverride(this.selectedWidget.object.item_id, config);
          } else {
            await this.updateOverride(this.selectedWidget.object.item_id, config);
          }

          this.selectWidget(null);

          this.$toasted.global.general_success({ message });
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
          console.log('saveTextLayer error', error);
        }
      },

      async createOverride(newItemId, overrideData = {}) {
        try {
          const data = {
            itemId: this.selectedWidget.object.item_id,
            newItemId,
            overrideConfig: { ...overrideData, override: undefined },
          };

          this.isEditingScreen
            ? await apiCreateScreenItemOverride({ ...data, screenId: this.screen.id })
            : await apiCreateScreenGroupItemOverride({
                ...data,
                groupId: this.groupId,
                layout: this.screenLayout,
              });
        } catch (error) {
          console.log('createOverride error', error);
          throw error;
        }
      },

      async updateOverride(newItemId, overrideData = {}) {
        try {
          const data = {
            groupId: this.groupId,
            overrideId: this.selectedWidget.object.override.id,
            itemId: this.selectedWidget.object.item_id,
            newItemId,
            overrideConfig: { ...overrideData, override: undefined },
            layout: this.layout?.layout_id,
          };

          this.isEditingScreen
            ? await apiScreenUpdateItemOverride(data)
            : await apiScreenGroupUpdateItemOverride(data);
        } catch (error) {
          console.log('updateOverride error', error);
          throw error;
        }
      },

      async deleteOverride() {
        try {
          const {
            screen: screenId,
            screen_group: groupId,
            id: overrideId,
          } = this.selectedWidget.object.override;

          !!groupId
            ? await apiScreenGroupDeleteItemOverride(groupId, overrideId)
            : await apiScreenDeleteItemOverride(screenId, overrideId);

          await this.handleContentChange();

          this.selectWidget(null);

          this.$toasted.global.general_success({
            message: 'Override removed',
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
      },

      getLayerType(layer) {
        if (!layer) return;

        if (layer.type === 'template') {
          return 'template';
        }

        if (layer.type !== 'playlist') {
          return this.getSimpleType(layer.object.item_type);
        }

        return 'playlist';
      },

      isLayerSwapable(layer) {
        if (!layer) return;

        const { VIDEO, IMAGE, PDF, RICH_TEXT } = WIDGET_TYPES;
        const { RSS, TABLE, TRAFIKLAB, WEATHER } = APP_TYPES;

        const swapables = [VIDEO, IMAGE, PDF, RSS, TABLE, TRAFIKLAB, WEATHER, RICH_TEXT];
        const layerType = this.getLayerType(layer);

        return swapables.includes(layerType);
      },

      openContentBrowser() {
        this.$refs.contentBrowserModal.open();
      },

      closeContentBrowser() {
        this.$refs.contentBrowserModal.close();
      },

      openPlaylistAddItem() {
        this.$refs.PlaylistItems.openPlaylistContentModal();
      },

      openPlaylistTemplateSidebar(item) {
        this.currentPlaylistTemplate = item;
      },

      selectWidget(widget) {
        if (!this.isOverridable) {
          this.showOverrideBlockedModal = true;
          return;
        }

        if (widget && !widget.has_access) {
          return;
        }

        this.$store.commit(SET_SELECTED_WIDGET, widget);
      },

      verifyLock(widget) {
        if (!widget) return false;
        if (!this.isOverridable) return true;
        if (!widget.has_access) return true;

        const isWidgetOverriddenByGroup = !!widget.object.override?.screen_group;

        return this.lockLayers && isWidgetOverriddenByGroup;
      },

      getLockTitle(widget) {
        if (!widget.has_access) {
          ('Locked - You do not have permission to view this widget');
        }
        return this.verifyLock(widget) ? 'Locked - It has overrides at group level' : '';
      },

      getIconType(widget) {
        return getWidgetIcons(widget.itemType || widget.object.type);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .back-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .skip-overlay {
    z-index: 2000 !important;
  }
  .border-none {
    border: none !important;
  }

  .bg-gray {
    background-color: #f4f4f4;
  }

  .layers {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 8px;
    .router-link {
      text-decoration: none;
    }
    .layer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      color: $primaryText;
      width: 100%;
      background: #fff;
      border: 1px solid $backgroundGrey3;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 16px;

      &:hover {
        cursor: pointer;
      }

      span {
        flex: 1;
        max-width: 95%;
        overflow: hidden;
        position: relative;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        // gradient right
        &:after {
          content: '';
          position: absolute;
          right: 0;
          width: 25px;
          height: 100%;
          background: #000;
          background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 100%);
        }
      }

      i.right {
        transition: all 0.2s ease-in-out;
        opacity: 0;
        transform: translateX(-10px) scale(0.4);
        font-size: 24px;
        color: $primaryText;
      }

      &:hover {
        background-color: $backgroundGrey3;
        i.right {
          opacity: 1;
          transform: translateX(5px) scale(1);
        }
      }
    }
  }

  .back {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    user-select: none;

    i {
      font-size: 22px;
    }
    .text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $secondaryText;

      b {
        color: $primaryText;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .widget-preview-container {
      width: 100%;
      overflow: hidden;

      .widget-preview {
        position: relative !important;
        height: 100% !important;
        width: 100% !important;
        top: 0 !important;
        left: 0 !important;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    margin: 8px 0;

    i {
      margin-right: 10px;
    }
  }

  .form-control {
    border: 2px solid #e8e8e8;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
    color: $primaryText;
    background-color: #fff;
    width: 100%;
    height: 100%;
    &:focus {
      border-color: #000000;
      box-shadow: none;
    }
  }

  .content-section {
    & > span {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
  }

  .disabled {
    min-height: 44px;
    background-color: $backgroundGrey2;
    cursor: default;
  }

  .modal-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding: 0;
  }
</style>
