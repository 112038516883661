<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :title="editMode ? 'Edit Role' : 'Add Role'"
    :isSaving="isLoading"
    :disabled="roleName.length < 2"
    :okFunction="() => (editMode ? editRole() : createRole())"
    :okButtonLabel="editMode ? 'Save' : 'Add Role'"
    :modalStyles="{ width: '400px' }"
  >
    <template v-slot:body>
      <div class="group-body">
        <div class="form-group">
          <label for="roleName">
            <div class="label">Role Name</div>
            <input
              type="text"
              name="roleName"
              id="roleName"
              required
              minlength="3"
              maxlength="40"
              v-model="roleName"
              placeholder="Set role name"
            />
          </label>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '../../common/Modal.vue';
  import SendButton from '../../common/SendButton.vue';
  import { CREATE_ROLE, UPDATE_ROLE, GET_ORGANISATION_ROLES } from '@/store/actions/roles';

  export default {
    name: 'RolesModal',

    components: { Modal, SendButton },

    props: {
      role: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        roleName: '',
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;

      if (this.role) {
        this.roleName = this.role.name;
        this.selectedLayers = [...this.role.layers];
      } else {
        this.roleName = '';
      }
    },

    computed: {
      roles() {
        return this.$store.state.roles.roles.map((role) => ({
          users: 0,
          ...role,
        }));
      },

      editMode() {
        return !!this.role;
      },
    },

    methods: {
      async createRole() {
        this.isLoading = true;

        try {
          const successful = await this.$store.dispatch(CREATE_ROLE, this.roleName);

          if (successful) {
            this.$toasted.global.general_success({
              message: 'Role created',
            });

            this.$store.dispatch(GET_ORGANISATION_ROLES);
            this.closeModal();
          } else {
            throw new Error('Failed');
          }
        } catch (e) {
          console.error(e);
          this.$toasted.global.general_error({
            message: 'Ups, something went wrong, please try again later.',
          });
        }

        this.isLoading = false;
      },

      async editRole() {
        this.isLoading = true;

        try {
          const data = {
            ...this.role,
            name: this.roleName,
          };

          const successful = await this.$store.dispatch(UPDATE_ROLE, data);

          if (successful) {
            this.$toasted.global.general_success({
              message: 'Role updated',
            });

            await this.$store.dispatch(GET_ORGANISATION_ROLES);
            this.closeModal();
          }
        } catch (error) {
          console.error(error);

          this.$toasted.global.general_error({
            message: 'Ups, something went wrong, please try again later.',
          });
        }

        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .group-body {
    display: grid;
    row-gap: 24px;
    column-gap: 16px;

    .form-group {
      width: 100%;
      margin-bottom: 0px;

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 8px;

        font-weight: 500;
        line-height: 24px;

        .label {
          display: flex;
          gap: 8px;
          align-items: center;

          font-size: 14px;
        }

        input,
        select {
          padding: 8px 16px;

          border: 2px solid $borderGrey;
          border-radius: 8px;
          font-size: 16px;
        }
      }
    }

    .description {
      grid-column: 1/3;
    }
  }
</style>
