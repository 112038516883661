export enum FileUploadStatus {
  Uploading,
  PendingProcess,
  Processing,
  Completed,
  Failed,
}

export interface FileUploadOption {
  file: File;
  status: FileUploadStatus | null;
  percent: number;
}

export interface TemporaryPlaylistItem {
  created: string;
  created_by: string;
  last_modified: string;
  modified_by: string;
  item_id: string;
  item_url: string;
  item_resolution_width: number | null;
  item_resolution_height: number | null;
  item_priority: number | null;
  item_type: string;
  mute: boolean;
  display_timer: number;
  schedule_start: string | null;
  schedule_end: string | null;
  size: number;
  last_pushed_dt: string;
  last_pushed_duration: number;
  organisation: string;
  name: string;
  parent: number | null;
  item_folder: number;
  is_active: boolean;
  playlist: string | null;
  thumbnail_url: string;
}

export interface TemporaryPDFPlaylistItem {
  file_id: number;
  type: string;
  mime: string;
  name: string;
  size: number;
  url: string;
  playlistitem: string;
  thumbnail_url: string[];
}

export interface Folder {
  id: number;
  name: string;
}
