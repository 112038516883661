<template>
  <div class="template">
    <div class="loader" :class="{ 'template-mode': showBrowserMode }" v-if="showLoader">
      <Loader />
    </div>

    <div v-else class="template-body" :class="{ 'template-mode': showBrowserMode }">
      <div class="folders" :class="{ 'template-mode': showBrowserMode }">
        <div class="folders-header section-header" :class="{ 'template-mode': showBrowserMode }">
          <div class="header-top">
            <div class="title">Folders</div>
            <i
              v-if="!showBrowserMode"
              class="material-icons-outlined align-middle button"
              @click="handleFolderAddModal"
            >
              add
            </i>
          </div>

          <div class="header-bottom">
            <SearchButton v-model="folderSearchTerm" />
          </div>
        </div>

        <div class="folders-body scrollbar" :class="{ 'template-mode': showBrowserMode }">
          <FolderItem
            :totals="templatesWithoutFolder"
            :isFolder="false"
            :active="selectedFolder === null"
            :browserMode="showBrowserMode"
            @select-folder="selectPath"
          />

          <FolderItem
            v-for="folder in folders"
            :key="folder.full_path"
            :folder="folder"
            isFolder
            :browserMode="showBrowserMode"
            :active="isFolderActive(folder)"
            :selectedFolder="selectedFolder"
            :selectedRoot="selectedRoot"
            @select-folder="selectPath"
            @onDelete="handleDeleteUpdateFolder"
            @onEdit="handleDeleteUpdateFolder"
          />
        </div>
      </div>

      <div class="items" :class="{ 'template-mode': showBrowserMode }">
        <div class="items-header section-header">
          <div class="header-top">
            <div class="title">Templates</div>
            <i
              v-if="!showBrowserMode"
              class="material-icons-outlined align-middle button"
              @click="showNewTemplateModal = true"
            >
              add
            </i>
          </div>

          <div class="header-bottom">
            <SearchButton v-model="templateSearchTerm" />

            <span
              v-if="!showBrowserMode"
              class="material-icons-outlined button inline-block cursor-pointer ml-auto"
              title="Multiple selection"
              :class="{ 'text-primary-danger': multipleTemplatesModeActive }"
              @click="handleMultipleTemplatesMode"
            >
              select_all
            </span>

            <ToggleSwitch v-else v-model="onlyTemplatesThatFitModal" text="Area Size" />
          </div>
        </div>

        <div
          v-if="filteredTemplates?.length"
          class="items-body scrollbar"
          :class="{ 'template-mode': showBrowserMode }"
        >
          <TemplateCard
            v-for="(template, index) in filteredTemplates"
            :key="index"
            :template="template"
            :selected="template.isSelected"
            showDetails
            :showFloatingMessage="doesTemplateFit(template)"
            @templateSelected="handleTemplateSelection(template)"
          >
            <template v-slot:message>
              <div class="floating-message">
                <i class="material-icons-outlined align-middle">open_in_full</i>
                <span>RIGHT SIZE</span>
              </div>
            </template>
          </TemplateCard>
        </div>

        <div v-else class="no-data">
          {{
            this.onlyTemplatesThatFitModal && this.showBrowserMode
              ? 'No templates that match area size.'
              : 'No templates'
          }}
        </div>
      </div>
    </div>

    <CreateTemplateFolderModal
      v-if="showAddFolderModal"
      :showModal="showAddFolderModal"
      @folder-created="getTemplateFolders"
      @close="showAddFolderModal = false"
      :parentId="selectedFolder?.id"
    />

    <CreateTemplateModal
      v-if="showNewTemplateModal"
      :showModal="showNewTemplateModal"
      @closeModal="showNewTemplateModal = false"
    />
  </div>
</template>

<script>
  import Loader from '@/components/common/Loader.vue';
  import SearchButton from '@/components/common/SearchButton.vue';
  import FolderItem from '@/components/templates/TemplateView/FolderItem.vue';
  import TemplateCard from '@/components/templates/TemplateCard.vue';
  import CreateTemplateFolderModal from '@/components/templates/CreateTemplateFolderModal.vue';
  import CreateTemplateModal from '@/components/templates/CreateTemplateModal.vue';
  import ToggleSwitch from '@/components/common/ToggleSwitch.vue';

  import {
    GET_TEMPLATES_FOLDER,
    HANDLE_TEMPLATE_SELECTION,
    RESET_SELECTIONS,
    SELECT_PATH,
    SET_TEMPLATE_SEARCH_TERM,
    TEMPLATE_FOLDER_SEARCH_REQUEST,
    TOGGLE_MULTIPLE_SELECTION,
  } from '@/store/actions/templatesBrowser';
  import { CONTENT_TYPE_FOLDER } from '@/constant/const';

  export default {
    name: 'TemplatesBrowser',

    components: {
      Loader,
      SearchButton,
      FolderItem,
      TemplateCard,
      ToggleSwitch,
      CreateTemplateModal,
      CreateTemplateFolderModal,
    },

    props: {
      playlist: {
        type: Object,
        default: () => ({}),
      },
      showBrowserMode: {
        type: Boolean,
        default: false,
      },
      requestStatus: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        showLoader: false,
        showAddFolderModal: false,
        showNewTemplateModal: false,
        onlyTemplatesThatFitModal: true,
      };
    },

    created() {
      this.$store.dispatch(RESET_SELECTIONS);
      this.getTemplateFolders();
    },

    computed: {
      folders() {
        return this.$store.getters.getFolderDetails;
      },

      selectedFolder() {
        return this.$store.state.templatesBrowser.selectedFolder;
      },

      multipleTemplates() {
        return this.$store.state.templatesBrowser.multipleTemplates;
      },

      templates() {
        return this.$store.state.templatesBrowser.templatesFolder;
      },

      filteredTemplates() {
        const templates = this.$store.getters.getFilteredTemplates;

        if (this.onlyTemplatesThatFitModal && this.showBrowserMode) {
          const { height, width } = this.getPlaylistSize();

          return templates.filter(
            (template) => template.settings.height === height && template.settings.width === width,
          );
        }

        return templates;
      },

      templatesWithoutFolder() {
        return this.templates.filter((item) => item.content_type !== CONTENT_TYPE_FOLDER).length;
      },

      selectedRoot() {
        return this.$store.state.templatesBrowser.selectedRoot;
      },

      loadingTemplateFolders() {
        return this.$store.state.templatesBrowser.showLoader;
      },

      multipleTemplatesModeActive() {
        return this.$store.state.templatesBrowser.multipleTemplatesModeActive;
      },

      multipleTemplateSelections() {
        return this.$store.state.templatesBrowser.multipleTemplateSelections;
      },

      folderSearchTerm: {
        get() {
          return this.$store.state.templatesBrowser.folderSearchTerm;
        },
        set(value = '') {
          this.$store.commit(TEMPLATE_FOLDER_SEARCH_REQUEST, value);
        },
      },

      layoutSize() {
        return this.isLayoutDesigner
          ? this.$store.getters.getPlayerLayoutSize
          : this.$store.getters.getScreenDimensions;
      },

      templateSearchTerm: {
        get() {
          return this.$store.state.templatesBrowser.templateSearchTerm;
        },
        set(value = '') {
          this.$store.commit(SET_TEMPLATE_SEARCH_TERM, value);
        },
      },

      isLayoutDesigner() {
        return this.$route.name === 'Layout';
      },
    },

    methods: {
      handleFolderAddModal() {
        this.showAddFolderModal = true;
      },

      selectPath(root, folder) {
        this.$store.dispatch(SELECT_PATH, { root, folder });
      },

      isTemplateSelected(template) {
        return this.$store.dispatch('isTemplateSelected', template);
      },

      handleMultipleTemplatesMode() {
        this.$store.dispatch(TOGGLE_MULTIPLE_SELECTION);
      },

      async getTemplateFolders(showLoader = true) {
        this.showLoader = showLoader;

        await this.$store.dispatch(GET_TEMPLATES_FOLDER, {
          filterByRole: true,
        });

        this.showLoader = false;
      },

      handleTemplateSelection(template) {
        this.$store.dispatch(HANDLE_TEMPLATE_SELECTION, template);
      },

      handleDeleteUpdateFolder() {
        this.getTemplateFolders(false);

        this.$store.dispatch(RESET_SELECTIONS);
      },

      isFolderActive(folder) {
        return folder.full_path === this.selectedRoot?.full_path;
      },

      getPlaylistSize() {
        const layoutChildren = this.isLayoutDesigner
          ? Object.values(this.$store.state.layoutDesigner.widgets)
          : this.$store.getters.widgetsArray;

        const layoutRotation = this.isLayoutDesigner
          ? this.$store.getters.getLayoutRotation
          : this.$store.getters.getPlayerRotation;

        const playlist = layoutChildren.find(
          (widget) => widget.object.playlist_id === this.playlist?.playlist_id,
        );

        const {
          heightPixels,
          widthPixels,
          height: playlistHeightPercentage,
          width: playlistWidthPercentage,
        } = playlist?.position[layoutRotation] || {};

        const { height: layoutHeight, width: layoutWidth } = this.layoutSize;

        if (!heightPixels && !widthPixels) {
          return {
            height: Math.round((playlistHeightPercentage / 100) * layoutHeight),
            width: Math.round((playlistWidthPercentage / 100) * layoutWidth),
          };
        }

        return { height: heightPixels, width: widthPixels };
      },

      doesTemplateFit(template) {
        const { height, width } = this.getPlaylistSize();

        return template.settings.height === height && template.settings.width === width;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;

    &.template-mode {
      height: 100%;
    }
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    font-size: 16px;
    line-height: 24px;
    color: $secondaryText;
  }

  .template {
    height: 100%;
    overflow: hidden;

    .template-body {
      padding: 48px 10px 0px 24px;
      display: flex;
      height: 100%;
      overflow: hidden;

      &.template-mode {
        padding: 0;
      }

      .folders,
      .items {
        width: 50%;
      }
      .items {
        border-left: 2px solid #f0f0f0;

        &.template-mode {
          border: none;
        }
      }

      .folders {
        display: flex;
        flex-direction: column;

        &.template-mode {
          margin-right: 24px;
        }

        .folders-header {
          box-sizing: border-box;
          padding: 0px 16px;

          &.template-mode {
            padding: 0;
            margin-right: 12px;
          }
        }

        .folders-body {
          flex: 1;
          box-sizing: border-box;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 28px 16px;

          &.template-mode {
            padding: 20px 16px 0 0;
          }
        }
      }

      .items {
        display: flex;
        flex-direction: column;

        .items-header {
          padding: 0px 16px;
        }

        .items-body {
          flex: 1;
          padding: 28px 16px;
          display: grid;
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          grid-template-columns: repeat(2, minmax(1px, 1fr));
          align-content: start;
          align-items: stretch;
          gap: 16px;
          justify-items: center;

          .item {
            height: fit-content;
            width: 100%;
          }

          &.template-mode {
            padding: 20px 16px 0;
          }
        }
      }
    }
  }

  .section-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 4px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    font-family: 'Whyte Inktrap';
    .header-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        color: #6a6b6a;
      }
    }

    .header-bottom {
      display: flex;
      align-items: center;
      vertical-align: middle;
      color: #6a6b6a;
      gap: 10px;
      width: 100%;
      justify-content: space-between;

      .search-button {
        height: 24px;
        flex: 1;
      }
    }
  }

  .floating-message {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 6px;
    margin-left: 6px;
    display: flex;
    gap: 4px;
    align-items: center;
    color: white;
    background-color: $primaryRed;
    padding: 4px 8px;
    border-radius: 48px;
    font-size: 12px;
    line-height: 14px;
    font-weight: light;

    i {
      font-size: 12px;
    }
  }
</style>
