<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isLoading="isLoading"
    title="Delete Confirmation"
    :okFunction="deleteUser"
  >
    <template v-slot:body>
      <div class="delete-description">
        Are you sure you want to delete <span>{{ user.name || user.username }}</span> user?
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  import { ADMIN_USER_DELETE, USER_DELETE } from '@/store/actions/user';
  import { ADMIN_ORGANISATIONS_GET } from '@/store/actions/organisation';

  export default {
    name: 'DeleteUserModal',

    components: { Modal },

    props: {
      user: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    computed: {
      isAdmin() {
        return this.$store.getters.isAdministrator;
      },
    },

    methods: {
      async deleteUser() {
        const DISPATCH_ACTION = this.isAdmin ? ADMIN_USER_DELETE : USER_DELETE;
        this.isLoading = true;

        try {
          await this.$store.dispatch(DISPATCH_ACTION, this.user.user_id);

          this.$toasted.global.general_success({
            message: 'User removed',
          });

          this.$emit('deleted');
          await this.$store.dispatch(ADMIN_ORGANISATIONS_GET);
          this.closeModal();
        } catch (error) {
          this.$toasted.global.general_success({
            message: String(error),
          });
        }

        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .delete-description {
    display: flex;
    gap: 4px;

    span {
      color: $primaryText;
      font-weight: 700;
    }
  }
</style>
