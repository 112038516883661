<template>
  <Modal
    :title="'Create Weather Widget'"
    :okButtonLabel="'Create'"
    :modalStyles="{ width: '500px' }"
    :okFunction="createWeatherWidget"
    :disabled="disableOkButton"
    @close="$emit('closeModal')"
    @closeModal="closeModal"
  >
    <template #body>
      <div class="group-body">
        <div class="form-group">
          <label class="label" for="widgetName">
            <div>Widget Name</div>

            <input
              type="text"
              name="widgetName"
              id="widgetName"
              required
              v-model="name"
              placeholder="Set widget's name"
              maxlength="40"
            />
          </label>
        </div>

        <div class="form-group">
          <label class="label" for="displayType">
            <div>Display Type</div>

            <select v-model="displayType" id="displayType">
              <option :value="WEATHER_DISPLAY_TYPE.NOW.value">Current Weather</option>
              <option :value="WEATHER_DISPLAY_TYPE.HOURS.value">Hourly Forecast</option>
              <option :value="WEATHER_DISPLAY_TYPE.DAYS.value">Daily Forecast</option>
            </select>
          </label>
        </div>

        <div v-if="!location" class="form-group">
          <div class="location-container">
            <label class="label" for="loc-search">
              <div>Location</div>
              <LocationPicker @updateLocation="updateLocation" />
            </label>
          </div>
        </div>

        <div v-else class="form-group settings-input mb-0 bb-0">
          <label for="template-name">Location</label>

          <input
            class="form-control"
            type="text"
            id="template-name"
            placeholder="Name"
            maxlength="30"
            v-model="location.label"
          />

          <div v-show="location" class="remove-button" @click="location = null">
            Change Location
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import LocationPicker from '@/components/apps/weather/LocationPicker.vue';
  import Modal from '@/components/common/Modal.vue';

  import { WEATHER_DISPLAY_TYPE } from '@/constant/weatherApp';

  export default {
    name: 'CreateWeatherWidgetModal',

    components: {
      LocationPicker,
      Modal,
    },

    data() {
      return {
        name: 'Weather Widget',
        displayType: WEATHER_DISPLAY_TYPE.NOW.value,
        location: null,
        WEATHER_DISPLAY_TYPE,
      };
    },

    mounted() {
      this.location = null;
      this.displayType = WEATHER_DISPLAY_TYPE.NOW.value;
    },

    computed: {
      disableOkButton() {
        return this.name.length < 3 || !this.location;
      },
    },

    methods: {
      createWeatherWidget() {
        const data = {
          name: this.name,
          displayType: this.displayType,
          location: this.location,
        };

        this.$emit('createWidget', data);
        this.closeModal();
      },

      closeModal() {
        this.$emit('closeModal');
      },

      updateLocation(data) {
        this.location = data;

        if (!this.name) {
          this.name = data.name;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .group-body {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;

    margin-right: -16px;
    padding-right: 16px;

    .form-group {
      width: 100%;
      margin-bottom: 0px;
      max-width: 480px;

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 8px;

        font-weight: 500;
        line-height: 24px;

        .label {
          display: flex;
          gap: 8px;
          align-items: center;

          font-size: 14px;
        }

        input,
        select {
          padding: 8px 16px;

          border: 2px solid $borderGrey;
          border-radius: 8px;
          font-size: 16px;
        }
      }
    }
  }

  .location-container {
    width: 100%;
  }

  .remove-button {
    width: 100%;
    text-align: right;

    &:hover {
      cursor: pointer;
      color: $primaryRed;
    }
  }
</style>
