<template>
  <OnClickOutside class="search-container" @trigger="manageClickOutside">
    <div class="search-button" :class="{ isOpen }">
      <i
        v-if="!isOpen"
        data-v-3c78ec44=""
        class="material-icons-outlined align-middle icon"
        @click="open"
      >
        search
      </i>
      <div v-if="isOpen" class="search-box">
        <input
          type="text"
          ref="inputField"
          :value="value"
          v-auto-focus
          @input="(e) => $emit('input', e.target.value)"
        />

        <span v-if="value === ''" class="material-icons-outlined align-middle icon" @click="close">
          search
        </span>

        <span v-else class="material-icons-outlined align-middle icon" @click="reset">close</span>
      </div>
    </div>
  </OnClickOutside>
</template>

<script>
  import Vue from 'vue';
  import { OnClickOutside } from '@vueuse/components';

  Vue.directive('auto-focus', {
    inserted: function (el) {
      el.focus();
    },
  });

  export default {
    name: 'SearchButton',

    components: {
      OnClickOutside,
    },

    props: {
      value: {
        type: String,
        default: '',
      },
    },

    emits: ['input'],

    data() {
      return {
        isOpen: false,
      };
    },

    methods: {
      open() {
        this.isOpen = true;
      },
      close() {
        this.isOpen = false;
      },
      manageClickOutside() {
        if (!this.value) this.close();
      },
      reset() {
        this.$emit('input', '');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .search-container {
    flex: 1;
  }

  .search-button {
    line-height: 24px;
    height: 30px;

    .icon {
      cursor: pointer;
      user-select: none;
    }

    &.isOpen {
      flex: 1;
    }
    .search-box {
      display: flex;
      align-items: center;
      border-bottom: 2px solid #d9d9d9;

      input {
        display: block;
        flex: 1;
        border: 0;
        font-size: 14px;
        font-family: 'Poppins';
        background-color: transparent;
        padding: 0;
        margin: 0;
        line-height: 14px;
        &:focus {
          outline: none;
        }
      }

      .icon {
        color: #333332;
      }
    }
  }
</style>
