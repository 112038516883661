<template>
  <div class="sidebar-section">
    <div v-if="!hideReturn" class="section-title title">
      <i class="material-icons-outlined align-middle button" @click="$emit('goBackButton')">
        arrow_back
      </i>

      <span class="title"> Back </span>
    </div>

    <div v-if="!hideReturn" class="section-title playlist-title">
      <div class="playlist-name">
        <i class="material-icons-outlined align-middle">playlist_play</i>

        <span class="title">
          Playlist <b>{{ playlist.name }}</b>
        </span>
      </div>

      <div class="dropdown">
        <i
          class="material-icons align-middle more-buttons dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          more_horiz
        </i>
        <div class="dropdown-menu dropdown-menu-right">
          <button type="button" class="dropdown-item" @click="displayHiddenItems">
            <i class="material-icons align-middle">{{
              showHiddenItems ? 'visibility_off' : 'visibility'
            }}</i>
            {{ showHiddenItems ? 'Hide' : 'Show' }} Hidden Items
            {{ isGroupOverride ? '(Group)' : '(Screen)' }}
          </button>
        </div>
      </div>
    </div>

    <div class="content-section">
      <div class="playlist-items-container">
        <draggable
          v-model="playlistItems"
          v-if="!playlistItemsLoading"
          @update="handleItemsReordered"
        >
          <div
            v-for="(playlistItem, index) in playlistItems"
            :key="index"
            class="playlist-item-container"
            :class="{
              selected:
                selectedPlaylistItem && playlistItem.item_id === selectedPlaylistItem.item_id,
            }"
            @click="selectItem(playlistItem)"
          >
            <div
              class="playlist"
              :title="`${getPlaylistItemDisplayName(playlistItem)} (${playlistItem.item_type}) ${
                isItemOverriddenByGroup(playlistItem)
                  ? '\nLocked - Item was added or modified at group level'
                  : ''
              }`"
            >
              <PlaylistItemImage
                :playlistItem="playlistItem"
                @openItemModal="setPlaylistPreviewItem"
              />

              <div class="playlist-body">
                <PlaylistItemAdditionalInfo :playlistItem="playlistItem" />
                <div class="playlist-name">
                  <PlaylistItemIcon :itemType="playlistItem.item_type" />

                  <span> {{ getPlaylistItemDisplayName(playlistItem) }} </span>
                </div>

                <div class="details">
                  <PlaylistItemStatus
                    :itemStatus="itemScheduleStatus(playlistItem)"
                    :hasActivePriorityItem="hasActivePriorityItem"
                    :playlistItem="playlistItem"
                  />

                  <div class="time" @click="openTimeDurationModal(playlistItem)">
                    <i class="material-icons-outlined align-middle">timer</i>
                    {{ playlistTotalTime(playlistItem.display_timer) }}
                  </div>

                  <div v-if="isItemOverriddenByGroup(playlistItem)">
                    <i class="material-icons-outlined align-middle right"> lock </i>
                  </div>
                  <div v-else class="dropdown">
                    <i
                      class="material-icons align-middle more-buttons dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      more_horiz
                    </i>
                    <div class="role-options dropdown-menu dropdown-menu-right">
                      <button
                        v-if="playlistItem.item_type.includes('app/template')"
                        type="button"
                        class="dropdown-item"
                        @click="openTemplateOverride(playlistItem)"
                      >
                        <i class="material-icons-outlined align-middle">edit</i>
                        Edit template's content
                      </button>

                      <button
                        v-if="playlistItem.item_type.includes('local-template')"
                        type="button"
                        class="dropdown-item"
                        @click="editTileOnTemplateEditor(playlistItem)"
                        :class="{ disabled: !isLocalTemplateAndEditable(playlistItem) }"
                      >
                        <i class="material-icons-outlined align-middle">format_shapes</i>
                        Edit Template
                        <i
                          v-if="!isLocalTemplateAndEditable(playlistItem)"
                          class="material-icons-outlined align-middle right"
                        >
                          lock
                        </i>
                      </button>

                      <button
                        type="button"
                        class="dropdown-item"
                        @click="openEditItemNameModal(playlistItem)"
                      >
                        <i class="material-icons-outlined align-middle">edit_note</i>
                        Edit item name
                      </button>
                      <button
                        v-if="playlistItem.item_type.includes('template')"
                        type="button"
                        class="dropdown-item"
                        @click="
                          playlistItem.item_type.includes('local-template')
                            ? duplicateLocalTemplate(playlistItem.template)
                            : duplicateTemplate(playlistItem.template)
                        "
                      >
                        <i class="material-icons-outlined align-middle">content_copy</i>
                        Duplicate item
                      </button>

                      <button
                        type="button"
                        class="dropdown-item"
                        @click="openSchedulingModal(playlistItem)"
                      >
                        <i class="material-icons-outlined align-middle">today</i>
                        Schedule
                      </button>

                      <button
                        type="button"
                        class="dropdown-item"
                        @click="openPriorityModal(playlistItem)"
                      >
                        <div class="priority-icon"></div>
                        Set Priority
                      </button>

                      <button
                        v-if="selectedPlaylistItemType === 'audio'"
                        type="button"
                        class="dropdown-item"
                        @click="openAudioModal(playlistItem)"
                      >
                        <i class="material-symbols-outlined">volume_up</i>
                        Audio
                      </button>

                      <button
                        v-if="['edit', 'delete'].includes(playlistItem.override?.action)"
                        type="button"
                        class="dropdown-item"
                        @click="restoreWidget(playlistItem)"
                      >
                        <i class="material-icons align-middle">restore</i>
                        Restore widget
                        {{ isGroupOverride ? '(Group)' : '(Screen)' }}
                      </button>

                      <button
                        v-if="playlistItem.override?.action === 'add'"
                        type="button"
                        class="dropdown-item"
                        @click="removePlaylistItem(playlistItem)"
                      >
                        <i class="material-icons align-middle">remove</i>
                        Remove Item
                      </button>

                      <button
                        v-if="!playlistItem.override"
                        type="button"
                        class="dropdown-item"
                        @click="hidePlaylistItems(playlistItem)"
                      >
                        <i class="material-icons-outlined align-middle">visibility_off</i>
                        Hide Item
                        {{ isGroupOverride ? '(Group)' : '(Screen)' }}
                      </button>

                      <template
                        v-if="['image', 'video'].includes(getSimpleType(playlistItem.item_type))"
                      >
                        <div class="dropdown-divider"></div>

                        <button
                          v-for="mode in imageModes"
                          :key="mode.value"
                          type="button"
                          class="dropdown-item"
                          @click="
                            setPlaylistItemConfig({
                              assocId: playlistItem.assoc_id,
                              overrideId: playlistItem.override.id,
                              imageMode: mode.value,
                            })
                          "
                        >
                          <i class="material-icons-outlined align-middle">{{ mode.modeIcon }}</i>
                          {{ mode.label }}
                        </button>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <div class="my-3" v-else>
          <Loader />
        </div>

        <div v-if="hiddenPlaylistItems.length">
          <div class="section-title">
            <i class="material-icons-outlined align-middle">visibility_off</i>
            <span> Playlist's Hidden Items </span>
          </div>
          <div
            v-for="(playlistItem, index) in hiddenPlaylistItems"
            :key="index"
            class="playlist-item-container"
          >
            <div class="playlist" title="Removed Item">
              <PlaylistItemImage
                :playlistItem="playlistItem"
                @openItemModal="setPlaylistPreviewItem"
              />

              <div class="playlist-body">
                <div class="playlist-name">
                  <PlaylistItemIcon :itemType="playlistItem.item_type" />

                  <span> {{ getPlaylistItemDisplayName(playlistItem) }} </span>
                </div>

                <div class="details">
                  <div class="date">
                    <i class="material-icons-outlined align-middle text-secondary">
                      visibility_off
                    </i>
                  </div>

                  <div v-if="isItemOverriddenByGroup(playlistItem)">
                    <i class="material-icons-outlined align-middle right"> lock </i>
                  </div>

                  <div v-else class="dropdown">
                    <i
                      class="material-icons align-middle more-buttons dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      more_horiz
                    </i>
                    <div class="role-options dropdown-menu dropdown-menu-right">
                      <button
                        v-if="['remove'].includes(playlistItem.override?.action)"
                        type="button"
                        class="dropdown-item"
                        @click="restoreWidget(playlistItem)"
                      >
                        <i class="material-icons align-middle">restore</i>
                        Restore widget
                        {{ isGroupOverride ? '(Group)' : '(Screen)' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PlaylistContentModal
      @closeModal="showPlaylistContentModal = false"
      @openContentBrowser="openContentBrowser"
      @openUploadModal="openFileUploadPopUp"
      @openAppsModal="openAppsModal"
      @openTemplateModal="showTemplateFolderBrowserModal = true"
      @openLinkModal="openLinkModal"
      @createNewTemplate="addNewTemplate"
      v-if="showPlaylistContentModal"
    />

    <FileUploadModal
      v-if="showFileUploadPopUp"
      :folders="mediaFolders"
      :selectedFolder="null"
      @close="(data) => handleCloseFileUploadModal(data)"
      @success="(data) => handleCloseFileUploadModal(data)"
      :allowMultiple="false"
      :awaitProcessing="false"
    />

    <ContentBrowserModal
      ref="content_browser_modal"
      allow-multiple-item-select
      :isSaving="isSavingContent"
      @submitted="[addPlaylistItems($event), closeModal()]"
      :title="contentBrowserTitle"
      :filterItemTypes="contentBrowserTypes"
    />

    <PlaylistItemDurationModal
      v-if="showPlaylistDurationModal"
      @closeModal="showPlaylistDurationModal = false"
      :playlistItem="selectedPlaylistItem"
      @setSchedule="setPlaylistItemDuration"
    />

    <Scheduler
      v-if="showSchedulingModal"
      :playlistItem="selectedPlaylistItem"
      @closeModal="showSchedulingModal = false"
      @setSchedule="setPlaylistItemConfig"
    />

    <PlaylistItemDisplayPriority
      v-if="showItemPriorityModal"
      :playlistItem="selectedPlaylistItem"
      @closeModal="showItemPriorityModal = false"
      @setPriority="setPlaylistItemConfig"
    />

    <PlaylistItemAudioModal
      v-if="showItemAudioModal"
      :playlistItem="selectedPlaylistItem"
      @close="showItemAudioModal = false"
      @save="setPlaylistItemAudio"
    />

    <TemplateFolderBrowserModal
      v-if="showTemplateFolderBrowserModal"
      :playlist="playlist"
      @closeModal="showTemplateFolderBrowserModal = false"
      @createTile="addTileAsItem"
    />

    <PlaylistItemModal
      v-if="previewItem"
      :previewItem="previewItem"
      :playlist="playlist"
      @closeModal="previewItem = null"
    />

    <AddLinkModal
      v-if="showLinkModal"
      :playlist="playlist"
      isOverride
      @closeModal="showLinkModal = false"
      @addLayerToPlaylist="addLayerToPlaylist"
    />

    <EditSingleItem
      v-if="showEditItemNameModal"
      title="Edit Playlist Item Name"
      label="Name"
      placeholder="Edit Name"
      :field="playlistItemName"
      :validation="maxLength(60)"
      @field-modified="handleEditName"
      @closeModal="closeEditItemModal"
    />
  </div>
</template>

<script>
  import moment from 'moment';
  import draggable from 'vuedraggable';
  import _ from 'lodash';

  import PlaylistContentModal from '@/components/playlist/PlaylistContentModal.vue';
  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import PlaylistItemDurationModal from '@/components/screens/PlaylistItemDurationModal.vue';
  import Loader from '@/components/common/Loader.vue';
  import Scheduler from '@/components/common/scheduler/Scheduler.vue';
  import PlaylistItemDisplayPriority from '@/components/common/PlaylistItemDisplayPriority.vue';
  import PlaylistItemModal from '@/components/common/PlaylistItemModal.vue';
  import PlaylistItemImage from '@/components/screens/PlaylistItemImage.vue';
  import PlaylistItemStatus from '@/components/screens/PlaylistItemStatus.vue';
  import PlaylistItemIcon from '@/components/screens/PlaylistItemIcon.vue';
  import AddLinkModal from '@/components/playlist/AddLinkModal.vue';
  import TemplateFolderBrowserModal from '@/components/layoutDesigner/editor/TemplateFolderBrowserModal.vue';
  import EditSingleItem from '@/components/common/EditSingleItem.vue';
  import { getSimpleType, validationsMixin } from '@/helpers/mixins';
  import FileUploadModal from '@/components/common/fileUploadModal/FileUploadModal.vue';

  import {
    PLAYLIST_ITEM_DELETE,
    GET_PLAYLIST_ITEMS,
    PLAYLIST_ITEM_UPDATE,
  } from '@/store/actions/playlist';
  import { PLAYLIST_ITEM_CREATE } from '@/store/actions/playlist';
  import { SET_PLAYLIST_UPDATE_STATE } from '@/store/actions/player';

  import { SCREENS_OVERRIDE_ACTIONS } from '@/constant/screens';
  import {
    DAYS_OF_THE_WEEK,
    PLAYLIST_SCHEDULE_STATUS,
    SCHEDULE_TYPES,
    DISPLAY_PRIORITY,
    CONTENT_TYPE_LOCAL_TEMPLATE,
  } from '@/constant/const';
  import { transformSecondsToTimer } from '@/helpers/utils';
  import { simpleTypeMixin } from '@/helpers';

  import {
    apiCreateTileAddOverride,
    apiGetGroupTileChildren,
    apiGetScreenTileChildren,
    apiCreateTileFromTileAddOverride,
  } from '@/api/tiles';
  import {
    apiCreateScreenGroupPlaylistItemOverride,
    apiCreateScreenPlaylistItemOverride,
    apiDeleteScreenGroupPlaylistItemOverride,
    apiDeleteScreenPlaylistItemOverride,
    apiUpdateScreenGroupPlaylistItemOverride,
    apiUpdateScreenPlaylistItemOverride,
  } from '@/api/playlist';
  import {
    apiCreateNewTemplateAsPlaylistOverride,
    apiGetScreenGroupTemplateChildren,
    apiGetScreenTemplateChildren,
  } from '@/api/templates';
  import PlaylistItemAudioModal from '@/components/common/PlaylistItemAudioModal.vue';
  import { IMAGE_MODE_SETTINGS, WIDGET_TYPES } from '@/models/layoutDesigner';
  import PlaylistItemAdditionalInfo from '@/components/common/PlaylistItemAdditionalInfo.vue';
  import { CONTENT_REQUEST } from '@/store/actions/content';

  export default {
    name: 'PlaylistItems',
    mixins: [simpleTypeMixin, validationsMixin],
    emits: ['contentChange'],

    components: {
      PlaylistItemAudioModal,
      PlaylistContentModal,
      ContentBrowserModal,
      draggable,
      Loader,
      PlaylistItemDurationModal,
      Scheduler,
      PlaylistItemModal,
      PlaylistItemImage,
      PlaylistItemIcon,
      AddLinkModal,
      TemplateFolderBrowserModal,
      PlaylistItemDisplayPriority,
      EditSingleItem,
      PlaylistItemStatus,
      PlaylistItemAdditionalInfo,
      FileUploadModal,
    },

    props: {
      playlist: {
        type: Object,
        default: () => ({}),
      },

      hideReturn: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        showPlaylistDurationModal: false,
        showDeleteScreenModal: false,
        showSchedulingModal: false,
        showItemPriorityModal: false,
        showItemAudioModal: false,
        showPlaylistContentModal: false,
        showTemplateFolderBrowserModal: false,
        showLinkModal: false,
        showHiddenItems: false,

        previewItem: null,
        selectedPlaylistItem: null,
        isSavingContent: false,
        playlistItemsLoading: false,
        playlistItems: [],
        hiddenPlaylistItems: [],
        contentBrowserTitle: '',
        contentBrowserTypes: [],
        PLAYLIST_SCHEDULE_STATUS,
        DISPLAY_PRIORITY,
        showEditItemNameModal: false,
        SCHEDULE_TYPES,
        showFileUploadPopUp: false,

        imageModes: IMAGE_MODE_SETTINGS,
      };
    },

    mounted() {
      this.getPlaylistItems();
      this.showHiddenItems = false;
    },

    watch: {
      playlist() {
        this.getPlaylistItems();
      },
    },

    computed: {
      organisation() {
        return this.$store.state.organisation.organisation || this.$store.state.auth.organisation;
      },

      isGroupOverride() {
        return this.$route.params.groupId;
      },

      rootCategory() {
        return this.$route.query.rootCategory;
      },

      layoutId() {
        return this.$route.params.layoutId;
      },

      playlistItemName() {
        if (this.selectedPlaylistItem) {
          return this.selectedPlaylistItem.local_name
            ? this.selectedPlaylistItem.local_name
            : this.selectedPlaylistItem.item_type.includes('template')
              ? this.selectedPlaylistItem.template.name
              : this.selectedPlaylistItem.name;
        }
      },

      selectedPlaylistItemType() {
        if (!this.selectedPlaylistItem) return null;
        return getSimpleType(this.selectedPlaylistItem.item_type);
      },

      hasActivePriorityItem() {
        for (const playlistItem of this.playlistItems) {
          const status = this.itemScheduleStatus(playlistItem);
          if (
            status === PLAYLIST_SCHEDULE_STATUS.ACTIVE &&
            playlistItem.display_priority === DISPLAY_PRIORITY.PRIORITY
          ) {
            return true;
          }
        }
        return false;
      },

      groupId() {
        return this.$route.params?.groupId;
      },

      mediaFolders() {
        return this.$store.getters.getAllContentFolders;
      },
    },

    methods: {
      openEditItemNameModal(item) {
        this.selectedPlaylistItem = item;
        this.showEditItemNameModal = true;
      },

      closeEditItemModal() {
        this.showEditItemNameModal = false;
      },

      async handleEditName(newItemName) {
        await this.setPlaylistItemConfig({
          assocId: this.selectedPlaylistItem.assoc_id,
          overrideId: this.selectedPlaylistItem.override?.id,
          local_name: newItemName,
        });

        this.$emit('contentChange');
        this.closeEditItemModal();
      },

      getPlaylistItemDisplayName(playlistItem) {
        if (playlistItem.local_name) {
          return playlistItem.local_name;
        }
        return playlistItem.item_type.includes('template')
          ? playlistItem.template.name
          : playlistItem.name;
      },

      checkScreenOverride(playlistItem) {
        return !!playlistItem.override?.screen;
      },

      checkGroupOverride(playlistItem) {
        return !!playlistItem.override?.screen_group;
      },

      async getPlaylistItems(withLoading = true) {
        this.playlistItemsLoading = withLoading;
        const response = await this.$store.dispatch(GET_PLAYLIST_ITEMS, {
          playlist_id: this.playlist.playlist_id,
          showHiddenItems: this.showHiddenItems,
        });

        const responseItems = response.data.overridden_items
          ? response.data.overridden_items
          : response.data.items;
        this.playlistItems = responseItems.filter(
          (item) => item.override?.action !== SCREENS_OVERRIDE_ACTIONS.REMOVE,
        );
        this.hiddenPlaylistItems = responseItems.filter(
          (item) => item.override?.action === SCREENS_OVERRIDE_ACTIONS.REMOVE,
        );
        this.playlistItemsLoading = false;
      },

      playlistTotalTime(seconds) {
        return transformSecondsToTimer(seconds).substring(3);
      },

      itemScheduleStatus(playlistItem) {
        if (!playlistItem.scheduler || playlistItem.scheduler.available === SCHEDULE_TYPES.ALWAYS) {
          return PLAYLIST_SCHEDULE_STATUS.ACTIVE;
        }

        const { type, timeToTime, daysTime, daysTimes, daysHourType, days } =
          playlistItem.scheduler;

        const fromDate = timeToTime.from ? moment(timeToTime.from).toDate() : null;
        const toDate = timeToTime.to ? moment(timeToTime.to).toDate() : null;

        const isFromDateValid = fromDate ? moment(fromDate).isBefore() : true;
        const isToDateValid = toDate ? moment(toDate).isAfter() : true;

        if (type === SCHEDULE_TYPES.TIME_TO_TIME && isFromDateValid) {
          return isToDateValid
            ? PLAYLIST_SCHEDULE_STATUS.ACTIVE
            : PLAYLIST_SCHEDULE_STATUS.FINISHED;
        } else if (type === SCHEDULE_TYPES.TIME_TO_TIME) {
          return PLAYLIST_SCHEDULE_STATUS.SCHEDULED;
        } else if (type === SCHEDULE_TYPES.ADVANCED && !isFromDateValid) {
          return PLAYLIST_SCHEDULE_STATUS.SCHEDULED;
        } else if (type === SCHEDULE_TYPES.ADVANCED && !isToDateValid) {
          return PLAYLIST_SCHEDULE_STATUS.FINISHED;
        }

        const now = moment();
        const currentDay = DAYS_OF_THE_WEEK[now.isoWeekday() - 1];
        const midnight = now.clone().startOf('day');
        const currentMinute = now.diff(midnight, 'minutes');

        const isValidDay =
          (daysHourType === SCHEDULE_TYPES.SAME_HOURS && days.length === 0) ||
          days.includes(currentDay);

        if (!isValidDay) {
          return PLAYLIST_SCHEDULE_STATUS.INACTIVE;
        }

        if (daysHourType === SCHEDULE_TYPES.SAME_HOURS) {
          return currentMinute >= daysTime[0] && currentMinute <= daysTime[1]
            ? PLAYLIST_SCHEDULE_STATUS.ACTIVE
            : PLAYLIST_SCHEDULE_STATUS.INACTIVE;
        }

        const currentRangeOfMinutes = daysTimes[currentDay];

        return currentMinute >= currentRangeOfMinutes[0] &&
          currentMinute <= currentRangeOfMinutes[1]
          ? PLAYLIST_SCHEDULE_STATUS.ACTIVE
          : PLAYLIST_SCHEDULE_STATUS.INACTIVE;
      },

      async hidePlaylistItems(playlistItem) {
        try {
          await this.addOverrideItems([playlistItem], SCREENS_OVERRIDE_ACTIONS.REMOVE);

          this.getPlaylistItems(false);
          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);

          this.$emit('contentChange');

          this.$toasted.global.general_success({
            message: 'Item removed',
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: `Delete failed: ${error}`,
          });
        }
      },

      openPlaylistContentModal() {
        this.showPlaylistContentModal = true;
      },

      // Add Item to playlist
      async addPlaylistItems(objects) {
        try {
          if (!objects?.length) return;

          this.isSavingContent = true;
          let createItems = [];

          objects.forEach((item, index) => {
            let item_type = this.getObjectType(item.item_type);

            createItems.push({
              item_id: item.item_id || item.playlistitem,
              item_type,
              item_priority: this.playlistItems.length + index + 1,
              item_name: item.name || item.item_name,
            });
          });

          await this.addOverrideItems(createItems);
          await this.getPlaylistItems();

          this.isSavingContent = false;
          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);

          this.$emit('contentChange');

          // close modal
          this.showPlaylistContentModal = false;
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }
      },

      closeModal() {
        this.$refs.content_browser_modal.close();
      },

      createNewItems(items) {
        return this.$store.dispatch(PLAYLIST_ITEM_CREATE, {
          playlist_id: this.playlist.playlist_id,
          itemList: items,
        });
      },

      deleteItem(playlistItem) {
        const deleteArgs = {
          playlist_id: this.playlist.playlist_id,
          assoc_id: playlistItem.assoc_id,
        };

        return this.$store.dispatch(PLAYLIST_ITEM_DELETE, deleteArgs);
      },

      async restoreWidget(playlistItem) {
        try {
          const { screen, screen_group, id, playlist } = playlistItem.override;

          !!screen_group
            ? await apiDeleteScreenGroupPlaylistItemOverride(screen_group, playlist, id)
            : await apiDeleteScreenPlaylistItemOverride(screen, playlist, id);

          this.getPlaylistItems(false);
          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);

          this.$emit('contentChange');

          this.$toasted.global.general_success({
            message: 'Item Restored',
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: `Restoring failed: ${error}`,
          });
        }
      },

      async removePlaylistItem(playlistItem) {
        try {
          const { screen, screen_group, id, playlist } = playlistItem.override;

          !!screen_group
            ? await apiDeleteScreenGroupPlaylistItemOverride(screen_group, playlist, id)
            : await apiDeleteScreenPlaylistItemOverride(screen, playlist, id);

          this.getPlaylistItems(false);
          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);

          this.$emit('contentChange');

          this.$toasted.global.general_success({
            message: 'Item Removed',
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: `Remove failed: ${error}`,
          });
        }
      },

      async updateOverride(playlistItem, newData, newAction) {
        const { screen_group, screen, id, playlist, item, action } = playlistItem.override;

        const updateData = {
          override_id: id,
          playlist,
          item,
          action: newAction ? newAction : action,
          config: {
            ...playlistItem.override.config,
            ...newData,
          },
          screen_group,
          screen,
        };

        !!screen_group
          ? await apiUpdateScreenGroupPlaylistItemOverride([updateData])
          : await apiUpdateScreenPlaylistItemOverride([updateData]);
      },

      async saveItemsOrder(items = [], emitUpdate = true) {
        let allItems = [...this.playlistItems, ...items];
        let updateItems = [];

        allItems.forEach((item, index) => {
          updateItems.push({
            ...item,
            assoc_id: item.assoc_id,
            item_name: item.name,
            item_priority: index + 1,
          });
        });

        await this.$store.dispatch(PLAYLIST_ITEM_UPDATE, {
          playlist_id: this.playlist.playlist_id,
          itemList: updateItems,
        });

        if (emitUpdate) {
          this.$emit('reloadItems');
        }

        this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);
      },

      addOverrideItems(items, action = SCREENS_OVERRIDE_ACTIONS.ADD, overrideConfig = {}) {
        const { groupId, screenId } = this.$store.state.player;

        const overrideItems = items.map((item, index) => {
          const config = {
            display_timer: 15,
            item_priority: this.playlistItems.length + index + 1,
            ...overrideConfig,
          };

          // init default value for audio widget
          if (item.item_type === WIDGET_TYPES.AUDIO) {
            config.mute = false;
            config.volume = 1;
          }

          return {
            action,
            screen: screenId,
            screen_group: parseInt(groupId),
            playlist: this.playlist.playlist_id,
            item: item.item_id,
            config: config,
            layout: this.layoutId,
          };
        });

        return !!groupId
          ? apiCreateScreenGroupPlaylistItemOverride(overrideItems)
          : apiCreateScreenPlaylistItemOverride(overrideItems);
      },

      getObjectType(type) {
        if (type.includes('app')) {
          return type;
        }

        return this.getSimpleType(type);
      },

      openContentBrowser() {
        this.contentBrowserTitle = '';
        this.contentBrowserTypes = ['image', 'video', 'audio', 'pdf'];
        this.$refs.content_browser_modal.open();
      },

      openAppsModal() {
        this.contentBrowserTitle = 'Browse Apps';
        this.contentBrowserTypes = ['app'];
        this.$refs.content_browser_modal.open();
      },

      getEncodedURL(baseUrl, uri = null) {
        return `${baseUrl}/${encodeURI(uri)}`;
      },

      selectItem(selectedItem) {
        this.selectedPlaylistItem = selectedItem;
      },

      openTimeDurationModal(item) {
        if (
          !this.isLocalTemplateAndEditable(item) ||
          (!!item.override?.screen_group &&
            parseInt(item.override?.screen_group) !== parseInt(this.groupId))
        ) {
          return;
        }

        this.selectedPlaylistItem = item;
        this.showPlaylistDurationModal = true;
      },

      openSchedulingModal(item) {
        this.selectedPlaylistItem = item;
        this.showSchedulingModal = true;
      },

      openPriorityModal(item) {
        this.selectedPlaylistItem = item;
        this.showItemPriorityModal = true;
      },

      openAudioModal(item) {
        this.selectedPlaylistItem = item;
        this.showItemAudioModal = true;
      },

      openTemplateOverride(item) {
        this.selectedPlaylistItem = item;
        this.$emit('playlistsTemplateSidebar', item);
      },

      editTileOnTemplateEditor(playlistItem) {
        const { template: tile, override } = playlistItem;

        if (!this.isLocalTemplateAndEditable(playlistItem)) {
          return;
        }

        this.$router.push({
          name: 'Template',
          query: {
            tile: tile.template_id,
            screen: this.$store.state.player.screenId,
            group: this.$store.state.player.groupId,
            playlist: this.playlist.playlist_id,
            category: this.playlist.category,
            layout: this.layoutId,
            rootCategory: this.rootCategory,
            name: override?.config.local_name,
          },
          params: {
            templateId: tile.template_id,
          },
        });
      },

      displayHiddenItems() {
        this.showHiddenItems = !this.showHiddenItems;
        this.getPlaylistItems(false);
      },

      async duplicateTemplate(template) {
        try {
          const { screenId, groupId } = this.$store.state.player;
          let response = null;

          if (this.isGroupOverride) {
            response = await apiGetScreenGroupTemplateChildren({
              groupId,
              playlistId: this.playlist?.playlist_id,
              templateId: template.template_id,
              layoutId: this.layoutId,
            });
          } else if (screenId) {
            response = await apiGetScreenTemplateChildren(
              template.template_id,
              screenId,
              this.playlist?.playlist_id,
            );
          }

          const templateOverrideIds = response?.data
            .filter((widget) => !!widget.object.override?.id)
            .map((widget) => widget.object.override.id);

          await this.addTileAsItem(template, !!response ? templateOverrideIds : []);

          this.$emit('contentChange');
        } catch (error) {
          console.log('duplicateTemplate ~ error:', error);

          this.$toasted.global.general_error({
            message: 'Template duplication failed',
          });
        }
      },

      async duplicateLocalTemplate(template) {
        try {
          const { screenId, groupId } = this.$store.state.player;
          let response = null;

          if (this.isGroupOverride) {
            response = await apiGetGroupTileChildren(
              template.template_id,
              groupId,
              this.playlist?.playlist_id,
              this.layoutId,
            );
          } else if (screenId) {
            response = await apiGetScreenTileChildren(
              template.template_id,
              screenId,
              this.playlist?.playlist_id,
            );
          }

          const templateOverrideIds = response?.data
            .filter((widget) => !!widget.object.override?.id)
            .map((widget) => widget.object.override.id);

          await apiCreateTileFromTileAddOverride({
            template_id: template.template_id,
            playlist: this.playlist.playlist_id,
            screen_group: this.isGroupOverride ? groupId : null,
            screen: this.isGroupOverride ? null : screenId,
            config: { display_timer: 15, item_priority: this.playlistItems.length + 1 },
            template_override_ids: templateOverrideIds,
            layout: this.layoutId,
          });

          await this.getPlaylistItems(false);
          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);

          this.$emit('contentChange');
        } catch (error) {
          console.log('duplicateLocalTemplate ~ error:', error);

          this.$toasted.global.general_error({
            message: 'Template duplication failed',
          });
        }
      },

      async setPlaylistItemDuration({ assocId, overrideId, duration }) {
        try {
          const playlistItem = this.playlistItems.find((item) =>
            item.assoc_id ? item.assoc_id === assocId : item.override.id === overrideId,
          );

          playlistItem.display_timer = Number(duration);

          this.playlistItems = [...this.playlistItems];

          if (!playlistItem.override) {
            await this.addOverrideItems([playlistItem], SCREENS_OVERRIDE_ACTIONS.EDIT, {
              display_timer: Number(duration),
              item_priority: playlistItem.item_priority,
            });
          } else {
            await this.updateOverride(playlistItem, {
              display_timer: Number(duration),
            });
          }

          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);
          this.getPlaylistItems(false);

          this.$emit('contentChange');
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }
      },

      async setPlaylistItemAudio({ assocId, overrideId, mute, volume }) {
        try {
          const playlistItem = this.playlistItems.find((item) =>
            item.assoc_id ? item.assoc_id === assocId : item.override.id === overrideId,
          );

          playlistItem.mute = mute;
          playlistItem.volume = volume;

          this.playlistItems = [...this.playlistItems];

          if (!playlistItem.override) {
            await this.addOverrideItems([playlistItem], SCREENS_OVERRIDE_ACTIONS.EDIT, {
              mute: mute,
              volume: volume,
              item_priority: playlistItem.item_priority,
            });
          } else {
            await this.updateOverride(playlistItem, {
              mute: mute,
              volume: volume,
            });
          }

          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);
          this.getPlaylistItems(false);

          this.$emit('contentChange');
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }
      },

      async setPlaylistItemConfig({
        assocId,
        overrideId,
        scheduler,
        priority,
        local_name,
        mute,
        imageMode,
      }) {
        try {
          const playlistItem = this.playlistItems.find((item) =>
            item.assoc_id ? item.assoc_id === assocId : item.override.id === overrideId,
          );

          if (scheduler) {
            playlistItem.scheduler = scheduler;
          }
          if (priority) {
            playlistItem.display_priority = priority;
          }
          if (local_name) {
            playlistItem.local_name = local_name;
          }
          if (mute !== null) {
            playlistItem.mute = mute;
          }
          if (imageMode) {
            playlistItem.imageMode = imageMode;
          }

          this.playlistItems = [...this.playlistItems];

          if (!playlistItem.override) {
            await this.addOverrideItems([playlistItem], SCREENS_OVERRIDE_ACTIONS.EDIT, {
              ...(scheduler && { scheduler: scheduler }),
              ...(priority && { display_priority: priority }),
              ...(local_name && { local_name: local_name }),
              ...(imageMode && { imageMode: imageMode }),
              item_priority: playlistItem.item_priority,
            });
          } else {
            await this.updateOverride(playlistItem, {
              ...(scheduler && { scheduler: scheduler }),
              ...(priority && { display_priority: priority }),
              ...(local_name && { local_name: local_name }),
              ...(imageMode && { imageMode: imageMode }),
            });
          }

          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);
          this.getPlaylistItems(false);

          this.$emit('contentChange');
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }
      },

      async handleItemsReordered() {
        try {
          await this.handleReorderOverride();

          this.$emit('playlistItemsUpdated');
          this.$emit('contentChange');
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({
            message: "Error while refreshing the screens's playlist(s)",
          });
        }
      },

      async handleReorderOverride() {
        const { groupId, screenId } = this.$store.state.player;

        const itemsToAdd = [];
        const itemsToUpdate = [];

        this.playlistItems.forEach((playlistItem, index) => {
          let data = {
            screen: groupId ? null : screenId,
            playlist: this.playlist.playlist_id,
            screen_group: groupId ? parseInt(groupId) : null,
          };

          if (!playlistItem.override) {
            data = {
              ...data,
              action: SCREENS_OVERRIDE_ACTIONS.EDIT,
              item: playlistItem.item_id,
              config: {
                display_timer: playlistItem.display_timer,
                scheduler: playlistItem.scheduler,
                item_priority: index + 1,
              },
              layout: this.layoutId,
            };

            itemsToAdd.push(data);
          } else {
            const { id, item, action } = playlistItem.override;

            data = {
              ...data,
              override_id: id,
              action,
              item,
              config: {
                ...playlistItem.override.config,
                item_priority: index + 1,
              },
            };

            itemsToUpdate.push(data);
          }
        });

        if (itemsToAdd.length) {
          !!groupId
            ? await apiCreateScreenGroupPlaylistItemOverride(itemsToAdd)
            : await apiCreateScreenPlaylistItemOverride(itemsToAdd);
        }

        if (itemsToUpdate.length) {
          !!groupId
            ? await apiUpdateScreenGroupPlaylistItemOverride(itemsToUpdate)
            : await apiUpdateScreenPlaylistItemOverride(itemsToUpdate);
        }

        this.getPlaylistItems(false);
        this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);
      },

      async addTileAsItem(template, templateOverrideIds = []) {
        try {
          this.isSavingContent = true;

          const { groupId, screenId } = this.$store.state.player;

          const response = await apiCreateTileAddOverride({
            template_id: template.template_id,
            playlist: this.playlist.playlist_id,
            screen_group: this.isGroupOverride ? groupId : null,
            screen: this.isGroupOverride ? null : screenId,
            config: { display_timer: 15, item_priority: this.playlistItems.length + 1 },
            template_override_ids: templateOverrideIds,
            layout: this.layoutId,
          });

          await this.getPlaylistItems(false);

          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);

          this.$emit('contentChange');
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isSavingContent = false;
      },

      openTemplateModal() {
        this.$refs.template_folder_model.open();
      },

      openLinkModal() {
        this.showLinkModal = true;
      },

      async addNewTemplate() {
        const tile = await this.createNewTemplate();

        if (!tile) return;

        this.$router.push({
          name: 'Template',
          query: {
            tile: tile.template_id,
            screen: this.$store.state.player.screenId,
            group: this.$store.state.player.groupId,
            playlist: this.playlist.playlist_id,
            category: this.playlist.category,
            layout: this.layoutId,
            rootCategory: this.rootCategory,
            name: 'new template',
          },
          params: {
            templateId: tile.template_id,
          },
        });
      },

      async createNewTemplate() {
        try {
          const { groupId, screenId } = this.$store.state.player;
          const { height, width } = this.getPlaylistSize();

          const data = {
            screenId: !this.groupId && screenId ? parseInt(screenId) : null,
            groupId: this.groupId ? parseInt(this.groupId) : null,
            layoutId: this.layoutId,
            overrideConfig: {
              display_timer: 15,
              item_priority: this.playlistItems.length + 1,
            },
            playlistId: this.playlist.playlist_id,
            height,
            width,
          };

          const response = await apiCreateNewTemplateAsPlaylistOverride(data);

          return response.data.localTemplate;
        } catch (error) {
          this.$toasted.global.general_error({
            message: 'Sorry, something went wrong',
          });
        }
      },

      getPlaylistSize() {
        const layoutChildren = this.$store.getters.widgetsArray;
        const layoutRotation = this.$store.getters.getPlayerRotation;
        const playerResolution = this.$store.getters.getPlayerResolution;
        const playlist = layoutChildren.find(
          (widget) => widget.object.playlist_id === this.playlist.playlist_id,
        );
        const { heightPixels, widthPixels, height, width } =
          playlist?.position[layoutRotation] || {};

        if (!heightPixels && !widthPixels) {
          if (layoutRotation === 'horizontal') {
            return {
              height: (playerResolution?.height / 100) * height,
              width: (playerResolution?.width / 100) * width,
            };
          } else {
            return {
              width: (playerResolution?.height / 100) * width,
              height: (playerResolution?.width / 100) * height,
            };
          }
        }

        return { height: heightPixels, width: widthPixels };
      },

      setPlaylistPreviewItem(playlistItem) {
        this.previewItem = playlistItem;
      },

      addLayerToPlaylist(playlistItem) {
        this.addPlaylistItems([playlistItem]);
      },

      isLocalTemplateAndEditable(playlistItem) {
        if (playlistItem.item_type === CONTENT_TYPE_LOCAL_TEMPLATE) {
          return !playlistItem.has_group_widget_override;
        }

        return true;
      },

      isItemOverriddenByGroup(playlistItem) {
        if (!!this.isGroupOverride) {
          const { groupId } = this.$store.state.player;

          return (
            (!!this.checkGroupOverride(playlistItem) &&
              parseInt(playlistItem.override.screen_group) !== parseInt(groupId)) ||
            !this.isLocalTemplateAndEditable(playlistItem)
          );
        }

        return (
          this.checkGroupOverride(playlistItem) || !this.isLocalTemplateAndEditable(playlistItem)
        );
      },

      async openFileUploadPopUp() {
        await this.$store.dispatch(CONTENT_REQUEST);
        this.showFileUploadPopUp = true;
      },

      handleCloseFileUploadModal(data) {
        this.showFileUploadPopUp = false;
        this.addPlaylistItems(data.playlistItems);
      },

      getSimpleType,
    },
  };
</script>

<style lang="scss">
  .playlist-items-container .playlist-item-container {
    &.selected:hover .image .move {
      display: none;
    }

    .playlist:hover {
      .move {
        display: flex;
      }

      .image:hover {
        .search {
          display: flex;
        }

        .move {
          display: none;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .dropdown-toggle {
    &:hover {
      cursor: pointer;
    }

    &::after {
      display: none !important;
    }
  }

  .sidebar-section {
    padding-top: 16px;
    padding-bottom: 24px;

    & + .sidebar-section {
      border-top: 1px solid $lineGrey;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .playlist-items-container {
    display: flex;
    flex-direction: column;

    .playlist-item-container {
      display: flex;
      align-items: center;
      padding: 10px 0;

      & + .playlist-item-container {
        border-top: 1px solid $lineGrey;
      }

      &.selected {
        .playlist {
          background-color: $backgroundGrey2;
        }
      }
    }

    .playlist {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 100px 1fr;
      align-items: center;
      padding: 10px;
      gap: 8px;
      width: 100%;
      transition: background 0.25s ease-in;

      .playlist-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
      }

      .playlist-name {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        font-weight: bold;

        span {
          display: -webkit-box;
          word-wrap: break-word;
          word-break: break-all;
          max-height: 32px;
          text-overflow: ellipsis;
          overflow: hidden;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        i {
          max-width: 24px;
        }
      }

      .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        .editable-locked {
          display: flex;
        }
      }

      .time {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .date {
        display: flex;
        align-items: center;
        gap: 4px;

        &:hover {
          color: $primaryRed;
        }
      }

      &:hover {
        background-color: $backgroundGrey2;
        cursor: pointer;
      }
    }
  }

  .section-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    margin-bottom: 8px;

    i {
      color: $primaryText;
    }

    &.title {
      margin-bottom: 24px;
    }

    &.playlist-title {
      display: flex;
      justify-content: space-between;
    }
  }

  .dropdown-item {
    .priority-icon {
      width: 24px;
      height: 24px;
      display: inline-block;
      background-image: url('../../assets/img/priority.svg');
    }
    &:hover {
      .priority-icon {
        background-image: url('../../assets/img/priority-red.svg');
      }
    }
  }
  .right {
    margin-left: auto;
  }

  .dropdown-divider {
    position: relative;
    overflow: visible;
    margin: 12px;

    &::before {
      content: 'Fit content';
      display: block;
      font-size: 12px;
      line-height: 12px;
      color: $secondaryText;
      background-color: white;
      position: absolute;
      top: -7px;
      left: 6px;
      padding: 0 8px 0 4px;
    }
  }
</style>
