<template>
  <div class="table-container scrollbar">
    <table v-if="organisations && organisations.length > 0" class="table organizations-table">
      <thead>
        <tr>
          <th>Name</th>
          <th style="width: 132px">Licenses</th>
          <th style="width: 100px">Users</th>
          <th style="width: 132px">Created</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="organisation in organisations"
          :key="organisation.organisation_id"
          :class="{
            selected: organisation.organisation_id === selectedOrganisation?.organisation_id,
          }"
          @click="setSelectedOrganisation(organisation)"
        >
          <td>
            {{ organisation.name | alwaysShowStr }}
          </td>

          <td style="width: 132px">
            <span :style="{ color: organisation.license_count === 0 ? '#e3003a' : 'inherit' }">{{
              organisation.license_count
            }}</span>
          </td>

          <td style="width: 100px">{{ organisation.user_count }}</td>

          <td style="width: 132px">
            {{ organisation.created | dateCreated }}
          </td>

          <td style="width: 64px">
            <div class="dropdown">
              <i
                class="material-icons-outlined align-middle more-buttons dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                more_vert
              </i>
              <div class="dropdown-menu dropdown-menu-right">
                <button
                  type="button"
                  class="dropdown-item"
                  @click="openDeleteLicenseModal(organisation)"
                >
                  <i class="material-icons-outlined align-middle">delete</i>
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="no-data">No organizations</div>

    <DeleteOrganisationModal
      @closeModal="showDeleteOrganisationModal = false"
      :organisationId="selectedOrganisation?.organisation_id"
      v-if="showDeleteOrganisationModal"
      @deleted="organisationDeleted"
    />
  </div>
</template>

<script>
  import moment from 'moment';

  import { GET_ORGANISATION_ROLES } from '@/store/actions/roles';
  import { SET_CURRENT_ORGANISATION } from '@/store/actions/organisation';
  import DeleteOrganisationModal from '@/components/admin/DeleteOrganisationModal.vue';

  export default {
    name: 'OrganisationsList',

    props: {
      searchTerm: {
        type: String,
        default: '',
      },
    },

    components: {
      DeleteOrganisationModal,
    },

    filters: {
      dateCreated(value) {
        const ts = moment.utc(value);
        return ts.local().format('D/MM/Y');
      },
    },

    data() {
      return {
        showDeleteOrganisationModal: false,
      };
    },

    computed: {
      organisations() {
        if (!this.searchTerm) return this.$store.state.organisation.organisations;

        const searchTerm = this.searchTerm.toLowerCase();

        return this.$store.state.organisation.organisations.filter((organisation) => {
          return (
            organisation.name.toLowerCase().includes(searchTerm) ||
            organisation.organisation_id.toLowerCase().includes(searchTerm)
          );
        });
      },

      selectedOrganisation() {
        return this.$store.state.organisation.organisation;
      },

      isAdmin() {
        return this.$store.getters.isAdministrator;
      },
    },

    methods: {
      checkDate(date) {
        return moment(date).isBefore();
      },

      setSelectedOrganisation(organisation) {
        this.$store.commit(SET_CURRENT_ORGANISATION, organisation, false);
        this.$store.dispatch(GET_ORGANISATION_ROLES);

        if (organisation && this.$route.params?.organisation_id === organisation?.organisation_id)
          return;

        const routeData = organisation
          ? {
              name: 'Admin Organisation',
              params: { organisation_id: organisation.organisation_id },
            }
          : {
              name: 'Organisation',
            };

        this.$router.push(routeData);
      },

      openDeleteLicenseModal(organisation) {
        this.setSelectedOrganisation(organisation);
        this.showDeleteOrganisationModal = true;
      },

      organisationDeleted() {
        this.setSelectedOrganisation(null);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .table-container {
    overflow-y: auto;
    overflow-x: visible;

    padding-right: 2rem;
    margin-right: -2rem;
  }

  .organizations-table {
    border: none;
    border-spacing: 0 8px;
    border-collapse: separate;
    margin-bottom: 0;

    thead {
      th {
        position: sticky;
        top: 0;
        box-shadow: 0px 0px 0 8px $backgroundGrey;
        z-index: 2;
        background-color: $backgroundGrey;
      }
    }

    th,
    td {
      border: none;
    }

    th {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      & + th {
        text-align: center;
      }
    }

    td {
      padding: 14px 16px;

      font-size: 16px;
      line-height: 24px;

      background: none;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;

      &:first-child {
        border-left: 2px solid transparent;
      }

      &:last-child {
        border-right: 2px solid transparent;
      }

      & + td {
        text-align: center;
      }
    }

    tbody {
      overflow-y: auto;
      height: 100%;

      tr {
        cursor: pointer;
        background-color: #fff;

        &:hover,
        &.selected {
          td {
            border-top: 2px solid $primaryRed;
            border-bottom: 2px solid $primaryRed;

            &:first-child {
              border-left: 2px solid $primaryRed;
            }

            &:last-child {
              border-right: 2px solid $primaryRed;
            }
          }
        }
      }
    }
  }
</style>
