<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    title="Create Template Folder"
    :modalStyles="{ width: '400px' }"
    :okFunction="createFolder"
    :isSaving="isSaving"
    :disabled="folderName.length < 3 || folderName.length > 40 || isSaving"
    okButtonLabel="Create Folder"
  >
    <template v-slot:body>
      <div class="content-wrapper">
        <BaseInput v-model="folderName" label="Name" placeholder="Enter folder name" />
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { TEMPLATE_FOLDER_CREATE } from '@/store/actions/templatesBrowser';

  export default {
    name: 'CreateTemplateFolderModal',

    components: { Modal },

    props: {
      parentId: {
        type: Number,
        default: null,
      },
    },

    emits: ['folder-created'],

    data() {
      return {
        showModal: false,
        isSaving: false,
        folderName: '',
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async createFolder() {
        this.isSaving = true;

        try {
          await this.$store.dispatch(TEMPLATE_FOLDER_CREATE, {
            parent_id: this.parentId,
            folderName: this.folderName,
          });
          this.$toasted.global.general_success({ message: 'Folder created successfully' });
          this.$emit('folder-created');
        } catch (error) {
          this.$toasted.global.general_error({
            message: error.response?.data?.message ?? 'Error creating folder',
          });
        } finally {
          this.isSaving = false;
          this.closeModal();
        }
      },

      closeModal() {
        this.showModal = false;
        this.folderName = '';
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
