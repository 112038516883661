<template>
  <div class="image" @click="openItemPreview">
    <img
      v-if="
        playlistItem.item_type.includes(WIDGET_TYPES.IMAGE) ||
        playlistItem.item_type.includes(WIDGET_TYPES.VIDEO)
      "
      :src="getEncodedURL(baseUrl, playlistItem.thumbnail_url || playlistItem.item_url)"
      @error="handleImageError"
      :alt="playlistItem.name"
      class="image-preview"
    />

    <img
      v-else-if="playlistItem.item_type.includes(WIDGET_TYPES.TEMPLATE)"
      :src="
        playlistItem.template.thumbnail_image_url
          ? get_full_url(playlistItem.template.thumbnail_image_url)
          : playlistItem.template.settings.image
      "
      @error="handleThumbanilImageError"
    />

    <div v-else class="icon">
      <i
        v-if="playlistItem.item_type.includes(APP_TYPES.TABLE)"
        class="material-icons-outlined align-middle"
      >
        table_rows
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(WIDGET_TYPES.PDF)"
        class="material-icons-outlined align-middle"
      >
        picture_as_pdf
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(APP_TYPES.RSS)"
        class="material-icons-outlined align-middle"
      >
        rss_feed
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(APP_TYPES.WEATHER)"
        class="material-icons-outlined align-middle"
      >
        cloud
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(APP_TYPES.TRAFIKLAB)"
        class="material-icons-outlined align-middle"
      >
        widgets
      </i>

      <i
        v-else-if="playlistItem.item_type.includes(WIDGET_TYPES.LAYER)"
        class="material-icons-outlined align-middle"
      >
        language
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(WIDGET_TYPES.TILE)"
        class="material-icons-outlined align-middle"
      >
        now_wallpaper
      </i>
      <div v-else-if="playlistItem.item_type.includes(WIDGET_TYPES.AUDIO)">
        <i v-if="playlistItem.mute" class="material-symbols-outlined">volume_off</i>
        <i v-else class="material-symbols-outlined">volume_up</i>
      </div>
    </div>

    <div class="search">
      <i class="material-icons-outlined align-middle">search</i>
    </div>
    <div class="move">
      <i class="material-icons-outlined align-middle">open_with</i>
    </div>
  </div>
</template>

<script>
  import { APP_TYPES, WIDGET_TYPES } from '@/models/layoutDesigner';
  import { simpleTypeMixin } from '@/helpers';
  import { getCloudfrontUrl } from '@/helpers/utils';

  export default {
    name: 'PlaylistItemImage',
    mixins: [simpleTypeMixin],
    props: { playlistItem: { type: Object, default: () => null } },

    data() {
      return { WIDGET_TYPES, APP_TYPES, imageErrorHandled: false };
    },

    methods: {
      openItemPreview() {
        this.$emit('openItemModal', this.playlistItem);
      },

      handleImageError(event) {
        if (!this.imageErrorHandled) {
          if (this.playlistItem.item_type.includes(this.WIDGET_TYPES.VIDEO)) {
            event.target.src = new URL(
              '@/assets/img/sidebar/Playlists-1.svg',
              import.meta.url,
            ).href;
          } else {
            event.target.src = this.getEncodedURL(this.baseUrl, this.playlistItem.item_url);
          }
          this.imageErrorHandled = true;
        }
      },

      handleThumbanilImageError(event) {
        if (!this.imageErrorHandled) {
          event.target.src = this.playlistItem.template.settings.image;
        }
        this.imageErrorHandled = true;
      },

      get_full_url(url) {
        return getCloudfrontUrl(url);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid $lineGrey;
    .image-preview {
      object-fit: cover;
      min-height: 56px;
      width: 100%;
      height: 100%;
    }

    .search,
    .move {
      position: absolute;
      display: none;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.55);
      top: 0;
      width: 100%;
      height: 100%;

      i {
        color: white;
      }
    }
  }
</style>
