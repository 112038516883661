<template>
  <SimpleModal title="Delete Screen Group" ref="deleteScreenGroupModal">
    <template #default>
      <BaseText variant="body1">
        Are you sure you want to delete <strong>{{ screenGroupName }}</strong> ?</BaseText
      >
      <BaseText variant="body2" color="#6a6b6a">
        By Deleting this group, its screens will move to "Ungrouped screens".</BaseText
      >
    </template>
    <template #footer>
      <BaseButton size="sm" theme="secondary" @click="close" class="mr-3"> Cancel </BaseButton>
      <BaseButton size="sm" @click="deleteGroup" :loading="isLoading"> Delete </BaseButton>
    </template>
  </SimpleModal>
</template>

<script>
  import { SCREEN_GROUP_DELETE } from '@/store/actions/screens';
  import SimpleModal from '../common/SimpleModal.vue';
  import { BaseText } from '../common/ui/atoms/baseText';

  export default {
    name: 'DeleteGroupModal',
    emits: ['groupDeleted'],
    components: { SimpleModal, BaseText },

    props: {
      screenGroupName: {
        type: String,
        default: '',
      },
      screenGroupId: {
        type: String | Number,
        default: '',
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async deleteGroup() {
        this.isLoading = true;

        try {
          const successful = await this.$store.dispatch(SCREEN_GROUP_DELETE, this.screenGroupId);

          if (successful) {
            this.$toasted.global.general_success({
              message: 'Group deleted successfully',
            });

            this.$emit('groupDeleted');
            this.close();
          }
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
      },

      open() {
        this.$refs.deleteScreenGroupModal.open();
      },
      close() {
        this.$refs.deleteScreenGroupModal.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .ui-modal-content {
    width: 500px;
  }
</style>
