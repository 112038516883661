<template>
  <SimpleModal title="Add Group" ref="createGroupModal">
    <template #default>
      <div class="v-stack">
        <BaseInput label="Group Name" id="group-name" v-model="groupName" />

        <BaseSelect label="Parent Group" id="parent-group" v-model="parentGroupSelected">
          <option :value="null">None</option>
          <option v-for="group in screenGroups" :key="group.id" :value="group.id">
            {{ group.name }}
          </option>
        </BaseSelect>
      </div>
    </template>

    <template #footer>
      <BaseButton @click="close" theme="secondary" class="mr-3" size="sm"> Cancel </BaseButton>

      <BaseButton @click="createGroup" size="sm" :loading="isLoading"> Create Group </BaseButton>
    </template>
  </SimpleModal>
</template>

<script>
  import { SCREENS_ADD_GROUP } from '@/store/actions/screens';
  import SimpleModal from '@/components/common/SimpleModal.vue';

  export default {
    name: 'CreateGroupModal',

    components: { SimpleModal },

    props: {
      screenGroups: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        isLoading: false,
        layouts: [],
        groupName: '',
        parentGroupSelected: null,
      };
    },

    methods: {
      async createGroup() {
        if (!this.groupName) {
          this.$toasted.global.general_error({ message: 'Group name is required!' });
          return;
        }
        this.isLoading = true;

        try {
          const data = {
            name: this.groupName,
            parent_id: this.parentGroupSelected,
          };

          await this.$store.dispatch(SCREENS_ADD_GROUP, data);

          this.$toasted.global.general_success({
            message: 'Group created successfully',
          });
          this.close();
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
      },

      reset() {
        this.groupName = '';
        this.parentGroupSelected = null;
      },

      open() {
        this.$refs.createGroupModal.open();
      },
      close() {
        this.reset();
        this.$refs.createGroupModal.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .ui-modal-content {
    width: 500px !important;
  }

  .v-stack {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
</style>
