var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image",on:{"click":_vm.openItemPreview}},[(
      _vm.playlistItem.item_type.includes(_vm.WIDGET_TYPES.IMAGE) ||
      _vm.playlistItem.item_type.includes(_vm.WIDGET_TYPES.VIDEO)
    )?_c('img',{staticClass:"image-preview",attrs:{"src":_vm.getEncodedURL(_vm.baseUrl, _vm.playlistItem.thumbnail_url || _vm.playlistItem.item_url),"alt":_vm.playlistItem.name},on:{"error":_vm.handleImageError}}):(_vm.playlistItem.item_type.includes(_vm.WIDGET_TYPES.TEMPLATE))?_c('img',{attrs:{"src":_vm.playlistItem.template.thumbnail_image_url
        ? _vm.get_full_url(_vm.playlistItem.template.thumbnail_image_url)
        : _vm.playlistItem.template.settings.image},on:{"error":_vm.handleThumbanilImageError}}):_c('div',{staticClass:"icon"},[(_vm.playlistItem.item_type.includes(_vm.APP_TYPES.TABLE))?_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v(" table_rows ")]):(_vm.playlistItem.item_type.includes(_vm.WIDGET_TYPES.PDF))?_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v(" picture_as_pdf ")]):(_vm.playlistItem.item_type.includes(_vm.APP_TYPES.RSS))?_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v(" rss_feed ")]):(_vm.playlistItem.item_type.includes(_vm.APP_TYPES.WEATHER))?_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v(" cloud ")]):(_vm.playlistItem.item_type.includes(_vm.APP_TYPES.TRAFIKLAB))?_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v(" widgets ")]):(_vm.playlistItem.item_type.includes(_vm.WIDGET_TYPES.LAYER))?_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v(" language ")]):(_vm.playlistItem.item_type.includes(_vm.WIDGET_TYPES.TILE))?_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v(" now_wallpaper ")]):(_vm.playlistItem.item_type.includes(_vm.WIDGET_TYPES.AUDIO))?_c('div',[(_vm.playlistItem.mute)?_c('i',{staticClass:"material-symbols-outlined"},[_vm._v("volume_off")]):_c('i',{staticClass:"material-symbols-outlined"},[_vm._v("volume_up")])]):_vm._e()]),_vm._m(0),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search"},[_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v("search")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"move"},[_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v("open_with")])])
}]

export { render, staticRenderFns }